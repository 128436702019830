export const TextAreaForm = ({
  label,
  value,
  placeholder,
  onChange,
  width,
  required = false,
  height = "100px",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: width,
      }}
    >
      <label htmlFor="name" className="q-label-form">
        {label}
        {required && <div style={{ color: "red" }}>*</div>}
      </label>
      <textarea
        className="q-input-form"
        value={value}
        type={"text"}
        placeholder={placeholder}
        style={{ height: height, resize: "none" }}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
