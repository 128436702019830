import { DatePicker } from "@mui/x-date-pickers";
import CheckboxRow from "components/forms/CheckboxRow";
import SelectForm from "components/forms/SelectForm";
import { TextAreaForm } from "components/forms/TextAreaForm";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import "style/pages-style/create-invoice.css";
import AddPaymentInvoiceModal from "./AddPaymentInvoiceModal";
import ClientSidebar from "pages/home/Clients/ClientSidebar";
import { formatPrice } from "helpers/StringHelpers";
import { format } from "date-fns";
import useInvoicesStore from "providers/useInvoicesStore";
import useClientsStore from "providers/useClientsStore";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import InvoicePDFView from "./InvoicePDFView";
import { v1 } from "uuid";
import { generateInvoicePDF } from "helpers/pdf/GenerateInvoicePDF";
import useCompanyStore from "providers/useCompanyStore";

export default function AddInvoice() {
  const [data, setData] = useState({
    invoiceId: v1().split("-")[0],
    toClientId: null,
    works: [],
    createdAt: new Date(),
    expireAt: null,
    note: "",
    payedOn: null,
  });
  const [alreadyPayed, setAlreadyPayed] = useState(false);
  const nav = useNavigate();
  const [addPaymentModalVisible, setAddPaymentModalVisible] = useState(false);
  const [workEditValue, setWorkEditValue] = useState(undefined);
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);

  const { clientsState, clientFromId } = useClientsStore();
  const { companyState } = useCompanyStore();
  const { invoicesState, addInvoice, updateInvoice } = useInvoicesStore();
  const [isLoading, setIsLoading] = useState(false);

  const setDataForm = ({ field, value }) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const isButtonEnable = data.toClientId !== null && data.works.length !== 0;

  const onClick = async () => {
    setIsLoading(true);

    const obj = {
      ...data,
      works: JSON.stringify(data.works),
    };

    console.log(obj);

    if (isEdit) await updateInvoice(obj, data.id);
    else await addInvoice(obj);

    toast.success(
      "Fattura " + (isEdit ? "modificata" : "creata") + " con successo"
    );

    if (!isEdit) nav("/home/list-invoices");
    setIsLoading(false);
  };

  // Check if edit mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const isEdit = id !== null && id !== undefined && id !== "";

  useEffect(() => {
    const setInvoiceDataFromId = () => {
      if (id === undefined) return;

      const editInvoice = invoicesState.filter((e) => e.id === Number(id));

      if (editInvoice.length === 0) return;

      setData({
        ...editInvoice[0],
      });
      setAlreadyPayed(editInvoice[0].payedOn !== null);
    };

    setInvoiceDataFromId();

    return () => {};
  }, [id, invoicesState]);

  return (
    <div className="base-view">
      <strong className="title-home-view">Crea fattura</strong>
      <div className="divider-home-view" />

      <div className="invoice-base-layout">
        {/* Data */}
        <div className="input-data-layout">
          <SelectForm
            label={"Cliente"}
            secondaryButton={
              <div
                className="add-label-button"
                onClick={() => setAddClientModalVisible(true)}
              >
                Aggiungi
              </div>
            }
            options={clientsState.map((e) => ({
              name: e.name,
              code: e.id,
              rightText: e.pIva,
            }))}
            value={{
              name: clientFromId(data.toClientId)?.name,
              code: data.toClientId,
            }}
            onChange={(i) =>
              setDataForm({
                field: "toClientId",
                value: clientsState[i].id,
              })
            }
          />
          <button
            className="q-secondary-button blue-revert"
            style={{ width: "100%" }}
            onClick={() => setAddPaymentModalVisible(true)}
          >
            Aggiungi prestazione
          </button>

          <div>
            {data.works.length !== 0 && (
              <div style={{ fontWeight: "500" }}>Prestazioni:</div>
            )}
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              {data.works.map((e, i) => (
                <div
                  key={i}
                  style={{
                    backgroundColor: "#f7f7f7",
                    border: "1px solid #666666",
                    padding: "12px",
                  }}
                >
                  {/* Price */}
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  >
                    {formatPrice(e.price)}
                    {e.quantity !== "" && (
                      <div style={{ fontSize: "13px", color: "grey" }}>
                        x{e.quantity}
                      </div>
                    )}
                  </div>

                  {/* Descripion */}
                  <div
                    style={{
                      fontSize: "13px",
                      color: "#222222",
                      lineHeight: "1",
                    }}
                  >
                    {e.description}
                  </div>

                  {/* Actions */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      fontSize: "13px",
                      marginTop: "12px",
                    }}
                  >
                    <div
                      className="add-label-button"
                      style={{ color: "var(--n-blue)", margin: "0px" }}
                      onClick={() => {
                        setWorkEditValue(e);
                        setAddPaymentModalVisible(true);
                      }}
                    >
                      Modifica
                    </div>
                    <div
                      className="add-label-button"
                      style={{ color: "red" }}
                      onClick={() =>
                        setDataForm({
                          field: "works",
                          value: data.works.filter((_, index) => index !== i),
                        })
                      }
                    >
                      Elimina
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              marginTop: "12px",
            }}
          >
            <DatePicker
              label={"Data"}
              value={dayjs(data.createdAt)}
              disableFuture={true}
              onChange={(newValue) => {
                setDataForm({
                  field: "createdAt",
                  value: format(newValue, "yyyy-MM-dd"),
                });
              }}
            />

            <DatePicker
              label={"Scadenza"}
              value={data.expireAt === null ? null : dayjs(data.expireAt)}
              onChange={(newValue) => {
                setDataForm({
                  field: "expireAt",
                  value: newValue,
                });
              }}
            />
          </div>

          <CheckboxRow
            isSelected={alreadyPayed}
            title={"Incassata"}
            onChange={(e) => {
              setAlreadyPayed(!alreadyPayed);
              if (!e) setDataForm({ field: "payedOn", value: null });
            }}
          />
          {alreadyPayed && (
            <DatePicker
              label={"Pagata il"}
              value={dayjs(data.payedOn)}
              disableFuture={true}
              onChange={(newValue) =>
                setDataForm({
                  field: "payedOn",
                  value: format(newValue, "yyyy-MM-dd"),
                })
              }
            />
          )}

          <TextAreaForm
            label={"Note"}
            placeholder={"..."}
            value={data.note}
            onChange={(e) => {
              setDataForm({ field: "note", value: e });
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "12px",
            }}
          >
            <button
              className="q-secondary-button blue"
              style={{ width: "100%" }}
              disabled={!isButtonEnable || isLoading}
              onClick={onClick}
            >
              {isEdit ? "Modifica" : "Crea"} fattura
            </button>

            {isEdit && (
              <button
                className="q-secondary-button cool-red"
                style={{ width: "100%" }}
                onClick={() => {
                  generateInvoicePDF({
                    invoiceData: data,
                    client: clientFromId(data.toClientId),
                    companyState: companyState,
                  });
                }}
              >
                Scarica PDF
              </button>
            )}
          </div>
        </div>

        {/* Divider */}
        <div className="divider-vertical invoice-divider" />

        {/* PDF Example */}
        <InvoicePDFView
          invoiceData={{
            ...data,
          }}
        />
      </div>

      {/* Overlay */}
      <div
        className={
          "overlay" +
          (addPaymentModalVisible || addClientModalVisible ? "" : " hide")
        }
        onClick={() => {
          setWorkEditValue(undefined);
          setAddPaymentModalVisible(false);
          setAddClientModalVisible(false);
        }}
      />

      {/* Add work for the invoice */}
      <AddPaymentInvoiceModal
        visible={addPaymentModalVisible}
        workToEdit={workEditValue}
        onConfirm={(e) => {
          if (workEditValue !== undefined)
            setDataForm({
              field: "works",
              value: [...data.works.filter((v) => v.id !== e.id), e],
            });
          else setDataForm({ field: "works", value: [...data.works, e] });
          setWorkEditValue(undefined);
        }}
        onClose={() => {
          setAddPaymentModalVisible(false);
          setWorkEditValue(undefined);
        }}
      />

      {/* Edit profile */}
      <ClientSidebar
        open={addClientModalVisible}
        onClose={() => setAddClientModalVisible(false)}
      />
    </div>
  );
}
