import { HiUsers } from "react-icons/hi2";
import { TbInvoice } from "react-icons/tb";
import ListClients from "pages/home/Clients/ListClients";
import ListQuotes from "pages/home/Quotes/ListQuotes";
import ListInvoices from "pages/home/Invoices/ListInvoices";
import CompanySettings from "pages/home/CompanySettings/CompanySettings";
import { MdOutlineMapsHomeWork } from "react-icons/md";
import { MdMapsHomeWork } from "react-icons/md";
import { HiOutlineUsers } from "react-icons/hi2";
import { IoDocuments } from "react-icons/io5";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { MdSpaceDashboard } from "react-icons/md";
import Dashboard from "pages/home/Dashboard/Dashboard";
import { FaUserShield } from "react-icons/fa";
import ListUsers from "pages/home/Users/ListUsers";

export const PagesInHome = [
  {
    name: "Dashboard",
    icon: (
      <MdOutlineSpaceDashboard
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <MdSpaceDashboard
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <Dashboard />,
    path: "home/dashboard",
  },
  {
    name: "Lista clienti",
    icon: (
      <HiOutlineUsers
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <HiUsers
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <ListClients />,
    path: "home/list-clients",
  },
  {
    name: "Lista preventivi",
    icon: (
      <IoDocumentsOutline
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <IoDocuments
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <ListQuotes />,
    path: "home/list-quotes",
  },
  {
    name: "Lista fatture",
    icon: (
      <TbInvoice
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <TbInvoice
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <ListInvoices />,
    path: "home/list-invoices",
  },
  {
    name: "Lista utenti",
    icon: (
      <FaUserShield
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <FaUserShield
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <ListUsers />,
    path: "home/list-users",
    showOnlyIfAdmin: true,
  },
  // {
  //   name: "Prodotti",
  //   icon: (
  //     <PiWarehouse
  //       height={"var(--icon-size-small)"}
  //       width={"var(--icon-size-small)"}
  //     />
  //   ),
  //   iconActive: (
  //     <PiWarehouseFill
  //       height={"var(--icon-size-small)"}
  //       width={"var(--icon-size-small)"}
  //     />
  //   ),
  //   page: <ListProducts />,
  //   path: "home/list-products",
  //   showOnlyIfAdmin: true,
  // },
  {
    name: "Dati azienda",
    icon: (
      <MdOutlineMapsHomeWork
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    iconActive: (
      <MdMapsHomeWork
        height={"var(--icon-size-small)"}
        width={"var(--icon-size-small)"}
      />
    ),
    page: <CompanySettings />,
    path: "home/company-data",
    showOnlyIfAdmin: true,
  },
];
