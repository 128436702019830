const formatPrice = (number) => {
  try {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  } catch (_) {
    return number;
  }
};

const formatAddress = (address, city, cap) => {
  if (address === "") return "";

  if (city === "") return address;

  if (cap === "") return address + ", " + city;

  return address + ", " + city + ", " + cap;
};

const formatDateInEU = (d) => {
  // 2024-12-07
  const [year, month, day] = d.split("-");

  return day + "/" + month + "/" + year;
};

export { formatPrice, formatAddress, formatDateInEU };
