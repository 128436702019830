import { supabase } from "services/supabaseConfig";
import { create } from "zustand";

const useClientsStore = create((set, get) => ({
  clientsState: [],
  clearUserState: () => {
    set({ clientsState: [] });
  },
  addClient: async (newClient) => {
    await supabase.from("Clients").insert(newClient);

    const { data, error } = await supabase.from("Clients").select("*");
    if (error) return;

    set({
      clientsState: data,
    });
  },
  removeClient: async (clientId) => {
    await supabase.from("Clients").delete().eq("id", clientId);

    set({
      clientsState: get().clientsState.filter((e) => e.id !== clientId),
    });
  },
  updateClient: async (clientToEdit, clientId) => {
    const response = await supabase
      .from("Clients")
      .update(clientToEdit)
      .eq("id", clientId);

    if (response.error !== null) return;

    const { data, error } = await supabase.from("Clients").select("*");
    if (error) return;

    set({
      clientsState: data,
    });
  },
  clientFromId: (id) => {
    try {
      return get().clientsState.filter((c) => c.id === id)[0];
    } catch (_) {
      return undefined;
    }
  },
  initClients: async () => {
    const s = get().clientsState;

    if (s.length > 0) return;

    const response = await supabase.from("Clients").select("*");

    if (response.error !== null) return;

    set({
      clientsState: response.data,
    });
  },
}));

export default useClientsStore;
