import jsPDF from "jspdf";
import { format } from "date-fns";
import { formatPrice } from "helpers/StringHelpers";

export const generateInvoicePDF = async ({
  invoiceData,
  companyState,
  client,
}) => {
  // Create new PDF document
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "a4",
  });

  // Set default font
  doc.setFont("helvetica");

  // Page dimensions
  const pageWidth = doc.internal.pageSize.width;
  const margin = 20;

  // Add company logo
  if (companyState.logoUrl) {
    try {
      // Convert logo URL to base64
      const response = await fetch(companyState.logoUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      await new Promise((resolve) => {
        reader.onload = () => {
          doc.addImage(
            reader.result,
            "PNG",
            margin,
            margin,
            40,
            20,
            "",
            "FAST"
          );
          resolve();
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading logo:", error);
    }
  }

  // Add invoice number and date
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  const invoiceText = `Fattura #${invoiceData.invoiceId}`;
  doc.text(
    invoiceText,
    pageWidth - margin - doc.getTextWidth(invoiceText),
    margin + 5
  );

  doc.setFont("helvetica", "normal");
  const dateText = format(invoiceData.createdAt, "dd/MM/yyyy");
  doc.text(
    dateText,
    pageWidth - margin - doc.getTextWidth(dateText),
    margin + 12
  );

  // Company details
  const startY = margin + 45;
  doc.setFontSize(10);
  doc.text("Per conto di:", margin, startY);
  doc.setFontSize(14);
  doc.text(companyState.companyName, margin, startY + 7);
  doc.setFontSize(10);
  doc.text(companyState.address, margin, startY + 14);
  doc.text(
    `${companyState.city} (${companyState.prov}) ${companyState.cap}`,
    margin,
    startY + 21
  );
  doc.text(`P.IVA: ${companyState.pIva}`, margin, startY + 28);

  // Client details
  doc.setFontSize(10);
  doc.text("Destinatario:", pageWidth - margin - 60, startY);
  if (client) {
    doc.setFontSize(14);
    doc.text(client.name, pageWidth - margin - 60, startY + 7);
    doc.setFontSize(10);
    doc.text(client.address, pageWidth - margin - 60, startY + 14);
    doc.text(
      `${client.city} ${client.prov ? `(${client.prov})` : ""} ${client.cap}`,
      pageWidth - margin - 60,
      startY + 21
    );
    doc.text(`P.IVA: ${client.pIva}`, pageWidth - margin - 60, startY + 28);
  }

  // Works table
  const tableStartY = startY + 45;

  // Draw table headers
  doc.setFillColor(255, 255, 255);
  doc.setDrawColor(200, 200, 200);
  doc.rect(margin, tableStartY, pageWidth - margin * 2, 10, "FD");

  doc.setFont("helvetica", "bold");
  doc.setFontSize(10);
  doc.text("Descrizione", margin + 5, tableStartY + 7);
  doc.text("Importo", pageWidth - margin - 35, tableStartY + 7);

  // Draw table content
  let currentY = tableStartY + 10;
  doc.setFont("helvetica", "normal");

  invoiceData.works.forEach((work, index) => {
    const row = currentY;

    // Alternate row background
    if (index % 2 === 0) {
      doc.setFillColor(248, 248, 248);
      doc.rect(margin, row, pageWidth - margin * 2, 10, "F");
    }

    // Add work description and price
    doc.text(work.description, margin + 5, row + 7);
    const priceText = `${formatPrice(work.price)}${
      work.quantity ? ` x${work.quantity}` : ""
    }`;
    doc.text(
      priceText,
      pageWidth - margin - doc.getTextWidth(priceText),
      row + 7
    );

    currentY += 10;
  });

  // Draw table borders
  doc.setDrawColor(200, 200, 200);
  doc.rect(
    margin,
    tableStartY,
    pageWidth - margin * 2,
    currentY - tableStartY,
    "D"
  );

  // Add total
  const total = invoiceData.works.reduce(
    (acc, work) =>
      acc + work.price * (work.quantity === "" ? 1 : Number(work.quantity)),
    0
  );

  doc.setFont("helvetica", "bold");
  const totalText = `Prezzo totale: ${formatPrice(total)}`;
  doc.text(
    totalText,
    pageWidth - margin - doc.getTextWidth(totalText),
    currentY + 15
  );

  // Add notes and expiry date at the bottom
  const bottomY = doc.internal.pageSize.height - margin;
  doc.setFont("helvetica", "normal");
  doc.setFontSize(10);

  if (invoiceData.note) {
    doc.setFont("helvetica", "bold");
    doc.text("Note", margin, bottomY - 20);
    doc.setFont("helvetica", "normal");
    doc.text(invoiceData.note, margin, bottomY - 13);
  }

  if (invoiceData.expireAt && !isNaN(new Date(invoiceData.expireAt))) {
    doc.setFont("helvetica", "bold");
    doc.text("Da saldare entro:", pageWidth - margin - 60, bottomY - 20);
    doc.setFont("helvetica", "normal");
    doc.text(
      format(invoiceData.expireAt, "dd/MM/yyyy"),
      pageWidth - margin - 60,
      bottomY - 13
    );
  }

  doc.save(
    `fattura-${client.name.replaceAll(" ", "-")}-${
      invoiceData.invoiceId
    }-${Date.now()}.pdf`
  );
};
