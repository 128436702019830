import { floorsVLS } from "global/ProductsVariables";
import { create } from "zustand";
import { v1 } from "uuid";
import CalculateQuotePrice from "helpers/quote/CalculateQuotePrice";

// All data of the quote
const _initialState = {
  quoteId: v1().split("-")[0],
  numberOfWindows: "",
  installationOnFloor: floorsVLS[0],
  toClientId: undefined,
  productsList: [],
  calculatedPrice: "-",
  showedPrice: "-",
};

const useCreateQuoteStore = create((set, get) => ({
  state: _initialState,
  reset: () => {
    set({ state: _initialState });
  },
  setState: (newValue) => {
    const oldState = get().state;
    set({
      state: {
        ...oldState,
        ...newValue,
      },
    });
  },
  getPriceQuote: () => {
    return CalculateQuotePrice(get().state);
  },
  getStatePerStage: (stage) => {
    const oldState = get().state;

    switch (stage) {
      // Get windows
      case 0:
        return {
          numberOfWindows: oldState.numberOfWindows,
          installationOnFloor: oldState.installationOnFloor,
          toClientId: oldState.toClientId,
        };
      // Get product data
      case 1:
        return oldState.productsList;
      // Get price
      case 2:
      default:
        return {
          calculatedPrice: oldState.calculatedPrice,
          showedPrice: oldState.showedPrice,
        };
    }
  },
  dataPerStageFullfilled: (stage) => {
    const state = get().state;
    switch (stage) {
      case 0:
        return state.numberOfWindows !== "" && state.toClientId !== undefined;
      case 1:
        return CalculateQuotePrice(get().state) !== 0;
      default:
        return true;
    }
  },
}));

export default useCreateQuoteStore;
