import { InputForm } from "components/forms/InputForm";
import useClientsStore from "providers/useClientsStore";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { getCompanyData } from "services/CompanyDataAPI";

export default function ClientSidebar(props) {
  const { addClient, updateClient, removeClient } = useClientsStore();

  const _initialState = useMemo(
    () => ({
      codeFiscal: null,
      name: null,
      pIva: null,
      address: null,
      cap: null,
      city: null,
      prov: null,
      phoneNumber: null,
      email: null,
      pec: null,
      note: null,
    }),
    []
  );
  const [data, setData] = useState(_initialState);
  const [isLoading, setIsLoading] = useState(false);

  const isEdit = props.clientToEdit !== undefined;
  const isButtonEnable = data.name?.length > 3 && data.pIva?.length > 3;

  useEffect(() => {
    if (props.clientToEdit === undefined) setData(_initialState);
    else setData({ ...props.clientToEdit });

    return () => {};
  }, [_initialState, props.clientToEdit]);

  const handleChange = (field, e) =>
    setData((prev) => ({ ...prev, [field]: e }));

  const onClick = async () => {
    setIsLoading(true);

    try {
      if (isEdit) {
        await updateClient(data, props.clientToEdit.id);
      } else {
        await addClient(data);
      }
    } catch (_) {}
    setIsLoading(false);

    toast.success(
      "Cliente " + (isEdit ? "aggiornato" : "aggiunto") + " con successo"
    );
    setData(_initialState);
    props.onClose();
  };

  const onDelete = async () => {
    setIsLoading(true);
    try {
      await removeClient(props.clientToEdit.id);
    } catch (_) {}
    setIsLoading(false);
    toast.success("Cliente eliminato");
    setData(_initialState);
    props.onClose();
  };

  const GetDataThroughPIVA = async () => {
    toast.loading("Caricamento...");

    const { isValid, companyName, ...restOfData } = await getCompanyData(
      data.pIva
    );

    toast.dismiss();
    if (!isValid) {
      toast.error("P.IVA invalida");
      return;
    }

    setData((prev) => ({
      ...prev,
      ...restOfData,
      name: companyName,
      codeFiscal: data.pIva,
    }));
  };

  return (
    <div className={"edit-view" + (props.open ? " open-x" : "")}>
      {/* Title */}
      <div
        style={{
          display: "flex",
          margin: "24px 32px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong
          className="title-home-view"
          style={{ fontWeight: "500", fontSize: "26px" }}
        >
          {isEdit ? "Modifica" : "Aggiungi"} cliente
        </strong>
        <div className="close-icon" onClick={props.onClose}>
          <IoClose size={"32px"} color="#000000EE" />
        </div>
      </div>

      {/* Edit view content */}
      <div
        className="edit-view-content"
        style={{
          marginTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "8px",
          }}
        >
          <InputForm
            label={"Partita IVA"}
            value={data.pIva}
            required={true}
            placeholder={"123456789"}
            onChange={(e) => handleChange("pIva", e)}
            width="100%"
          />

          <InputForm
            label={"Codice fiscale"}
            value={data.codeFiscal}
            placeholder={"123456789"}
            onChange={(e) => handleChange("codeFiscal", e)}
            width="100%"
          />
        </div>
        {data.pIva?.length >= 10 && (
          <button onClick={GetDataThroughPIVA} className="q-primary-button">
            Autocompletamento da P.IVA
          </button>
        )}

        <InputForm
          label={"Nominativo"}
          required={true}
          value={data.name}
          placeholder={"Mario Rossi"}
          onChange={(e) => handleChange("name", e)}
          width="100%"
        />

        <InputForm
          label={"Indirizzo"}
          value={data.address}
          placeholder={"..."}
          onChange={(e) => handleChange("address", e)}
          width="100%"
        />

        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "8px",
          }}
        >
          <InputForm
            label={"CAP"}
            value={data.cap}
            width={"20%"}
            placeholder={"12345"}
            onChange={(e) => handleChange("cap", e)}
          />
          <InputForm
            label={"Città"}
            value={data.city}
            placeholder={"..."}
            onChange={(e) => handleChange("city", e)}
            width="100%"
          />
          <InputForm
            label={"Prov."}
            value={data.prov}
            width={"15%"}
            placeholder={"12"}
            onChange={(e) => handleChange("prov", e)}
          />
        </div>

        <InputForm
          label={"Numero di telefono"}
          value={data.phoneNumber}
          placeholder={"+4412345678"}
          onChange={(e) => handleChange("phoneNumber", e)}
          width="100%"
        />
        <InputForm
          label={"Email"}
          value={data.email}
          placeholder={"email@gmail.com"}
          onChange={(e) => handleChange("email", e)}
          width="100%"
        />
        <InputForm
          label={"PEC"}
          value={data.pec}
          placeholder={"email@gmail.com"}
          onChange={(e) => handleChange("pec", e)}
          width="100%"
        />

        <div style={{ marginTop: "24px", width: "100%" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <button
              className="q-secondary-button blue"
              disabled={!isButtonEnable || isLoading}
              onClick={onClick}
            >
              {isEdit ? "Modifica" : "Aggiungi"}
            </button>
            {isEdit && (
              <button
                className="q-secondary-button red"
                onClick={onDelete}
                disabled={isLoading}
              >
                Elimina
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
