import GraphBase from "components/GraphBase";
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CheckboxRow from "components/forms/CheckboxRow";

export default function RevenueGraph() {
  const data = [
    { month: "Jan", sales: 4000, profits: 2400 },
    { month: "Feb", sales: 3000, profits: 1398 },
    { month: "Mar", sales: 2000, profits: 9800 },
    { month: "Apr", sales: 2780, profits: 3908 },
    { month: "May", sales: 1890, profits: 4800 },
    { month: "Jun", sales: 2390, profits: 3800 },
    { month: "Jul", sales: 2390, profits: 3800 },
    { month: "Aug", sales: 2390, profits: 3800 },
    { month: "Sep", sales: 2390, profits: 3800 },
    { month: "Oct", sales: 2390, profits: 3800 },
    { month: "Nov", sales: 2390, profits: 3800 },
    { month: "Dec", sales: 2390, profits: 9800 },
  ];

  const _keysGraph = [
    { name: "Vendite", code: "sales", color: "#8884d8" },
    { name: "Prodotto", code: "profits", color: "green" },
  ];
  const [keysSelected, setKeysSelected] = useState([..._keysGraph]);
  const currentYear = new Date().getFullYear();
  const [date1, setDate1] = useState(dayjs("01-01-" + currentYear));
  const [date2, setDate2] = useState(dayjs(Date()));

  const ValueFilterCheckBox = ({ keyValue }) => {
    const isSelected = keysSelected.map((e) => e.code).includes(keyValue.code);

    return (
      <CheckboxRow
        title={keyValue.name}
        isSelected={isSelected}
        onChange={(e) =>
          setKeysSelected((prev) =>
            isSelected
              ? prev.filter((e) => e.code !== keyValue.code)
              : [...prev, keyValue]
          )
        }
      />
    );
  };

  return (
    <div>
      {/* Title */}
      <h2 style={{ fontWeight: "600", marginBottom: "8px" }}>Andamento</h2>

      {/* All graph */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "16px",
        }}
      >
        {/* Filters */}
        <div
          style={{
            display: "flex",
            flex: 0.3,
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {/* Values Filter */}
          <div className="box-with-border">
            <div style={{ marginBottom: "4px" }}>Profitto totale:</div>
            {_keysGraph.map((e) => (
              <ValueFilterCheckBox key={e.code} keyValue={e} />
            ))}
          </div>

          {/* Range filter */}
          <div className="box-with-border">
            <div>Date:</div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                marginTop: "12px",
              }}
            >
              <DatePicker
                label={"Inizio"}
                value={date1}
                onChange={(newValue) => setDate1(newValue)}
              />
              <DatePicker
                label={"Fine"}
                value={date2}
                shouldDisableYear={(e) => e.year() > currentYear}
                onChange={(newValue) => setDate2(newValue)}
              />
            </div>
          </div>
        </div>

        {/* Graph */}
        <div
          className="box-with-border"
          style={{
            flex: 1,
            height: "500px",
          }}
        >
          <GraphBase data={data} keys={keysSelected} />
        </div>
      </div>
    </div>
  );
}
