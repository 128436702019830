import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCompanyData } from "services/CompanyDataAPI";
import "style/pages-style/company-settings.css";
import PlaceHolderLogo from "assets/images/placeholder_logo.png";
import { InputForm } from "components/forms/InputForm";
import useCompanyStore from "providers/useCompanyStore";

import _ from "lodash";
import { supabase } from "services/supabaseConfig";

export default function CompanySettings() {
  const { companyState, updateCompanyData } = useCompanyStore();
  const [data, setData] = useState({
    ...companyState,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (field, e) =>
    setData((prev) => ({ ...prev, [field]: e }));

  const GetDataThroughPIVA = async () => {
    toast.loading("Caricamento...");

    const { isValid, ...restOfData } = await getCompanyData(data.pIva);

    toast.dismiss();
    if (!isValid) {
      toast.error("P.IVA invalida");
      return;
    }

    setData((prev) => ({
      ...prev,
      ...restOfData,
    }));
  };

  const uploadLogo = async (event) => {
    const file = event.target.files[0];
    if (file === undefined) return;

    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("Carica un immagine non superiore ai 10MB");
      return;
    }

    // Generate a unique file name to avoid conflicts
    const fileExt = file.name.split(".").pop();
    const fileName = `${Math.random()}.${fileExt}`;

    // Upload file to Supabase storage
    const { error } = await supabase.storage
      .from("logos_bucket")
      .upload(`${fileName}`, file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (error) {
      toast.error("Impossibile caricare questa immagine");
      return;
    }

    // Get the public URL of the uploaded file
    const {
      data: { publicUrl },
    } = supabase.storage.from("logos_bucket").getPublicUrl(`${fileName}`);

    toast.success("Logo caricato con successo");

    await updateCompanyData({ logoUrl: publicUrl });
  };

  const UpdateData = async () => {
    setLoading(true);
    await updateCompanyData(data);
    setLoading(false);

    toast.success("Dati aggiornati con successo");
  };

  useEffect(() => {
    setData(companyState);
  }, [companyState]);

  return (
    <div className="base-view">
      <strong className="title-home-view">La mia azienda</strong>
      <div className="divider-home-view" />

      <div className="home-view">
        <div id="data-forms-view">
          <InputForm
            label={"P.IVA"}
            value={data.pIva}
            placeholder="123456789"
            onChange={(e) => handleChange("pIva", e)}
          />
          {data.pIva?.length >= 10 && (
            <button
              onClick={GetDataThroughPIVA}
              className="q-primary-button"
              disabled={data.pIva === companyState.pIva}
            >
              Autocompletamento da P.IVA
            </button>
          )}

          <InputForm
            label={"Codice fiscale"}
            value={data.codeFiscal}
            placeholder="123456789"
            onChange={(e) => handleChange("codeFiscal", e)}
          />

          <InputForm
            label={"Nominativo"}
            value={data.companyName}
            placeholder="..."
            onChange={(e) => handleChange("companyName", e)}
          />

          <InputForm
            label={"Numero di telefono"}
            value={data.phoneNumber}
            placeholder="13456789"
            onChange={(e) => handleChange("phoneNumber", e)}
          />

          <InputForm
            label={"Email"}
            value={data.email}
            placeholder="email@gmail.com"
            onChange={(e) => handleChange("email", e)}
          />

          <InputForm
            label={"PEC"}
            value={data.pec}
            placeholder="email@gmail.com"
            onChange={(e) => handleChange("pec", e)}
          />

          <InputForm
            label={"Indirizzo impresa"}
            value={data.address}
            placeholder="Via ..."
            onChange={(e) => handleChange("phoneNumber", e)}
          />

          <div className="row-forms">
            <InputForm
              label={"CAP"}
              value={data.cap}
              placeholder="..."
              onChange={(e) => handleChange("cap", e)}
            />
            <InputForm
              label={"Comune"}
              value={data.city}
              placeholder="..."
              onChange={(e) => handleChange("city", e)}
            />
            <InputForm
              label={"Provincia"}
              value={data.prov}
              placeholder="..."
              onChange={(e) => handleChange("prov", e)}
            />
          </div>

          <button
            className="q-secondary-button blue"
            disabled={_.isEqual(data, companyState) || loading}
            onClick={UpdateData}
          >
            Salva
          </button>
        </div>

        <div id="upload-logo-view">
          <img
            src={
              companyState.logoUrl !== null
                ? companyState.logoUrl
                : PlaceHolderLogo
            }
            alt="placeholder"
            style={{
              width: "300px",
              height: "250px",
              backgroundColor: "#77777744",
              border: "1px solid grey",
              objectFit: "contain",
            }}
          />
          <button
            className="q-secondary-button blue-revert"
            disabled={loading}
            onClick={() => {
              document.getElementById("input-file").click();
            }}
          >
            Carica logo
          </button>
          <input
            type="file"
            accept=".pdf,.png,.jpg,.jpeg"
            id={"input-file"}
            onChange={(e) => uploadLogo(e)}
            hidden
          />
        </div>
      </div>
    </div>
  );
}
