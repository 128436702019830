import Sidebar from "components/Sidebar";
import UserIcon from "components/UserIcon";
import { PagesInHome } from "global/Pages";
import { useLocalStorage } from "helpers/uses/useLocalStorage";
import LoginPage from "pages/auth/LoginPage";
import AddInvoice from "pages/home/Invoices/AddInvoice";
import CreateQuote from "pages/home/Quotes/CreateQuote/CreateQuote";
import LandingPage from "pages/landing/LandingPage";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";
import useInvoicesStore from "providers/useInvoicesStore";
import useUserStore from "providers/useUserStore";
import React, { useEffect } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

const ProtectedRoute = () => {
  const { getUserId } = useLocalStorage();
  const location = useLocation();

  console.log(getUserId());

  if (getUserId() === undefined || getUserId() === null) {
    // Redirect to login page if not authenticated
    // Save the attempted URL for redirecting after login
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, render child routes
  return <Outlet />;
};

export default function App() {
  const loc = useLocation();
  const nav = useNavigate();
  const isLogged = loc.pathname.includes("/home");

  const { getUserId } = useLocalStorage();
  const { initUserData } = useUserStore();
  const { initCompanyData } = useCompanyStore();
  const { initClients } = useClientsStore();
  const { initInvoices } = useInvoicesStore();

  useEffect(() => {
    const getUser = async () => {
      const userId = getUserId();

      if (userId === undefined || userId === null) return;

      await initUserData(userId);
      await initCompanyData();
      await initClients();
      await initInvoices();

      // Keep the session
      if (loc.pathname === "/") nav("/" + PagesInHome[0].path);
    };
    getUser();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex" }}>
      {/* <TopBar /> */}
      {isLogged && <Sidebar />}

      {/* User Icon */}
      {isLogged && <UserIcon />}

      {/* Routes */}
      <Routes>
        <Route element={<LandingPage />} path="/" />

        <Route element={<LoginPage />} path="/login" />

        <Route element={<ProtectedRoute />}>
          {/* Home paths */}
          {PagesInHome.map((e) => (
            <Route key={e.path} element={e.page} path={e.path} />
          ))}

          <Route path={"/home/list-quotes/quote"} element={<CreateQuote />} />

          <Route
            path={"/home/list-invoices/invoice"}
            element={<AddInvoice />}
          />
        </Route>
      </Routes>
    </div>
  );
}
