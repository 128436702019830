import { InputForm } from "components/forms/InputForm";
import React, { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import { supabase } from "services/supabaseConfig";

export default function ModalUser(props) {
  const _initialState = useMemo(
    () => ({
      name: "",
      email: "",
      password: "",
      isAdmin: false,
    }),
    []
  );
  const [userData, setUserData] = useState(_initialState);
  const [isLoading, setIsLoading] = useState(false);

  const isEdit = props.userToEdit !== undefined;
  const isButtonEnable =
    userData.name.length > 3 &&
    userData.email.length > 3 &&
    userData.password.length >= 8;

  useEffect(() => {
    if (props.userToEdit === undefined) setUserData(_initialState);
    else setUserData({ ...props.userToEdit, password: "••••••••" });

    return () => {};
  }, [_initialState, props.userToEdit]);

  if (!props.visible) {
    return <></>;
  }

  const onClick = async () => {
    setIsLoading(true);

    try {
      if (isEdit) {
        await supabase
          .from("Users")
          .update({
            name: userData.name,
            isAdmin: userData.isAdmin,
          })
          .eq("id", props.userToEdit.id);
      } else {
        const { data, error } = await supabase.auth.signUp({
          email: userData.email,
          password: userData.password,
          options: {
            data: {
              name: userData.name,
            },
          },
        });

        if (error) throw Error();

        await supabase.from("Users").insert({
          id: data.user.id,
          email: userData.email,
          name: userData.name,
          isAdmin: userData.isAdmin,
        });
      }
    } catch (_) {}
    setIsLoading(false);
    toast.success(
      "Utente " + (isEdit ? "aggiornato" : "aggiunto") + " con successo"
    );

    props.update();
    props.onClose();
  };

  return (
    <div className={"little-modal-center modal-2"}>
      {/* Title */}
      <div
        style={{
          display: "flex",
          margin: "24px 32px",
          marginBottom: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong
          className="title-home-view"
          style={{ fontWeight: "500", fontSize: "26px" }}
        >
          {isEdit ? "Modifica" : "Aggiungi"} utente
        </strong>
        <div className="close-icon" onClick={props.onClose}>
          <IoClose size={"32px"} color="#000000EE" />
        </div>
      </div>

      {/* Content */}
      <div
        style={{
          padding: "0px 32px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          marginBottom: "32px",
        }}
      >
        {/* Type */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <div
            className={
              "q-selectable-button" + (userData.isAdmin ? "" : " selected")
            }
            onClick={() => {
              setUserData((data) => ({ ...data, isAdmin: false }));
            }}
          >
            Utente
          </div>
          <div
            className={
              "q-selectable-button" + (userData.isAdmin ? " selected" : "")
            }
            onClick={() => {
              setUserData((data) => ({ ...data, isAdmin: true }));
            }}
          >
            Admin
          </div>
        </div>

        <InputForm
          label={"Nominativo"}
          value={userData.name}
          onChange={(e) => setUserData((prev) => ({ ...prev, name: e }))}
          placeholder={"..."}
          autoComplete={"new-password"}
        />
        <InputForm
          label={"Email"}
          value={userData.email}
          disable={isEdit}
          onChange={(e) => setUserData((prev) => ({ ...prev, email: e }))}
          placeholder={"emai@gmail.com"}
          autoComplete={"new-password"}
        />
        <InputForm
          label={"Password"}
          type="password"
          value={userData.password}
          disable={isEdit}
          onChange={(e) => setUserData((prev) => ({ ...prev, password: e }))}
          placeholder={"••••••••"}
          autoComplete={"new-password"}
        />

        <div
          style={{
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "8px",
          }}
        >
          <button className="q-secondary-button black">Chiudi</button>
          <button
            className="q-secondary-button blue"
            disabled={!isButtonEnable || isLoading}
            onClick={onClick}
          >
            {isEdit ? "Modifica" : "Aggiungi"}
          </button>
        </div>
      </div>
    </div>
  );
}
