import { supabase } from "services/supabaseConfig";
import { create } from "zustand";

const useQuotesStore = create((set, get) => ({
  quotesState: [],
  clearQuotesState: () => {
    set({ quotesState: [] });
  },
  addQuote: async (newQuote) => {
    const { error } = await supabase.from("Quotes").insert(newQuote);
    console.log(error);
    if (error) return;

    const response = await supabase.from("Quotes").select("*");

    if (response.error) return;

    set({
      quotesState: response.data.map((e) => ({
        ...e,
        installationOnFloor: JSON.parse(e.installationOnFloor),
        productsList: JSON.parse(e.productsList),
      })),
    });
  },
  removeQuote: async (id) => {
    await supabase.from("Quotes").delete().eq("id", id);

    set({
      quotesState: get().quotesState.filter((e) => e.id !== id),
    });
  },
  updateQuote: async (quoteToEdit, id) => {
    const response = await supabase
      .from("Quotes")
      .update(quoteToEdit)
      .eq("id", id);

    if (response.error !== null) return;

    const { data, error } = await supabase.from("Quotes").select("*");
    if (error) return;

    set({
      quotesState: data.map((e) => ({
        ...e,
        installationOnFloor: JSON.parse(e.installationOnFloor),
        productsList: JSON.parse(e.productsList),
      })),
    });
  },
  initQuotes: async () => {
    const s = get().quotesState;

    if (s.length > 0) return;

    const response = await supabase.from("Quotes").select("*");

    if (response.error !== null) return;

    set({
      quotesState: response.data.map((e) => ({
        ...e,
        installationOnFloor: JSON.parse(e.installationOnFloor),
        productsList: JSON.parse(e.productsList),
      })),
    });
  },
}));

export default useQuotesStore;
