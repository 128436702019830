// Logic of the price inside quote

import * as vs from "global/ProductsVariables";

const CalculatePricePerProduct = (prod) => {
  const _p = vs.listOfProductsVLS.filter(
    (e) => e.code === prod.product.code
  )[0];
  const _price =
    _p === undefined ? 0 : _p.pricesPerMeasures[prod.height + "-" + prod.width];

  const _ppInfix =
    prod.infix.percentagePlus === undefined ? 1 : 1 + prod.infix.percentagePlus;

  const _ppTGlass =
    prod.typeOfGlass.percentagePlus === undefined
      ? 1
      : 1 + prod.typeOfGlass.percentagePlus;

  const _ppGlass =
    prod.glass.percentagePlus === undefined ? 1 : 1 + prod.glass.percentagePlus;

  const _ppProfile =
    prod.profile.percentagePlus === undefined
      ? 1
      : 1 + prod.profile.percentagePlus;

  const _pWDumpster =
    (Number(prod.widthDumpster) / 1000) * vs.pricePerWidthDumpster;
  const _pSShutter =
    (Number(prod.widthShutter) / 1000) *
    (Number(prod.heightShutter) / 1000) *
    vs.pricePerSizeShutter;
  const _pSShutter2 =
    (Number(prod.widthShutter2) / 1000) *
    (Number(prod.heightShutter2) / 1000) *
    vs.pricePerSizeShutter2;
  const _pSMosNet =
    (Number(prod.widthMosNet) / 1000) *
    (Number(prod.heightMosNet) / 1000) *
    vs.pricePerSizeMosNet;

  return (
    _price * _ppInfix * _ppTGlass * _ppGlass * _ppProfile +
    (_pWDumpster + _pSShutter + _pSShutter2 + _pSMosNet)
  );
};

const CalculateQuotePrice = (quote) => {
  const _pOfFloor = quote.installationOnFloor.percentagePlus;
  const _lisOfPricePerProd = [];

  for (var i = 0; i < quote.productsList.length; i++) {
    const _prod = quote.productsList[i];
    _lisOfPricePerProd.push(CalculatePricePerProduct(_prod));
  }

  const _tot = _lisOfPricePerProd.reduce((a, b) => (a = a + b), 0);

  return _tot * (_pOfFloor === undefined ? 1 : 1 + _pOfFloor);
};

export default CalculateQuotePrice;
