import { supabase } from "services/supabaseConfig";
import { create } from "zustand";

const useInvoicesStore = create((set, get) => ({
  invoicesState: [],
  clearInvoicesState: () => {
    set({ invoicesState: [] });
  },
  addInvoice: async (newInvoice) => {
    const { error } = await supabase.from("Invoices").insert(newInvoice);
    console.log(error);
    if (error) return;

    const response = await supabase.from("Invoices").select("*");

    if (response.error) return;

    set({
      invoicesState: response.data.map((e) => ({
        ...e,
        works: JSON.parse(e.works),
      })),
    });
  },
  removeInvoice: async (invoiceId) => {
    await supabase.from("Invoices").delete().eq("id", invoiceId);

    set({
      invoicesState: get().invoicesState.filter((e) => e.id !== invoiceId),
    });
  },
  updateInvoice: async (invoiceToEdit, invoiceId) => {
    const response = await supabase
      .from("Invoices")
      .update(invoiceToEdit)
      .eq("id", invoiceId);

    if (response.error !== null) return;

    const { data, error } = await supabase.from("Invoices").select("*");
    if (error) return;

    set({
      invoicesState: data.map((e) => ({
        ...e,
        works: JSON.parse(e.works),
      })),
    });
  },
  initInvoices: async () => {
    const s = get().invoicesState;

    if (s.length > 0) return;

    const response = await supabase.from("Invoices").select("*");

    if (response.error !== null) return;

    set({
      invoicesState: response.data.map((e) => ({
        ...e,
        works: JSON.parse(e.works),
      })),
    });
  },
}));

export default useInvoicesStore;
