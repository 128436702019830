import React from "react";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import { format } from "date-fns";
import { formatAddress, formatPrice } from "helpers/StringHelpers";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";

export default function PDFQuoteView() {
  const { state } = useCreateQuoteStore();
  const { clientFromId } = useClientsStore();
  const { companyState } = useCompanyStore();

  const clientData = clientFromId(state.toClientId);

  const DataRow = ({ title, value }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          fontSize: "14px",
          alignItems: "flex-end",
        }}
      >
        {title !== undefined && (
          <strong style={{ fontWeight: "600" }}>{title}</strong>
        )}
        {value !== undefined && <div>{value === "" ? "-" : value}</div>}
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Information client */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          border: "1px solid var(--light-grey)",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <img src={companyState.logoUrl} alt="logo" width={"200px"} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <DataRow title={companyState.companyName + "#" + state.quoteId} />
              <DataRow value={format(new Date(), "dd/MM/yyyy")} />
            </div>
          </div>

          {/* Datas */}
          <div>
            <DataRow title={"Cliente:"} value={clientData.name} />
            <DataRow title={"Email:"} value={clientData.email ?? "-"} />
            <DataRow
              title={"Cellulare:"}
              value={clientData.phoneNumber ?? "-"}
            />
            {clientData.address !== "" && (
              <DataRow
                title={"Indirizzo:"}
                value={formatAddress(
                  clientData.address,
                  clientData.city,
                  clientData.cap
                )}
              />
            )}
            <DataRow
              title={"Installazione a:"}
              value={state.installationOnFloor.name}
            />
          </div>
        </div>
      </div>

      {/* Prices */}
      <div style={{ marginTop: "8px", marginBottom: "20px" }}>
        <DataRow title={"Prezzo:"} value={formatPrice(state.calculatedPrice)} />
        <DataRow
          title={"Prezzo ordine da pagare:"}
          value={formatPrice(state.showedPrice)}
        />
      </div>

      {/* List windows */}
      <div
        style={{
          gap: "4px",
          height: "46vh",
        }}
      >
        <strong style={{ fontWeight: "600", fontSize: "18px" }}>
          Lista finestre:
        </strong>

        {/* List windows data Table */}
        <div
          className="home-view br-secondary"
          style={{
            maxHeight: "94%",
            overflowX: "scroll",
            overflowY: "scroll",
            border: "1px solid var(--light-grey)",
          }}
        >
          <div className="quota-table-container br-secondary">
            <table className="quota-table-table secondary-table br-secondary">
              <thead>
                <tr
                  className="quota-table-header-row"
                  style={{
                    backgroundColor: "black",
                  }}
                >
                  <th className="quota-table-header-cell">Finestra</th>
                  <th className="quota-table-header-cell">Larghezza/Altezza</th>
                  <th className="quota-table-header-cell">Tipo vetro</th>
                  <th className="quota-table-header-cell">Modello vetro</th>
                  <th className="quota-table-header-cell">Colore esterno</th>
                  <th className="quota-table-header-cell">Colore interno</th>
                  <th className="quota-table-header-cell">Locale</th>
                </tr>
              </thead>
              <tbody>
                {state.productsList.map((e, i) => (
                  <tr
                    key={e.code}
                    className={
                      i % 2 === 0
                        ? "quota-table-row"
                        : "quota-table-alternate-row"
                    }
                  >
                    <td className="quota-table-cell secondary">
                      {e.product.name}
                    </td>
                    <td className="quota-table-cell secondary">
                      {e.width}x{e.height} (mm)
                    </td>
                    <td className="quota-table-cell secondary">
                      {e.typeOfGlass.name}
                    </td>
                    <td className="quota-table-cell secondary">
                      {e.glass.name}
                    </td>
                    <td className="quota-table-cell secondary">
                      {e.colorIn.name}
                    </td>
                    <td className="quota-table-cell secondary">
                      {e.colorOut.name}
                    </td>
                    <td className="quota-table-cell">{e.position}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
