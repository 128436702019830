import { supabase } from "services/supabaseConfig";
import { create } from "zustand";

const _initialState = {
  id: null,
  companyName: null,
  address: null,
  country: null,
  cap: null,
  city: null,
  prov: null,
  codeFiscal: null,
  pIva: null,
  phoneNumber: null,
  email: null,
  pec: null,
  logoUrl: null,
};

const useCompanyStore = create((set, get) => ({
  companyState: _initialState,
  clearCompanyState: () => {
    set({ companyState: _initialState });
  },
  updateCompanyData: async (newState) => {
    const _newData = {
      ...get().companyState,
      ...newState,
    };

    await supabase.from("Company_Data").update(_newData).eq("id", _newData.id);

    set({
      companyState: _newData,
    });
  },
  initCompanyData: async () => {
    const response = await supabase.from("Company_Data").select("*").single();

    if (response.error !== null) return;
    set({
      companyState: {
        ...get().companyState,
        ...response.data,
      },
    });
  },
}));

export default useCompanyStore;
