import { InputForm } from "components/forms/InputForm";
import React, { useState } from "react";
import PDFQuoteView from "../PDFQuoteView";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import toast from "react-hot-toast";
import { formatPrice } from "helpers/StringHelpers";
import { generateQuotePDF } from "helpers/pdf/GenerateQuotePDF";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";
import useQuotesStore from "providers/useQuotesStore";
import { useLocation, useNavigate } from "react-router-dom";

export default function QuotePrice({ onBack }) {
  const { state, setState, getPriceQuote } = useCreateQuoteStore();
  const { companyState } = useCompanyStore();
  const { addQuote, updateQuote } = useQuotesStore();
  const [price, setPrice] = useState(Number(state.showedPrice).toFixed(2));
  const { clientFromId } = useClientsStore();
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();

  const editQuote = async () => {
    setIsLoading(true);
    try {
      if (price !== Number(state.showedPrice).toFixed(2))
        setState({ showedPrice: price });

      const obj = {
        quoteId: state.quoteId,
        numberOfWindows: Number(state.numberOfWindows),
        installationOnFloor: JSON.stringify(state.installationOnFloor),
        toClientId: state.toClientId,
        productsList: JSON.stringify(state.productsList),
        calculatedPrice: getPriceQuote(),
        showedPrice: price,
      };

      await updateQuote(obj, state.id);
      toast.success("Preventivo aggiornato");
      nav("/home/list-quotes");
    } catch (e) {
      console.log("e: " + e);
    }
    setIsLoading(false);
  };

  const saveQuote = async () => {
    setIsLoading(true);
    try {
      if (price !== Number(state.showedPrice).toFixed(2))
        setState({ showedPrice: price });

      const obj = {
        quoteId: state.quoteId,
        numberOfWindows: Number(state.numberOfWindows),
        installationOnFloor: JSON.stringify(state.installationOnFloor),
        toClientId: state.toClientId,
        productsList: JSON.stringify(state.productsList),
        calculatedPrice: getPriceQuote(),
        showedPrice: price,
      };

      await addQuote(obj);
      toast.success("Preventivo salvato");
      nav("/home/list-quotes");
    } catch (e) {
      console.log("e: " + e);
    }
    setIsLoading(false);
  };

  // Check if edit mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEdit =
    searchParams.get("id") !== undefined &&
    searchParams.get("id") !== null &&
    searchParams.get("id") !== "";

  return (
    <div className="quote-price-layout">
      {/* Quote Data */}
      <div
        style={{
          display: "flex",
          gap: "16px",
          flexDirection: "column",
        }}
      >
        <div>
          <strong className="sub-title">Codice ordine</strong>
          <div className="sub-title-content">
            {companyState.companyName}#{state.quoteId}
          </div>
        </div>

        <div>
          <strong className="sub-title">Prezzo totale</strong>
          <div className="sub-title-content">
            {formatPrice(getPriceQuote())}
          </div>
        </div>

        <InputForm
          label={"Prezzo finale"}
          value={price}
          onChange={(e) => setPrice(e)}
          placeholder={"0.00"}
          width={"100%"}
          maxWidth={"420px"}
          type="number"
        />

        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: "6px",
            minWidth: "250px",
            maxWidth: "420px",
          }}
        >
          <button
            className="q-secondary-button blue"
            disabled={isLoading}
            onClick={() => (isEdit ? editQuote() : saveQuote())}
          >
            {isEdit ? "Modifica" : "Salva"} preventivo
          </button>
          <button
            className="q-secondary-button cool-red"
            onClick={() =>
              generateQuotePDF({
                state: state,
                clientData: clientFromId(state.toClientId),
                companyState: companyState,
              })
            }
          >
            Scarica PDF
          </button>
          <button className="q-secondary-button black" onClick={onBack}>
            Indietro
          </button>
        </div>
      </div>

      {/* Divider */}
      <div className="divider-vertical quote-divider" />

      {/* PDF Example */}
      <div className="pdf-view-layout">
        <PDFQuoteView />
      </div>
    </div>
  );
}
