import CheckboxRow from "components/forms/CheckboxRow";
import { InputForm } from "components/forms/InputForm";
import SelectForm from "components/forms/SelectForm";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { LuClipboardPaste } from "react-icons/lu";
import SelectProductModal from "pages/home/Quotes/CreateQuote/modals/SelectProductModal";
import toast from "react-hot-toast";
import SelectColorProduct from "pages/home/Quotes/CreateQuote/modals/SelectColorProduct";
import {
  climatZonesVLS,
  colorsDumpsterVLS,
  colorsHandleVLS,
  glassesVLS,
  infixVLS,
  productState,
  profilesVLS,
  specialFormatsVLS,
  typesOfGlassVLS,
} from "global/ProductsVariables";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import _ from "lodash";

export default function QuoteDataProduct() {
  const { setState, getStatePerStage, getPriceQuote } = useCreateQuoteStore();

  // Model data of the window
  const [showAddOptionDumpster, setshowAddOptionDumpster] = useState(false);
  const [showAddOptionShutter, setshowAddOptionShutter] = useState(false);
  const [showAddOptionShutter2, setshowAddOptionShutter2] = useState(false);
  const [showAddOptionMosNet, setshowAddOptionMosNet] = useState(false);

  // All windows data
  const [windowsData, setWindowsData] = useState(getStatePerStage(1));

  // Check if two windows have different data
  const windowsDataAreDifferent = ({ w1, w2 }) => {
    return !_.isEqual(w1, w2);
  };

  const saveWindowData = ({ useCopiedData = false }) => {
    // Remove previous window
    const _indexWindowSelected = windowsData.findIndex(
      (e) => e.code === windowSelected.code
    );
    var _newWindows = windowsData.filter((e) => e.code !== windowSelected.code);

    // Set new window
    if (useCopiedData)
      setDataForms({
        ...copiedData,
        name: windowSelected.name,
        code: windowSelected.code,
      });

    _newWindows = [
      ..._newWindows.slice(0, _indexWindowSelected),
      useCopiedData
        ? {
            ...copiedData,
            name: windowSelected.name,
            code: windowSelected.code,
          }
        : dataForms,
      ..._newWindows.slice(_indexWindowSelected),
    ];

    // Save new windows
    toast.success("Dati prodotto salvati");
    setWindowsData(_newWindows);
    setState({ productsList: _newWindows });
    setState({
      calculatedPrice: getPriceQuote(),
      showedPrice: getPriceQuote(),
    });
  };

  const saveForAllWindowsData = () => {
    var _newWindows = [];

    // Save new windows data with same variable
    for (var i = 0; i < windowsData.length; i++) {
      const _w = windowsData[i];
      _newWindows.push({ ...dataForms, name: _w.name, code: _w.code });
    }

    toast.success("Dati salvati per tutte le finestre");
    setWindowsData(_newWindows);
    setState({ productsList: _newWindows });
    setState({
      calculatedPrice: getPriceQuote(),
      showedPrice: getPriceQuote(),
    });
  };

  // Current window selected
  const [windowSelected, setwindowSelected] = useState({
    name: windowsData[0].name,
    code: windowsData[0].code,
  });

  const _indexWindowSelected = windowsData.findIndex(
    (e) => e.code === windowSelected.code
  );

  // Window data to insert for the current window selected
  const [dataForms, setDataForms] = useState(getStatePerStage(1)[0]);

  // Choose which type of product
  const [showAddProduct, setShowAddProduct] = useState(false);
  // Choose which color
  const [showSelectColor, setShowSelectColor] = useState(-1);
  const handleChangeDataForms = ({ field, value }) => {
    setDataForms((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const [copiedData, setCopiedData] = useState();
  // Show copy button if data are saved
  const showCopyButton = () => {
    if (_indexWindowSelected === -1) return;

    return windowsDataAreDifferent({
      w1: windowsData[_indexWindowSelected],
      w2: productState,
    });
  };

  useEffect(() => {
    // Set checkboxes visibility values
    setshowAddOptionDumpster(windowsData[0].widthDumpster !== "");
    setshowAddOptionShutter(windowsData[0].widthShutter !== "");
    setshowAddOptionShutter2(windowsData[0].widthShutter2 !== "");
    setshowAddOptionMosNet(windowsData[0].widthMosNet !== "");

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODOs: Add validator to add only number
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", gap: "8px", marginBottom: "12px" }}>
        <SelectForm
          options={windowsData.map((e) => ({ name: e.name, code: e.code }))}
          value={windowSelected}
          onChange={(i) => {
            setwindowSelected({
              name: windowsData[i].name,
              code: windowsData[i].code,
            });
            setshowAddOptionDumpster(windowsData[i].widthDumpster !== "");
            setshowAddOptionShutter(windowsData[i].widthShutter !== "");
            setshowAddOptionShutter2(windowsData[i].widthShutter2 !== "");
            setshowAddOptionMosNet(windowsData[i].widthMosNet !== "");
            setDataForms(windowsData[i]);
          }}
          width={"140px"}
        />

        <div style={{ flex: 1 }} />

        {showCopyButton() && (
          <button
            className="q-secondary-button black"
            onClick={() => {
              toast.success("Dati copiati");
              setCopiedData({ ...dataForms });
            }}
          >
            <MdContentCopy size={"20px"} />
            <div>Copia valori finestra</div>
          </button>
        )}

        {copiedData !== undefined && (
          <button
            className="q-secondary-button black"
            onClick={() => {
              saveWindowData({ useCopiedData: true });
            }}
          >
            <LuClipboardPaste size={"20px"} />
            <div>Incolla valori finestra</div>
          </button>
        )}
      </div>

      <div style={{ display: "flex", gap: "16px" }}>
        <InputForm
          value={dataForms.position}
          label={"Locale"}
          placeholder={"Bagno, cucina, camera..."}
          onChange={(e) => {
            handleChangeDataForms({ field: "position", value: e });
          }}
          width={"100%"}
        />
        <InputForm
          value={dataForms.width}
          label={"Larghezza"}
          placeholder={"(mm)"}
          onChange={(e) => {
            handleChangeDataForms({ field: "width", value: e });
            if (dataForms.product.code !== "")
              handleChangeDataForms({
                field: "product",
                value: { name: "", code: "" },
              });
          }}
          width={"100%"}
        />
        <InputForm
          value={dataForms.height}
          label={"Altezza"}
          placeholder={"(mm)"}
          onChange={(e) => {
            handleChangeDataForms({ field: "height", value: e });
            if (dataForms.product.code !== "")
              handleChangeDataForms({
                field: "product",
                value: { name: "", code: "" },
              });
          }}
          width={"100%"}
        />
      </div>

      <div style={{ display: "flex", gap: "16px" }}>
        <SelectForm
          value={dataForms.product}
          label={"Prodotto finestra"}
          clicked={showAddProduct}
          onClick={() => {
            setShowAddProduct(true);
          }}
          width={"100%"}
        />

        <SelectForm
          value={dataForms.specialFormat}
          options={specialFormatsVLS}
          label={"Formato speciale"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "specialFormat",
              value: specialFormatsVLS[i],
            });
          }}
          width={"100%"}
        />

        <SelectForm
          value={dataForms.colorHandle}
          options={colorsHandleVLS}
          label={"Colore maniglia"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "colorHandle",
              value: colorsHandleVLS[i],
            });
          }}
          width={"100%"}
        />
      </div>

      <CheckboxRow
        isSelected={showAddOptionDumpster}
        title={"Aggiungi cassonetto"}
        onChange={(e) => {
          handleChangeDataForms({
            field: "widthDumpster",
            value: "",
          });
          handleChangeDataForms({
            field: "colorDumpster",
            value: colorsDumpsterVLS[0],
          });
          setshowAddOptionDumpster(e);
        }}
      />
      {showAddOptionDumpster && (
        <div style={{ display: "flex", gap: "16px" }}>
          <InputForm
            value={dataForms.widthDumpster}
            label={"Larghezza cassonetto"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "widthDumpster",
                value: e,
              });
            }}
            width={"100%"}
          />
          <SelectForm
            value={dataForms.colorDumpster}
            options={colorsDumpsterVLS}
            label={"Colore cassoneto"}
            onChange={(i) => {
              handleChangeDataForms({
                field: "colorDumpster",
                value: colorsDumpsterVLS[i],
              });
            }}
            width={"100%"}
          />
        </div>
      )}

      <CheckboxRow
        isSelected={showAddOptionShutter}
        title={"Aggiungi tapparella"}
        onChange={(e) => {
          handleChangeDataForms({
            field: "widthShutter",
            value: "",
          });
          handleChangeDataForms({
            field: "heightShutter",
            value: "",
          });
          setshowAddOptionShutter(e);
        }}
      />
      {showAddOptionShutter && (
        <div style={{ display: "flex", gap: "16px" }}>
          <InputForm
            value={dataForms.widthShutter}
            label={"Larghezza tapparella"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "widthShutter",
                value: e,
              });
            }}
            width={"100%"}
          />
          <InputForm
            value={dataForms.heightShutter}
            label={"Altezza tapparella"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "heightShutter",
                value: e,
              });
            }}
            width={"100%"}
          />
        </div>
      )}

      <CheckboxRow
        isSelected={showAddOptionShutter2}
        title={"Aggiungi persiana"}
        onChange={(e) => {
          handleChangeDataForms({
            field: "widthShutter2",
            value: "",
          });
          handleChangeDataForms({
            field: "heightShutter2",
            value: "",
          });
          setshowAddOptionShutter2(e);
        }}
      />
      {showAddOptionShutter2 && (
        <div style={{ display: "flex", gap: "16px" }}>
          <InputForm
            value={dataForms.widthShutter2}
            label={"Larghezza persiana"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "widthShutter2",
                value: e,
              });
            }}
            width={"100%"}
          />
          <InputForm
            value={dataForms.heightShutter2}
            label={"Altezza persiana"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "heightShutter2",
                value: e,
              });
            }}
            width={"100%"}
          />
        </div>
      )}

      <CheckboxRow
        isSelected={showAddOptionMosNet}
        title={"Aggiungi zanzariera"}
        onChange={(e) => {
          handleChangeDataForms({
            field: "widthMosNet",
            value: "",
          });
          handleChangeDataForms({
            field: "heightMosNet",
            value: "",
          });
          setshowAddOptionMosNet(e);
        }}
      />
      {showAddOptionMosNet && (
        <div style={{ display: "flex", gap: "16px" }}>
          <InputForm
            value={dataForms.widthMosNet}
            label={"Larghezza zanzariera"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "widthMosNet",
                value: e,
              });
            }}
            width={"100%"}
          />
          <InputForm
            value={dataForms.heightMosNet}
            label={"Altezza zanzariera"}
            placeholder={"(mm)"}
            onChange={(e) => {
              handleChangeDataForms({
                field: "heightMosNet",
                value: e,
              });
            }}
            width={"100%"}
          />
        </div>
      )}

      <SelectForm
        options={infixVLS}
        value={dataForms.infix}
        label={"Versione infisso"}
        onChange={(i) => {
          handleChangeDataForms({
            field: "infix",
            value: infixVLS[i],
          });
        }}
        width={"100%"}
      />

      <div style={{ display: "flex", gap: "16px" }}>
        <SelectForm
          options={typesOfGlassVLS}
          value={dataForms.typeOfGlass}
          label={"Tipo di vetro"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "typeOfGlass",
              value: typesOfGlassVLS[i],
            });
          }}
          width={"100%"}
        />
        <SelectForm
          options={glassesVLS}
          value={dataForms.glass}
          label={"Vetro"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "glass",
              value: glassesVLS[i],
            });
          }}
          width={"100%"}
        />
        <SelectForm
          options={profilesVLS}
          value={dataForms.profile}
          label={"Profilo"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "profile",
              value: profilesVLS[i],
            });
          }}
          width={"100%"}
        />
      </div>

      <div style={{ display: "flex", gap: "16px" }}>
        <SelectForm
          value={dataForms.colorIn}
          label={"Colore interno"}
          clicked={showSelectColor === 0}
          onClick={() => setShowSelectColor(0)}
          width={"100%"}
        />
        <SelectForm
          value={dataForms.colorOut}
          label={"Colore esterno"}
          clicked={showSelectColor === 1}
          onClick={() => setShowSelectColor(1)}
          width={"100%"}
        />
        <SelectForm
          options={climatZonesVLS}
          value={dataForms.climateZone}
          label={"Zona climatica"}
          onChange={(i) => {
            handleChangeDataForms({
              field: "climateZone",
              value: climatZonesVLS[i],
            });
          }}
          width={"100%"}
        />
      </div>

      <div style={{ display: "flex", gap: "8px" }}>
        <button
          className="q-secondary-button blue"
          onClick={saveWindowData}
          disabled={
            !windowsDataAreDifferent({
              w1: windowsData[_indexWindowSelected],
              w2: dataForms,
            })
          }
        >
          Salva finestra
        </button>

        <button
          className="q-secondary-button light-blue-2"
          onClick={saveForAllWindowsData}
          disabled={
            !windowsDataAreDifferent({
              w1: windowsData[_indexWindowSelected],
              w2: productState,
            })
          }
        >
          Salva per tutte le finestre
        </button>
        <div style={{ flex: 1 }} />
      </div>

      <div
        className={
          "overlay" + (showAddProduct || showSelectColor !== -1 ? "" : " hide")
        }
        onClick={() => {
          setShowAddProduct(false);
          setShowSelectColor(-1);
        }}
      />
      {/* Select product */}
      <SelectColorProduct
        open={showSelectColor !== -1}
        isInternal={showSelectColor === 0}
        productCode={
          showSelectColor === 0
            ? dataForms.colorIn.code
            : dataForms.colorOut.code
        }
        onSelect={(e) => {
          if (showSelectColor === 0)
            handleChangeDataForms({
              field: "colorIn",
              value: { name: e.name, code: e.code },
            });
          else
            handleChangeDataForms({
              field: "colorOut",
              value: { name: e.name, code: e.code },
            });
        }}
        onClose={() => setShowSelectColor(-1)}
      />

      {/* Add product */}
      <SelectProductModal
        open={showAddProduct}
        productCode={dataForms.product.code}
        productWidth={dataForms.width}
        productHeight={dataForms.height}
        onSelect={(e) => {
          handleChangeDataForms({
            field: "product",
            value: { name: e.name, code: e.code },
          });
        }}
        onClose={() => setShowAddProduct(false)}
      />
    </div>
  );
}
