import React, { useEffect, useState } from "react";
import "style/pages-style/create-quote.css";
import QuoteBase from "./pages/QuoteBase";
import QuoteDataProduct from "./pages/QuoteDataProduct";
import QuotePrice from "./pages/QuotePrice";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import useQuotesStore from "providers/useQuotesStore";

export default function CreateQuote() {
  const [indexSelected, setIndexSelected] = useState(0);
  const { setState, dataPerStageFullfilled } = useCreateQuoteStore();
  const { quotesState } = useQuotesStore();

  const tabs = [
    {
      name: "Dati cliente",
      subpath: "create",
      page: <QuoteBase />,
    },
    {
      name: "Dati prodotti",
      subpath: "data-product",
      page: <QuoteDataProduct />,
    },
    {
      name: "Preventivo finale",
      subpath: "price",
      page: <QuotePrice onBack={goBack} />,
    },
  ];
  // const indexPageSelected = tabs.findIndex((e) => isSelcted(e));

  function goBack() {
    if (indexSelected === 0) return;
    setIndexSelected(indexSelected - 1);
    // nav("?p=" + tabs[indexPageSelected - 1].subpath);
  }

  const TabBox = ({ title, subpath, index }) => {
    return (
      <div
        className={
          "quota-tab" + (indexSelected === index ? " tab-selected" : "")
        }
        onClick={() => {
          if (indexSelected === 0 && !dataPerStageFullfilled(0))
            toast.error("Inserisci i dati richiesti prima di continuare");
          else if (index === 3 && !dataPerStageFullfilled(2))
            toast.error("Inserisci i dati dei prodotti prima di continuare");
          else setIndexSelected(index);
        }}
      >
        {title}
      </div>
    );
  };

  const NextButtons = () => {
    const isEnd = indexSelected === tabs.length - 1;
    const isFirstPage = indexSelected === 0;

    if (isEnd) return <></>;

    return (
      <div style={{ display: "flex", gap: "8px" }}>
        <button
          className="q-secondary-button black"
          onClick={goBack}
          disabled={isFirstPage}
        >
          Indietro
        </button>

        <button
          className="q-secondary-button blue"
          disabled={
            indexSelected === 0 && !dataPerStageFullfilled(indexSelected)
          }
          onClick={() => {
            setIndexSelected(indexSelected + 1);
            // nav("?p=" + tabs[indexPageSelected + 1].subpath);
          }}
        >
          Avanti
        </button>
      </div>
    );
  };

  // Check if edit mode
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  useEffect(() => {
    const setInvoiceDataFromId = () => {
      if (id === undefined || id === "") return;

      const editQuote = quotesState.filter((e) => e.id === Number(id));

      if (editQuote.length === 0) return;

      setState({
        ...editQuote[0],
      });
    };

    setInvoiceDataFromId();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, quotesState]);

  return (
    <div className="base-view">
      <strong className="title-home-view">Crea preventivo</strong>
      <div className="divider-home-view" />

      {/* Tabs */}
      <div>
        <div style={{ display: "flex", top: 1, position: "relative" }}>
          {tabs.map((e, i) => (
            <TabBox key={i} title={e.name} subpath={e.subpath} index={i} />
          ))}
        </div>

        {/* Border */}
        <div
          style={{
            borderTop: "1px solid var(--light-grey)",
          }}
        />
      </div>

      {/* Pages */}
      <div
        style={{
          padding: "24px 4px",
          display: "flex",
          gap: "18px",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {tabs[indexSelected].page}
        <NextButtons />
      </div>
    </div>
  );
}
