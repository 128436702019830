import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "style/variables.css";
import "style/root.css";
import "style/table.css";
import "style/modals.css";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
      <BrowserRouter>
        {/* Toast notification */}
        <Toaster />

        <App />
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>
);
