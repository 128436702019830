import { colorsQuoteVLS } from "global/ProductsVariables";
import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import "style/components-style/products-modal.css";

export default function SelectColorProduct(props) {
  const _colorTypes = [
    { name: "General", code: "general" },
    { name: "Standard", code: "standard" },
    { name: "Fuori Standard", code: "fuoriStandard" },
    { name: "Wood Lam", code: "woodLam" },
    { name: "Matt", code: "matt" },
  ];
  const [typeOfColorSelected, setTypeOfColorSelected] = useState("general");

  const FilterColorButton = ({ title, code }) => {
    const _selected = code === typeOfColorSelected;

    return (
      <button
        className={
          "q-secondary-button little-button " +
          (_selected ? "black" : "transparent")
        }
        onClick={() => setTypeOfColorSelected(code)}
      >
        {title}
      </button>
    );
  };

  const ProductToChoose = ({ product }) => {
    return (
      <div
        className={
          "product-option" +
          (props.productCode === product.code ? " product-selected" : "")
        }
        onClick={() => {
          props.onSelect(product);
          props.onClose();
        }}
      >
        <img
          src={"/images/colors/" + product.image + ".png"}
          style={{
            flex: 1,
            width: "100%",
            height: "100px",
            objectFit: "contain",
          }}
          alt={"product-" + product.name}
        />
        <div>{product.name}</div>
      </div>
    );
  };

  return (
    <div className={"edit-view" + (props.open ? " open-x" : "")}>
      <div className="edit-view-content fit-height">
        <div
          className="close-icon"
          style={{ position: "absolute", top: 8, right: 12 }}
          onClick={props.onClose}
        >
          <IoClose size={"32px"} color="#000000EE" />
        </div>

        <strong style={{ fontWeight: "500", fontSize: "20px" }}>
          Seleziona colore {props.isInternal ? "interno" : "esterno"}:
        </strong>

        {/* Filter type of colors */}
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {_colorTypes.map((e) => (
            <FilterColorButton key={e.code} title={e.name} code={e.code} />
          ))}
        </div>

        {/* Grid view products */}
        <div className="grid-view-product-modal">
          {colorsQuoteVLS[typeOfColorSelected].map((e, i) => (
            <ProductToChoose key={i} product={e} />
          ))}
        </div>
      </div>
    </div>
  );
}
