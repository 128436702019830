import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

export default function SelectForm({
  value,
  onChange,
  secondaryButton,
  onClick,
  clicked = false,
  label,
  disabled = false,
  options,
  width,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleToggle = () => {
    if (onClick !== undefined) {
      onClick();
      return;
    }

    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleOnSelect = (code) => {
    setIsOpen(false);
    onChange(code);
  };

  return (
    <div
      ref={dropdownRef}
      style={{
        position: "relative",
        width: width,
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {/* Label */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <label htmlFor="name" className="q-label-form">
          {label}
        </label>
        {secondaryButton}
      </div>

      {/* Form */}
      <div
        onClick={handleToggle}
        className="q-input-form"
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          border: (isOpen || clicked) && "1px solid var(--n-blue)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              maxHeight: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {value.name}
          </div>
        </div>
        <IoIosArrowDown size={"20px"} />
      </div>

      {/* Dropdown options */}
      {isOpen && (
        <div className="q-select-dropdown">
          {options.map((option, index) => (
            <div
              key={option.code}
              onClick={() => handleOnSelect(index)}
              className={
                "q-select-option" +
                (option.code === value.code ? " option-selected" : "")
              }
            >
              <div>{option.name}</div>
              <div style={{ fontSize: "12px", color: "grey" }}>
                {option.rightText}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
