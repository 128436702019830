// All variables used for creating a quote

// Type of products
const listOfProductsVLS = [
  {
    image: "/images/products/finestra-1-anta.png",
    name: "FINESTRA 1 ANTA con anta a ribalta",
    code: "1",
    pricesPerMeasures: {
      "600-600": 806.58,
      "600-700": 839.12,
      "600-800": 860.04,
      "600-900": 901.88,
      "600-1000": 922.8,
      "700-600": 839.12,
      "700-700": 860.04,
      "700-800": 890.26,
      "700-900": 932.1,
      "700-1000": 953.02,
      "700-1100": 994.86,
      "800-600": 860.04,
      "800-700": 890.26,
      "800-800": 922.8,
      "800-900": 953.02,
      "800-1000": 994.86,
      "800-1100": 1048.32,
      "800-1200": 1101.78,
      "900-600": 901.88,
      "900-700": 932.1,
      "900-800": 964.64,
      "900-900": 1027.4,
      "900-1000": 1069.24,
      "900-1100": 1132.0,
      "900-1200": 1185.46,
      "900-1300": 1248.22,
      "1000-600": 932.1,
      "1000-700": 964.64,
      "1000-800": 994.86,
      "1000-900": 1069.24,
      "1000-1000": 1122.7,
      "1000-1100": 1185.46,
      "1000-1200": 1236.6,
      "1000-1300": 1299.36,
      "1000-1400": 1352.82,
      "1100-600": 953.02,
      "1100-700": 1006.48,
      "1100-800": 1048.32,
      "1100-900": 1132.0,
      "1100-1000": 1185.46,
      "1100-1100": 1257.52,
      "1100-1200": 1310.98,
      "1100-1300": 1383.04,
      "1100-1400": 1436.5,
      "1200-600": 985.56,
      "1200-700": 1036.7,
      "1200-800": 1101.78,
      "1200-900": 1173.84,
      "1200-1000": 1236.6,
      "1200-1100": 1310.98,
      "1200-1200": 1362.12,
      "1200-1300": 1436.5,
      "1200-1400": 1499.26,
      "1300-600": 1015.78,
      "1300-700": 1090.16,
      "1300-800": 1152.92,
      "1300-900": 1236.6,
      "1300-1000": 1299.36,
      "1300-1100": 1373.74,
      "1300-1200": 1436.5,
      "1300-1300": 1520.18,
      "1300-1400": 1573.64,
      "1400-600": 1059.94,
      "1400-700": 1132.0,
      "1400-800": 1194.76,
      "1400-900": 1290.06,
      "1400-1000": 1352.82,
      "1400-1100": 1436.5,
      "1400-1200": 1487.64,
      "1400-1300": 1573.64,
      "1400-1400": 1636.4,
      "1500-600": 1111.08,
      "1500-700": 1194.76,
      "1500-800": 1257.52,
      "1500-900": 1352.82,
      "1500-1000": 1415.58,
      "1500-1100": 1508.56,
      "1500-1200": 1573.64,
      "1500-1300": 1657.32,
      "1500-1400": 1729.38,
      "1600-600": 1152.92,
      "1600-700": 1236.6,
      "1600-800": 1299.36,
      "1600-900": 1403.96,
      "1600-1000": 1466.72,
      "1600-1100": 1562.02,
      "1600-1200": 1624.78,
      "1600-1300": 1720.08,
      "1600-1400": 1792.14,
      "1700-600": 1206.38,
      "1700-700": 1299.36,
      "1700-800": 1373.74,
      "1700-900": 1466.72,
      "1700-1000": 1541.1,
      "1700-1100": 1636.4,
      "1700-1200": 1708.46,
      "1700-1300": 1803.76,
      "1700-1400": 1875.82,
      "1800-600": 1341.2,
      "1800-700": 1450.45,
      "1800-800": 1538.78,
      "1800-900": 1650.35,
      "1800-1000": 1736.35,
      "1800-1100": 1847.93,
      "1800-1200": 1924.63,
      "1800-1300": 2033.88,
      "1800-1400": 2110.59,
      "1900-600": 1396.99,
      "1900-700": 1517.86,
      "1900-800": 1606.19,
      "1900-900": 1727.06,
      "1900-1000": 1803.76,
      "1900-1100": 1924.63,
      "1900-1200": 2012.96,
      "1900-1300": 2122.21,
      "1900-1400": 2210.54,
      "2000-600": 1441.15,
      "2000-700": 1562.02,
      "2000-800": 1650.35,
      "2000-900": 1780.52,
      "2000-1000": 1857.23,
      "2000-1100": 1978.1,
      "2000-1200": 2066.42,
      "2000-1300": 2187.3,
      "2000-1400": 2275.62,
      "2100-600": 1506.24,
      "2100-700": 1627.11,
      "2100-800": 1715.43,
      "2100-900": 1847.93,
      "2100-1000": 1945.55,
      "2100-1100": 2066.42,
      "2100-1200": 2154.75,
      "2100-1300": 2287.25,
      "2100-1400": 2375.58,
      "2200-600": 1550.4,
      "2200-700": 1671.27,
      "2200-800": 1771.22,
      "2200-900": 1901.39,
      "2200-1000": 1989.72,
      "2200-1100": 2133.83,
      "2200-1200": 2219.84,
      "2200-1300": 2352.33,
      "2200-1400": 2440.66,
      "2300-600": 1594.56,
      "2300-700": 1727.06,
      "2300-800": 1824.68,
      "2300-900": 1968.8,
      "2300-1000": 2057.13,
      "2300-1100": 2198.92,
      "2300-1200": 2298.87,
      "2300-1300": 2429.04,
      "2300-1400": 2528.99,
      "2400-600": 1638.73,
      "2400-700": 1771.22,
      "2400-800": 1868.85,
      "2400-900": 2012.96,
      "2400-1000": 2110.59,
      "2400-1100": 2254.7,
      "2400-1200": 2352.33,
      "2400-1300": 2496.45,
      "2400-1400": 2594.07,
      "2500-600": 1692.19,
      "2500-700": 1824.68,
      "2500-800": 1933.93,
      "2500-900": 2089.67,
      "2500-1000": 2187.3,
      "2500-1100": 2340.71,
      "2500-1200": 2440.66,
      "2500-1300": 2584.77,
      "2600-600": 1727.06,
      "2600-700": 1880.47,
      "2600-800": 1978.1,
      "2600-900": 2133.83,
      "2600-1000": 2243.08,
      "2600-1100": 2396.5,
      "2600-1200": 2496.45,
    },
  },
  {
    image: "/images/products/finestra-2-ante.png",
    name: "FINESTRA 2 ANTE con anta a ribalta e asta a leva",
    code: "2",
    pricesPerMeasures: {
      "700-900": 1355.15,
      "700-1000": 1376.07,
      "700-1100": 1406.28,
      "700-1200": 1424.88,
      "700-1300": 1455.1,
      "700-1400": 1476.02,
      "700-1500": 1506.24,
      "700-1600": 1524.83,
      "700-1700": 1555.05,
      "700-1800": 1606.19,
      "700-1900": 1624.78,
      "700-2000": 1655.0,
      "700-2100": 1694.51,
      "700-2200": 1734.03,
      "800-900": 1396.99,
      "800-1000": 1415.58,
      "800-1100": 1445.8,
      "800-1200": 1466.72,
      "800-1300": 1496.94,
      "800-1400": 1524.83,
      "800-1500": 1545.75,
      "800-1600": 1575.97,
      "800-1700": 1594.56,
      "800-1800": 1645.7,
      "800-1900": 1675.92,
      "800-2000": 1734.03,
      "800-2100": 1785.17,
      "800-2200": 1824.68,
      "900-900": 1455.1,
      "900-1000": 1476.02,
      "900-1100": 1506.24,
      "900-1200": 1524.83,
      "900-1300": 1555.05,
      "900-1400": 1585.27,
      "900-1500": 1606.19,
      "900-1600": 1645.7,
      "900-1700": 1694.51,
      "900-1800": 1754.95,
      "900-1900": 1803.76,
      "900-2000": 1864.2,
      "900-2100": 1915.34,
      "900-2200": 1964.15,
      "1000-900": 1496.94,
      "1000-1000": 1524.83,
      "1000-1100": 1545.75,
      "1000-1200": 1575.97,
      "1000-1300": 1594.56,
      "1000-1400": 1624.78,
      "1000-1500": 1655.0,
      "1000-1600": 1724.73,
      "1000-1700": 1764.25,
      "1000-1800": 1845.6,
      "1000-1900": 1885.12,
      "1000-2000": 1954.85,
      "1000-2100": 2003.67,
      "1000-2200": 2054.8,
      "1100-900": 1536.45,
      "1100-1000": 1566.67,
      "1100-1100": 1585.27,
      "1100-1200": 1615.48,
      "1100-1300": 1636.4,
      "1100-1400": 1694.51,
      "1100-1500": 1745.65,
      "1100-1600": 1815.39,
      "1100-1700": 1864.2,
      "1100-1800": 1945.55,
      "1100-1900": 1994.37,
      "1100-2000": 2064.1,
      "1100-2100": 2112.91,
      "1100-2200": 2173.35,
      "1200-900": 1575.97,
      "1200-1000": 1606.19,
      "1200-1100": 1636.4,
      "1200-1200": 1655.0,
      "1200-1300": 1706.14,
      "1200-1400": 1764.25,
      "1200-1500": 1815.39,
      "1200-1600": 1894.42,
      "1200-1700": 1954.85,
      "1200-1800": 2024.59,
      "1200-1900": 2073.4,
      "1200-2000": 2164.05,
      "1200-2100": 2212.86,
      "1200-2200": 2264.0,
      "1300-900": 1624.78,
      "1300-1000": 1655.0,
      "1300-1100": 1685.22,
      "1300-1200": 1745.65,
      "1300-1300": 1794.47,
      "1300-1400": 1854.9,
      "1300-1500": 1915.34,
      "1300-1600": 2003.67,
      "1300-1700": 2054.8,
      "1300-1800": 2133.83,
      "1300-1900": 2194.27,
      "1300-2000": 2273.3,
      "1300-2100": 2333.74,
      "1300-2200": 2394.17,
      "1400-900": 1675.92,
      "1400-1000": 1694.51,
      "1400-1100": 1724.73,
      "1400-1200": 1815.39,
      "1400-1300": 1864.2,
      "1400-1400": 1924.63,
      "1400-1500": 1985.07,
      "1400-1600": 2073.4,
      "1400-1700": 2133.83,
      "1400-1800": 2212.86,
      "1400-1900": 2273.3,
      "1400-2000": 2363.95,
      "1400-2100": 2422.06,
      "1400-2200": 2482.5,
      "1500-900": 1724.73,
      "1500-1000": 1754.95,
      "1500-1100": 1803.76,
      "1500-1200": 1903.71,
      "1500-1300": 1964.15,
      "1500-1400": 2033.88,
      "1500-1500": 2094.32,
      "1500-1600": 2184.97,
      "1500-1700": 2254.7,
      "1500-1800": 2333.74,
      "1500-1900": 2394.17,
      "1500-2000": 2494.12,
      "1500-2100": 2552.23,
      "1500-2200": 2621.97,
      "1600-900": 1775.87,
      "1600-1000": 1815.39,
      "1600-1100": 1875.82,
      "1600-1200": 1973.45,
      "1600-1300": 2033.88,
      "1600-1400": 2103.62,
      "1600-1500": 2164.05,
      "1600-1600": 2264.0,
      "1600-1700": 2333.74,
      "1600-1800": 2412.77,
      "1600-1900": 2482.5,
      "1600-2000": 2582.45,
      "1600-2100": 2642.89,
      "1600-2200": 2712.62,
      "1700-900": 1833.98,
      "1700-1000": 1894.42,
      "1700-1100": 1964.15,
      "1700-1200": 2073.4,
      "1700-1300": 2133.83,
      "1700-1400": 2203.57,
      "1700-1500": 2273.3,
      "1700-1600": 2382.55,
      "1700-1700": 2442.98,
      "1700-1800": 2542.93,
      "1700-1900": 2603.37,
      "1700-2000": 2712.62,
      "1700-2100": 2782.35,
      "1700-2200": 2852.08,
    },
  },
  {
    image: "/images/products/finestra-scorrevole-parallelo.png",
    name: "FINESTRA SCORREVOLE PARALLELO 1 anta fissa 1 anta mobile con ribalta con movimentazione semiautomatica",
    code: "3",
    pricesPerMeasures: {
      "1200-1600": 4183.99,
      "1200-1700": 4253.72,
      "1200-1800": 4376.92,
      "1200-1900": 4460.6,
      "1200-2000": 4611.68,
      "1200-2100": 4695.36,
      "1200-2200": 4790.67,
      "1200-2300": 4930.13,
      "1200-2400": 5025.43,
      "1200-2500": 5095.17,
      "1200-2600": 5218.36,
      "1200-2700": 5467.08,
      "1200-2800": 5564.7,
      "1200-2900": 5646.06,
      "1200-3000": 5757.63,
      "1200-3100": 5855.26,
      "1200-3200": 5964.51,
      "1300-1600": 4293.24,
      "1300-1700": 4376.92,
      "1300-1800": 4500.11,
      "1300-1900": 4583.79,
      "1300-2000": 4748.83,
      "1300-2100": 4832.51,
      "1300-2200": 4944.08,
      "1300-2300": 5081.22,
      "1300-2400": 5178.85,
      "1300-2500": 5260.2,
      "1300-2600": 5385.72,
      "1300-2700": 5634.44,
      "1300-2800": 5743.69,
      "1300-2900": 5827.36,
      "1300-3000": 5936.61,
      "1300-3100": 6048.19,
      "1300-3200": 6157.43,
      "1400-1600": 4418.76,
      "1400-1700": 4500.11,
      "1400-1800": 4639.58,
      "1400-1900": 4720.93,
      "1400-2000": 4902.24,
      "1400-2100": 4983.59,
      "1400-2200": 5081.22,
      "1400-2300": 5232.31,
      "1400-2400": 5343.88,
      "1400-2500": 5425.24,
      "1400-2600": 5564.7,
      "1400-2700": 5813.42,
      "1400-2800": 5936.61,
      "1400-2900": 6020.29,
      "1400-3000": 6129.54,
      "1400-3100": 6241.11,
      "1400-3200": 6364.31,
      "1500-1600": 4528.0,
      "1500-1700": 4625.63,
      "1500-1800": 4762.77,
      "1500-1900": 4846.45,
      "1500-2000": 5025.43,
      "1500-2100": 5123.06,
      "1500-2200": 5232.31,
      "1500-2300": 5385.72,
      "1500-2400": 5494.97,
      "1500-2500": 5592.6,
      "1500-2600": 5729.74,
      "1500-2700": 5978.45,
      "1500-2800": 6101.65,
      "1500-2900": 6199.27,
      "1500-3000": 6324.79,
      "1500-3100": 6434.04,
      "1500-3200": 6559.56,
      "1600-1600": 4681.42,
      "1600-1700": 4762.77,
      "1600-1800": 4902.24,
      "1600-1900": 4997.54,
      "1600-2000": 5190.47,
      "1600-2100": 5274.15,
      "1600-2200": 5399.67,
      "1600-2300": 5550.76,
      "1600-2400": 5673.95,
      "1600-2500": 5771.58,
      "1600-2600": 5908.72,
      "1600-2700": 6171.38,
      "1600-2800": 6310.85,
      "1600-2900": 6392.2,
      "1600-3000": 6531.67,
      "1600-3100": 6640.92,
      "1600-3200": 6780.38,
      "1700-1600": 4790.67,
      "1700-1700": 4888.29,
      "1700-1800": 5039.38,
      "1700-1900": 5137.01,
      "1700-2000": 5329.94,
      "1700-2100": 5425.24,
      "1700-2200": 5550.76,
      "1700-2300": 5701.85,
      "1700-2400": 5841.31,
      "1700-2500": 5936.61,
      "1700-2600": 6076.08,
      "1700-2700": 6350.36,
      "1700-2800": 6475.88,
      "1700-2900": 6571.18,
      "1700-3000": 6710.65,
      "1700-3100": 6833.85,
      "1700-3200": 6973.31,
      "1800-1600": 4916.19,
      "1800-1700": 5025.43,
      "1800-1800": 5178.85,
      "1800-1900": 5274.15,
      "1800-2000": 5481.02,
      "1800-2100": 5578.65,
      "1800-2200": 5715.79,
      "1800-2300": 5880.83,
      "1800-2400": 6006.35,
      "1800-2500": 6115.6,
      "1800-2600": 6269.01,
      "1800-2700": 6531.67,
      "1800-2800": 6668.81,
      "1800-2900": 6780.38,
      "1800-3000": 6917.53,
      "1800-3100": 7054.67,
      "1800-3200": 7180.19,
      "1900-1600": 5039.38,
      "1900-1700": 5137.01,
      "1900-1800": 5302.04,
      "1900-1900": 5411.29,
      "1900-2000": 5620.49,
      "1900-2100": 5715.79,
      "1900-2200": 5855.26,
      "1900-2300": 6020.29,
      "1900-2400": 6171.38,
      "1900-2500": 6269.01,
      "1900-2600": 6434.04,
      "1900-2700": 6696.7,
      "1900-2800": 6847.79,
      "1900-2900": 6959.37,
      "1900-3000": 7110.45,
      "1900-3100": 7235.97,
      "1900-3200": 7387.06,
      "2000-1600": 5150.95,
      "2000-1700": 5260.2,
      "2000-1800": 5425.24,
      "2000-1900": 5536.81,
      "2000-2000": 5743.69,
      "2000-2100": 5855.26,
      "2000-2200": 6006.35,
      "2000-2300": 6171.38,
      "2000-2400": 6324.79,
      "2000-2500": 6434.04,
      "2000-2600": 6599.08,
      "2000-2700": 6875.69,
      "2000-2800": 7010.92,
      "2000-2900": 7138.35,
      "2000-3000": 7289.44,
      "2000-3100": 7428.9,
      "2000-3200": 7579.99,
      "2100-1600": 5274.15,
      "2100-1700": 5385.72,
      "2100-1800": 5550.76,
      "2100-1900": 5673.95,
      "2100-2000": 5880.83,
      "2100-2100": 6006.35,
      "2100-2200": 6157.43,
      "2100-2300": 6324.79,
      "2100-2400": 6475.88,
      "2100-2500": 6585.13,
      "2100-2600": 6766.44,
      "2100-2700": 7040.72,
      "2100-2800": 7208.08,
      "2100-2900": 7317.33,
      "2100-3000": 7482.36,
      "2100-3100": 7621.83,
      "2100-3200": 7772.92,
      "2200-1600": 5399.67,
      "2200-1700": 5508.92,
      "2200-1800": 5687.9,
      "2200-1900": 5813.42,
      "2200-2000": 6034.24,
      "2200-2100": 6143.49,
      "2200-2200": 6310.85,
      "2200-2300": 6489.83,
      "2200-2400": 6640.92,
      "2200-2500": 6766.44,
      "2200-2600": 6945.42,
      "2200-2700": 7222.03,
      "2200-2800": 7387.06,
      "2200-2900": 7510.26,
      "2200-3000": 7677.62,
      "2200-3100": 7814.76,
      "2200-3200": 7979.79,
      "2300-1600": 5508.92,
      "2300-1700": 5634.44,
      "2300-1800": 5813.42,
      "2300-1900": 5936.61,
      "2300-2000": 6171.38,
      "2300-2100": 6296.9,
      "2300-2200": 6461.94,
      "2300-2300": 6640.92,
      "2300-2400": 6792.01,
      "2300-2500": 6917.53,
      "2300-2600": 7110.45,
      "2300-2700": 7401.01,
      "2300-2800": 7566.04,
      "2300-2900": 7677.62,
      "2300-3000": 7856.6,
      "2300-3100": 8007.69,
      "2300-3200": 8172.72,
      "2400-1600": 5634.44,
      "2400-1700": 5771.58,
      "2400-1800": 5950.56,
      "2400-1900": 6076.08,
      "2400-2000": 6324.79,
      "2400-2100": 6447.99,
      "2400-2200": 6613.02,
      "2400-2300": 6805.95,
      "2400-2400": 6973.31,
      "2400-2500": 7096.51,
      "2400-2600": 7289.44,
      "2400-2700": 7579.99,
      "2400-2800": 7758.97,
      "2400-2900": 7884.49,
      "2400-3000": 8063.47,
      "2400-3100": 8214.56,
      "2400-3200": 8381.92,
      "2500-1600": 5757.63,
      "2500-1700": 5880.83,
      "2500-1800": 6090.03,
      "2500-1900": 6213.22,
      "2500-2000": 6447.99,
      "2500-2100": 6585.13,
      "2500-2200": 6766.44,
      "2500-2300": 6945.42,
      "2500-2400": 7124.4,
      "2500-2500": 7261.54,
      "2500-2600": 7456.8,
      "2500-2700": 7745.03,
      "2500-2800": 7926.33,
      "2500-2900": 8063.47,
      "2500-3000": 8256.4,
      "2500-3100": 8407.49,
      "2500-3200": 8574.85,
      "2600-1600": 5894.77,
      "2600-1700": 6020.29,
      "2600-1800": 6227.17,
      "2600-1900": 6350.36,
      "2600-2000": 6599.08,
      "2600-2100": 6738.54,
      "2600-2200": 6917.53,
      "2600-2300": 7110.45,
      "2600-2400": 7303.38,
      "2600-2500": 7428.9,
      "2600-2600": 7635.78,
      "2600-2700": 7940.28,
      "2600-2800": 8119.26,
      "2600-2900": 8256.4,
      "2600-3000": 8449.33,
      "2600-3100": 8616.69,
      "2600-3200": 8795.67,
      "2700-1600": 6006.35,
      "2700-1700": 6143.49,
      "2700-1800": 6350.36,
      "2700-1900": 6489.83,
      "2700-2000": 6738.54,
      "2700-2100": 6875.69,
      "2700-2200": 7068.61,
      "2700-2300": 7261.54,
      "2700-2400": 7456.8,
      "2700-2500": 7593.94,
      "2700-2600": 7800.81,
      "2700-2700": 8105.31,
      "2700-2800": 8298.24,
      "2700-2900": 8435.38,
      "2700-3000": 8630.64,
      "2700-3100": 8795.67,
      "2700-3200": 8988.6,
      "2800-1600": 6115.6,
      "2800-1700": 6269.01,
      "2800-1800": 6475.88,
      "2800-1900": 6626.97,
      "2800-2000": 6875.69,
      "2800-2100": 7026.77,
      "2800-2200": 7222.03,
      "2800-2300": 7428.9,
      "2800-2400": 7621.83,
      "2800-2500": 7758.97,
      "2800-2600": 7965.85,
      "2800-2700": 8284.3,
      "2800-2800": 8477.22,
      "2800-2900": 8630.64,
      "2800-3000": 8837.51,
      "2800-3100": 9002.55,
      "2800-3200": 9195.47,
    },
  },
  {
    image: "/images/products/porta-finestra-1-anta.png",
    name: "PORTA FINESTRA 1 ANTA telaio a girare con anta a ribalta",
    code: "4",
    pricesPerMeasures: {
      "1800-600": 1352.82,
      "1800-700": 1462.07,
      "1800-800": 1538.78,
      "1800-900": 1659.65,
      "1800-1000": 1736.35,
      "1800-1100": 1847.93,
      "1800-1200": 1924.63,
      "1800-1300": 2033.88,
      "1800-1400": 2122.21,
      "1900-600": 1396.99,
      "1900-700": 1506.24,
      "1900-800": 1594.56,
      "1900-900": 1715.43,
      "1900-1000": 1803.76,
      "1900-1100": 1913.01,
      "1900-1200": 2001.34,
      "1900-1300": 2122.21,
      "1900-1400": 2198.92,
      "2000-600": 1441.15,
      "2000-700": 1550.4,
      "2000-800": 1638.73,
      "2000-900": 1771.22,
      "2000-1000": 1857.23,
      "2000-1100": 1978.1,
      "2000-1200": 2057.13,
      "2000-1300": 2178.0,
      "2000-1400": 2264.0,
      "2100-600": 1494.61,
      "2100-700": 1615.48,
      "2100-800": 1703.81,
      "2100-900": 1836.31,
      "2100-1000": 1933.93,
      "2100-1100": 2057.13,
      "2100-1200": 2143.13,
      "2100-1300": 2275.62,
      "2100-1400": 2363.95,
      "2200-600": 1527.16,
      "2200-700": 1659.65,
      "2200-800": 1759.6,
      "2200-900": 1892.09,
      "2200-1000": 1978.1,
      "2200-1100": 2110.59,
      "2200-1200": 2210.54,
      "2200-1300": 2340.71,
      "2200-1400": 2429.04,
      "2300-600": 1582.94,
      "2300-700": 1715.43,
      "2300-800": 1813.06,
      "2300-900": 1957.18,
      "2300-1000": 2057.13,
      "2300-1100": 2187.3,
      "2300-1200": 2287.25,
      "2300-1300": 2429.04,
      "2300-1400": 2528.99,
      "2400-600": 1627.11,
      "2400-700": 1759.6,
      "2400-800": 1857.23,
      "2400-900": 2012.96,
      "2400-1000": 2110.59,
      "2400-1100": 2254.7,
      "2400-1200": 2352.33,
      "2400-1300": 2496.45,
      "2400-1400": 2594.07,
      "2500-600": 1682.89,
      "2500-700": 1824.68,
      "2500-800": 1924.63,
      "2500-900": 2078.05,
      "2500-1000": 2187.3,
      "2500-1100": 2331.41,
      "2500-1200": 2429.04,
      "2500-1300": 2584.77,
      "2600-600": 1727.06,
      "2600-700": 1868.85,
      "2600-800": 1978.1,
      "2600-900": 2133.83,
      "2600-1000": 2243.08,
      "2600-1100": 2384.87,
      "2600-1200": 2496.45,
      "2700-600": 1926.96,
      "2700-700": 2087.34,
      "2700-800": 2222.16,
      "2700-900": 2394.17,
      "2700-1000": 2515.04,
      "2700-1100": 2687.05,
      "2800-600": 1975.77,
      "2800-700": 2147.78,
      "2800-800": 2270.98,
      "2800-900": 2454.61,
      "2800-1000": 2577.8,
      "2800-1100": 2749.81,
      "2900-600": 2024.59,
      "2900-700": 2208.22,
      "2900-800": 2331.41,
      "2900-900": 2528.99,
      "2900-1000": 2649.86,
      "3000-600": 2073.4,
      "3000-700": 2259.35,
      "3000-800": 2394.17,
      "3000-900": 2577.8,
      "3000-1000": 2712.62,
    },
  },
  {
    image: "/images/products/porta-finestra-2-ante.png",
    name: "PORTA FINESTRA 2 ANTE telaio a girare con anta a ribalta e asta a leva",
    code: "5",
    pricesPerMeasures: {
      "1800-900": 2685,
      "1800-1000": 2717,
      "1800-1100": 2759,
      "1800-1200": 2792,
      "1800-1300": 2836,
      "1800-1400": 2868,
      "1800-1500": 2910,
      "1800-1600": 3008,
      "1800-1700": 3103,
      "1800-1800": 3201,
      "1800-1900": 3287,
      "1800-2000": 3415,
      "1800-2100": 3501,
      "1800-2200": 3587,
      "1900-900": 2738,
      "1900-1000": 2782,
      "1900-1100": 2813,
      "1900-1200": 2857,
      "1900-1300": 2889,
      "1900-1400": 2943,
      "1900-1500": 2996,
      "1900-1600": 3124,
      "1900-1700": 3210,
      "1900-1800": 3329,
      "1900-1900": 3415,
      "1900-2000": 3545,
      "1900-2100": 3640,
      "1900-2200": 3726,
      "2000-900": 2782,
      "2000-1000": 2824,
      "2000-1100": 2857,
      "2000-1200": 2899,
      "2000-1300": 2954,
      "2000-1400": 3017,
      "2000-1500": 3082,
      "2000-1600": 3210,
      "2000-1700": 3296,
      "2000-1800": 3415,
      "2000-1900": 3501,
      "2000-2000": 3640,
      "2000-2100": 3738,
      "2000-2200": 3833,
      "2100-900": 2845,
      "2100-1000": 2889,
      "2100-1100": 2922,
      "2100-1200": 2985,
      "2100-1300": 3050,
      "2100-1400": 3124,
      "2100-1500": 3189,
      "2100-1600": 3329,
      "2100-1700": 3426,
      "2100-1800": 3545,
      "2100-1900": 3631,
      "2100-2000": 3780,
      "2100-2100": 3877,
      "2100-2200": 3972,
      "2200-900": 2889,
      "2200-1000": 2931,
      "2200-1100": 2964,
      "2200-1200": 3061,
      "2200-1300": 3124,
      "2200-1400": 3201,
      "2200-1500": 3275,
      "2200-1600": 3415,
      "2200-1700": 3512,
      "2200-1800": 3631,
      "2200-1900": 3726,
      "2200-2000": 3877,
      "2200-2100": 3972,
      "2200-2200": 4070,
      "2300-900": 2943,
      "2300-1000": 2985,
      "2300-1100": 3038,
      "2300-1200": 3147,
      "2300-1300": 3222,
      "2300-1400": 3296,
      "2300-1500": 3373,
      "2300-1600": 3522,
      "2300-1700": 3619,
      "2300-1800": 3747,
      "2300-1900": 3845,
      "2300-2000": 3996,
      "2300-2100": 4103,
      "2300-2200": 4210,
      "2400-900": 2985,
      "2400-1000": 3029,
      "2400-1100": 3103,
      "2400-1200": 3222,
      "2400-1300": 3296,
      "2400-1400": 3373,
      "2400-1500": 3459,
      "2400-1600": 3598,
      "2400-1700": 3717,
      "2400-1800": 3833,
      "2400-1900": 3931,
      "2400-2000": 4091,
      "2400-2100": 4210,
      "2400-2200": 4307,
      "2500-900": 3029,
      "2500-1000": 3103,
      "2500-1100": 3189,
      "2500-1200": 3308,
      "2500-1300": 3382,
      "2500-1400": 3468,
      "2500-1500": 3545,
      "2500-1600": 3705,
      "2500-1700": 3812,
      "2500-1800": 3942,
      "2500-1900": 4049,
      "2500-2000": 4221,
      "2500-2100": 4328,
      "2500-2200": 4435,
      "2600-900": 3082,
      "2600-1000": 3168,
      "2600-1100": 3254,
      "2600-1200": 3382,
      "2600-1300": 3459,
      "2600-1400": 3554,
      "2600-1500": 3631,
      "2600-1600": 3791,
      "2600-1700": 3898,
      "2600-1800": 4038,
      "2600-1900": 4135,
      "2600-2000": 4316,
      "2600-2100": 4423,
      "2600-2200": 4533,
      "2700-900": 3082,
      "2700-1000": 3198,
      "2700-1100": 3305,
      "2700-1200": 3517,
      "2700-1300": 3635,
      "2700-1400": 3752,
      "2700-1500": 3870,
      "2700-1600": 4070,
      "2700-1700": 4186,
      "2700-1800": 4340,
      "2700-1900": 4458,
      "2700-2000": 4658,
      "2700-2100": 4774,
      "2700-2200": 4893,
      "2800-900": 3152,
      "2800-1000": 3270,
      "2800-1100": 3398,
      "2800-1200": 3598,
      "2800-1300": 3717,
      "2800-1400": 3847,
      "2800-1500": 3963,
      "2800-1600": 4175,
      "2800-1700": 4293,
      "2800-1800": 4447,
      "2800-1900": 4563,
      "2800-2000": 4763,
      "2800-2100": 4893,
      "2800-2200": 5011,
      "2900-900": 3236,
      "2900-1000": 3363,
      "2900-1100": 3482,
      "2900-1200": 3694,
      "2900-1300": 3835,
      "2900-1400": 3952,
      "2900-1500": 4082,
      "2900-1600": 4293,
      "2900-1700": 4423,
      "2900-1800": 4574,
      "2900-1900": 4693,
      "2900-2000": 4916,
      "3000-900": 3317,
      "3000-1000": 3436,
      "3000-1100": 3563,
      "3000-1200": 3787,
      "3000-1300": 3917,
      "3000-1400": 4047,
      "3000-1500": 4163,
      "3000-1600": 4386,
      "3000-1700": 4516,
      "3000-1800": 4681,
      "3000-1900": 4798,
      "3000-2000": 5023,
    },
  },
  {
    image: "/images/products/porta-finestra-3-ante.png",
    name: "PORTA FINESTRA 3 ANTE con telaio a girare, con anta a ribalta e asta a leva e montante verticale fisso",
    code: "6",
    pricesPerMeasures: {
      "1800-1600": 4046.85,
      "1800-1700": 4077.06,
      "1800-1800": 4118.9,
      "1800-1900": 4151.45,
      "1800-2000": 4193.29,
      "1800-2100": 4235.13,
      "1800-2200": 4267.67,
      "1800-2300": 4330.43,
      "1800-2400": 4423.4,
      "1800-2500": 4465.24,
      "1800-2600": 4623.31,
      "1800-2700": 4718.61,
      "1800-2800": 4823.21,
      "1800-2900": 4927.81,
      "1800-3000": 5020.79,
      "1800-3100": 5053.33,
      "1800-3200": 5188.14,
      "1800-3300": 5295.07,
      "1900-1600": 4118.9,
      "1900-1700": 4160.74,
      "1900-1800": 4193.29,
      "1900-1900": 4225.83,
      "1900-2000": 4267.67,
      "1900-2100": 4330.43,
      "1900-2200": 4393.19,
      "1900-2300": 4486.16,
      "1900-2400": 4590.76,
      "1900-2500": 4632.6,
      "1900-2600": 4781.37,
      "1900-2700": 4885.97,
      "1900-2800": 4999.87,
      "1900-2900": 5104.46,
      "1900-3000": 5209.06,
      "1900-3100": 5241.61,
      "1900-3200": 5378.75,
      "1900-3300": 5483.35,
      "2000-1600": 4181.66,
      "2000-1700": 4225.83,
      "2000-1800": 4256.05,
      "2000-1900": 4297.88,
      "2000-2000": 4351.35,
      "2000-2100": 4435.03,
      "2000-2200": 4507.08,
      "2000-2300": 4602.39,
      "2000-2400": 4706.99,
      "2000-2500": 4748.83,
      "2000-2600": 4906.89,
      "2000-2700": 5011.49,
      "2000-2800": 5125.38,
      "2000-2900": 5241.61,
      "2000-3000": 5346.21,
      "2000-3100": 5388.05,
      "2000-3200": 5513.57,
      "2000-3300": 5629.79,
      "2100-1600": 4276.97,
      "2100-1700": 4309.51,
      "2100-1800": 4393.19,
      "2100-1900": 4423.4,
      "2100-2000": 4507.08,
      "2100-2100": 4590.76,
      "2100-2200": 4674.44,
      "2100-2300": 4769.75,
      "2100-2400": 4885.97,
      "2100-2500": 4916.19,
      "2100-2600": 5083.54,
      "2100-2700": 5199.77,
      "2100-2800": 5315.99,
      "2100-2900": 5429.89,
      "2100-3000": 5555.41,
      "2100-3100": 5587.95,
      "2100-3200": 5722.77,
      "2100-3300": 5838.99,
      "2200-1600": 4339.72,
      "2200-1700": 4402.48,
      "2200-1800": 4497.79,
      "2200-1900": 4528.0,
      "2200-2000": 4611.68,
      "2200-2100": 4695.36,
      "2200-2200": 4790.67,
      "2200-2300": 4885.97,
      "2200-2400": 5011.49,
      "2200-2500": 5041.7,
      "2200-2600": 5199.77,
      "2200-2700": 5325.29,
      "2200-2800": 5450.81,
      "2200-2900": 5567.03,
      "2200-3000": 5692.55,
      "2200-3100": 5722.77,
      "2200-3200": 5859.91,
      "2200-3300": 5976.13,
      "2300-1600": 4444.32,
      "2300-1700": 4539.63,
      "2300-1800": 4623.31,
      "2300-1900": 4653.52,
      "2300-2000": 4760.45,
      "2300-2100": 4853.43,
      "2300-2200": 4937.11,
      "2300-2300": 5041.7,
      "2300-2400": 5167.22,
      "2300-2500": 5209.06,
      "2300-2600": 5378.75,
      "2300-2700": 5492.65,
      "2300-2800": 5629.79,
      "2300-2900": 5755.31,
      "2300-3000": 5871.53,
      "2300-3100": 5901.75,
      "2300-3200": 6048.19,
      "2300-3300": 6185.33,
      "2400-1600": 4539.63,
      "2400-1700": 4644.23,
      "2400-1800": 4727.91,
      "2400-1900": 4769.75,
      "2400-2000": 4865.05,
      "2400-2100": 4958.03,
      "2400-2200": 5053.33,
      "2400-2300": 5167.22,
      "2400-2400": 5283.45,
      "2400-2500": 5325.29,
      "2400-2600": 5504.27,
      "2400-2700": 5618.17,
      "2400-2800": 5755.31,
      "2400-2900": 5892.45,
      "2400-3000": 6006.35,
      "2400-3100": 6048.19,
      "2400-3200": 6194.63,
      "2400-3300": 6322.47,
      "2500-1600": 4665.15,
      "2500-1700": 4760.45,
      "2500-1800": 4865.05,
      "2500-1900": 4895.27,
      "2500-2000": 4999.87,
      "2500-2100": 5095.17,
      "2500-2200": 5199.77,
      "2500-2300": 5304.37,
      "2500-2400": 5441.51,
      "2500-2500": 5471.73,
      "2500-2600": 5650.71,
      "2500-2700": 5797.15,
      "2500-2800": 5922.67,
      "2500-2900": 6059.81,
      "2500-3000": 6194.63,
      "2500-3100": 6227.17,
      "2500-3200": 6385.23,
      "2500-3300": 6510.75,
      "2600-1600": 4760.45,
      "2600-1700": 4865.05,
      "2600-1800": 4969.65,
      "2600-1900": 4999.87,
      "2600-2000": 5104.46,
      "2600-2100": 5220.69,
      "2600-2200": 5315.99,
      "2600-2300": 5420.59,
      "2600-2400": 5567.03,
      "2600-2500": 5597.25,
      "2600-2600": 5787.85,
      "2600-2700": 5922.67,
      "2600-2800": 6059.81,
      "2600-2900": 6185.33,
      "2600-3000": 6331.77,
      "2600-3100": 6364.31,
      "2600-3200": 6520.05,
      "2600-3300": 6666.49,
    },
  },
  {
    image: "/images/products/vasistas-1-anta.png",
    name: "VASISTAS 1 anta con maniglia laterale",
    code: "7",
    pricesPerMeasures: {
      "600-600": 848.42,
      "600-700": 880.96,
      "600-800": 913.5,
      "600-900": 946.05,
      "600-1000": 976.26,
      "700-600": 880.96,
      "700-700": 913.5,
      "700-800": 934.42,
      "700-900": 976.26,
      "700-1000": 1008.81,
      "700-1100": 1052.97,
      "800-600": 913.5,
      "800-700": 934.42,
      "800-800": 966.97,
      "800-900": 1008.81,
      "800-1000": 1041.35,
      "800-1100": 1106.43,
      "800-1200": 1148.27,
      "900-600": 955.34,
      "900-700": 976.26,
      "900-800": 1008.81,
      "900-900": 1073.89,
      "900-1000": 1127.35,
      "900-1100": 1192.44,
      "900-1200": 1234.28,
      "900-1300": 1299.36,
      "900-1400": 1352.82,
      "1000-600": 976.26,
      "1000-700": 1008.81,
      "1000-800": 1052.97,
      "1000-900": 1127.35,
      "1000-1000": 1180.81,
      "1000-1100": 1245.9,
      "1000-1200": 1287.74,
      "1000-1300": 1364.44,
      "1000-1400": 1417.91,
      "1000-1500": 1513.21,
      "1100-600": 1008.81,
      "1100-700": 1052.97,
      "1100-800": 1106.43,
      "1100-900": 1180.81,
      "1100-1000": 1245.9,
      "1100-1100": 1310.98,
      "1100-1200": 1364.44,
      "1100-1300": 1438.83,
      "1100-1400": 1492.29,
      "1100-1500": 1599.21,
      "1200-600": 1032.05,
      "1200-700": 1094.81,
      "1200-800": 1148.27,
      "1200-900": 1234.28,
      "1200-1000": 1287.74,
      "1200-1100": 1364.44,
      "1200-1200": 1417.91,
      "1200-1300": 1503.91,
      "1200-1400": 1557.37,
      "1200-1500": 1664.3,
      "1200-1600": 1729.38,
      "1200-1700": 1747.98,
      "1300-600": 1062.27,
      "1300-700": 1148.27,
      "1300-800": 1201.73,
      "1300-900": 1299.36,
      "1300-1000": 1352.82,
      "1300-1100": 1438.83,
      "1300-1200": 1503.91,
      "1300-1300": 1578.29,
      "1300-1400": 1643.38,
      "1300-1500": 1761.92,
      "1300-1600": 1815.39,
      "1300-1700": 1852.58,
      "1400-600": 1106.43,
      "1400-700": 1192.44,
      "1400-800": 1245.9,
      "1400-900": 1343.52,
      "1400-1000": 1406.28,
      "1400-1100": 1492.29,
      "1400-1200": 1557.37,
      "1400-1300": 1643.38,
      "1400-1400": 1708.46,
      "1400-1500": 1824.68,
      "1400-1600": 1889.77,
      "1400-1700": 1922.31,
      "1500-600": 1159.89,
      "1500-700": 1245.9,
      "1500-800": 1320.28,
      "1500-900": 1417.91,
      "1500-1000": 1482.99,
      "1500-1100": 1569.0,
      "1500-1200": 1631.76,
      "1500-1300": 1729.38,
      "1500-1400": 1794.47,
      "1500-1500": 1922.31,
      "1500-1600": 1987.39,
      "1500-1700": 2024.59,
      "1600-600": 1201.73,
      "1600-700": 1299.36,
      "1600-800": 1364.44,
      "1600-900": 1459.75,
      "1600-1000": 1536.45,
      "1600-1100": 1622.46,
      "1600-1200": 1696.84,
      "1600-1300": 1782.84,
      "1600-1400": 1857.23,
      "1600-1500": 1987.39,
      "1600-1600": 2050.15,
      "1600-1700": 2105.94,
      "1700-600": 1257.52,
      "1700-700": 1352.82,
      "1700-800": 1427.2,
      "1700-900": 1536.45,
      "1700-1000": 1599.21,
      "1700-1100": 1708.46,
      "1700-1200": 1771.22,
      "1700-1300": 1868.85,
      "1700-1400": 1943.23,
      "1700-1500": 2082.7,
      "1700-1600": 2159.4,
      "1700-1700": 2208.22,
    },
  },
  {
    image: "/images/products/vetro-fisso.png",
    name: "Vetro fisso",
    code: "8",
    pricesPerMeasures: {
      "500-500": 550.89,
      "500-600": 562.51,
      "500-700": 588.08,
      "500-800": 602.03,
      "500-900": 613.65,
      "500-1000": 639.22,
      "500-1100": 653.17,
      "500-1200": 678.74,
      "500-1300": 715.93,
      "500-1400": 755.44,
      "500-1500": 781.01,
      "500-1600": 832.15,
      "500-1700": 857.72,
      "500-1800": 908.86,
      "500-1900": 934.42,
      "600-500": 562.51,
      "600-600": 588.08,
      "600-700": 602.03,
      "600-800": 627.6,
      "600-900": 639.22,
      "600-1000": 678.74,
      "600-1100": 715.93,
      "600-1200": 755.44,
      "600-1300": 792.63,
      "600-1400": 843.77,
      "600-1500": 883.29,
      "600-1600": 934.42,
      "600-1700": 971.61,
      "600-1800": 1022.75,
      "600-1900": 1062.27,
      "700-500": 588.08,
      "700-600": 602.03,
      "700-700": 613.65,
      "700-800": 639.22,
      "700-900": 678.74,
      "700-1000": 729.87,
      "700-1100": 767.06,
      "700-1200": 818.2,
      "700-1300": 857.72,
      "700-1400": 920.48,
      "700-1500": 959.99,
      "700-1600": 1011.13,
      "700-1700": 1048.32,
      "700-1800": 1099.46,
      "700-1900": 1138.97,
      "800-500": 602.03,
      "800-600": 627.6,
      "800-700": 639.22,
      "800-800": 690.36,
      "800-900": 741.5,
      "800-1000": 792.63,
      "800-1100": 843.77,
      "800-1200": 894.91,
      "800-1300": 946.05,
      "800-1400": 1011.13,
      "800-1500": 1048.32,
      "800-1600": 1113.41,
      "800-1700": 1164.54,
      "800-1800": 1215.68,
      "800-1900": 1266.82,
      "900-500": 613.65,
      "900-600": 639.22,
      "900-700": 678.74,
      "900-800": 741.5,
      "900-900": 792.63,
      "900-1000": 857.72,
      "900-1100": 894.91,
      "900-1200": 959.99,
      "900-1300": 1011.13,
      "900-1400": 1073.89,
      "900-1500": 1125.03,
      "900-1600": 1190.11,
      "900-1700": 1241.25,
      "900-1800": 1304.01,
      "900-1900": 1355.15,
      "1000-500": 639.22,
      "1000-600": 678.74,
      "1000-700": 729.87,
      "1000-800": 792.63,
      "1000-900": 857.72,
      "1000-1000": 920.48,
      "1000-1100": 971.61,
      "1000-1200": 1048.32,
      "1000-1300": 1099.46,
      "1000-1400": 1176.17,
      "1000-1500": 1215.68,
      "1000-1600": 1292.39,
      "1000-1700": 1343.52,
      "1000-1800": 1420.23,
      "1000-1900": 1471.37,
      "1100-500": 653.17,
      "1100-600": 715.93,
      "1100-700": 767.06,
      "1100-800": 843.77,
      "1100-900": 894.91,
      "1100-1000": 971.61,
      "1100-1100": 1022.75,
      "1100-1200": 1113.41,
      "1100-1300": 1164.54,
      "1100-1400": 1241.25,
      "1100-1500": 1292.39,
      "1100-1600": 1369.09,
      "1100-1700": 1431.85,
      "1100-1800": 1496.94,
      "1100-1900": 1548.08,
      "1200-500": 678.74,
      "1200-600": 755.44,
      "1200-700": 818.2,
      "1200-800": 894.91,
      "1200-900": 959.99,
      "1200-1000": 1048.32,
      "1200-1100": 1113.41,
      "1200-1200": 1190.11,
      "1200-1300": 1252.87,
      "1200-1400": 1329.58,
      "1200-1500": 1394.66,
      "1200-1600": 1471.37,
      "1200-1700": 1534.13,
      "1200-1800": 1610.84,
      "1200-1900": 1675.92,
      "1300-500": 715.93,
      "1300-600": 792.63,
      "1300-700": 857.72,
      "1300-800": 946.05,
      "1300-900": 1011.13,
      "1300-1000": 1099.46,
      "1300-1100": 1164.54,
      "1300-1200": 1252.87,
      "1300-1300": 1317.96,
      "1300-1400": 1394.66,
      "1300-1500": 1457.42,
      "1300-1600": 1548.08,
      "1300-1700": 1610.84,
      "1300-1800": 1701.49,
      "1300-1900": 1764.25,
      "1400-500": 755.44,
      "1400-600": 843.77,
      "1400-700": 908.86,
      "1400-800": 1011.13,
      "1400-900": 1073.89,
      "1400-1000": 1164.54,
      "1400-1100": 1227.3,
      "1400-1200": 1329.58,
      "1400-1300": 1394.66,
      "1400-1400": 1496.94,
      "1400-1500": 1559.7,
      "1400-1600": 1650.35,
      "1400-1700": 1713.11,
      "1400-1800": 1803.76,
      "1400-1900": 1880.47,
      "1500-500": 781.01,
      "1500-600": 883.29,
      "1500-700": 959.99,
      "1500-800": 1048.32,
      "1500-900": 1125.03,
      "1500-1000": 1215.68,
      "1500-1100": 1292.39,
      "1500-1200": 1394.66,
      "1500-1300": 1457.42,
      "1500-1400": 1559.7,
      "1500-1500": 1624.78,
      "1500-1600": 1727.06,
      "1500-1700": 1806.04,
      "1500-1800": 1908.31,
      "1500-1900": 1968.8,
      "1600-500": 832.15,
      "1600-600": 934.42,
      "1600-700": 1011.13,
      "1600-800": 1113.41,
      "1600-900": 1190.11,
      "1600-1000": 1292.39,
      "1600-1100": 1369.09,
      "1600-1200": 1471.37,
      "1600-1300": 1548.08,
      "1600-1400": 1650.35,
      "1600-1500": 1727.06,
      "1600-1600": 1829.33,
      "1600-1700": 1906.04,
      "1600-1800": 2008.31,
      "1600-1900": 2085.02,
      "1700-500": 857.72,
      "1700-600": 971.61,
      "1700-700": 1048.32,
      "1700-800": 1164.54,
      "1700-900": 1241.25,
      "1700-1000": 1343.52,
      "1700-1100": 1420.23,
      "1700-1200": 1534.13,
      "1700-1300": 1610.84,
      "1700-1400": 1713.11,
      "1700-1500": 1803.76,
      "1700-1600": 1906.04,
      "1700-1700": 1982.75,
      "1700-1800": 2096.64,
      "1700-1900": 2173.35,
      "1800-500": 894.91,
      "1800-600": 1022.75,
      "1800-700": 1099.46,
      "1800-800": 1215.68,
      "1800-900": 1304.01,
      "1800-1000": 1420.23,
      "1800-1100": 1496.94,
      "1800-1200": 1610.84,
      "1800-1300": 1701.49,
      "1800-1400": 1815.39,
      "1800-1500": 1892.09,
      "1800-1600": 2008.31,
      "1800-1700": 2096.64,
      "1800-1800": 2212.86,
      "1800-1900": 2289.57,
      "1900-500": 934.42,
      "1900-600": 1062.27,
      "1900-700": 1138.97,
      "1900-800": 1266.82,
      "1900-900": 1355.15,
      "1900-1000": 1471.37,
      "1900-1100": 1548.08,
      "1900-1200": 1675.92,
      "1900-1300": 1764.25,
      "1900-1400": 1880.47,
      "1900-1500": 1968.8,
      "1900-1600": 2085.02,
      "1900-1700": 2173.35,
      "1900-1800": 2289.57,
      "1900-1900": 2377.9,
      "2000-500": 971.61,
      "2000-600": 1099.46,
      "2000-700": 1190.11,
      "2000-800": 1317.96,
      "2000-900": 1420.23,
      "2000-1000": 1534.13,
      "2000-1100": 1624.78,
      "2000-1200": 1752.63,
      "2000-1300": 1840.95,
      "2000-1400": 1982.75,
      "2000-1500": 2059.45,
      "2000-1600": 2187.3,
      "2000-1700": 2275.62,
      "2000-1800": 2403.47,
      "2000-1900": 2494.12,
      "2100-500": 1011.13,
      "2100-600": 1138.97,
      "2100-700": 1241.25,
      "2100-800": 1369.09,
      "2100-900": 1457.42,
      "2100-1000": 1599.21,
      "2100-1100": 1687.54,
      "2100-1200": 1815.39,
      "2100-1300": 1906.04,
      "2100-1400": 2033.88,
      "2100-1500": 2136.16,
      "2100-1600": 2264.0,
      "2100-1700": 2366.28,
      "2100-1800": 2494.12,
      "2100-1900": 2582.45,
      "2200-500": 1048.32,
      "2200-600": 1190.11,
      "2200-700": 1292.39,
      "2200-800": 1431.85,
      "2200-900": 1522.51,
      "2200-1000": 1661.97,
      "2200-1100": 1764.25,
      "2200-1200": 1906.04,
      "2200-1300": 1994.37,
      "2200-1400": 2136.16,
      "2200-1500": 2238.43,
      "2200-1600": 2366.28,
      "2200-1700": 2468.55,
      "2200-1800": 2608.02,
      "2200-1900": 2698.67,
      "2300-500": 1087.84,
      "2300-600": 1227.3,
      "2300-700": 1329.58,
      "2300-800": 1471.37,
      "2300-900": 1573.64,
      "2300-1000": 1713.11,
      "2300-1100": 1815.39,
      "2300-1200": 1957.18,
      "2300-1300": 2059.45,
      "2300-1400": 2198.92,
      "2300-1500": 2301.19,
      "2300-1600": 2442.98,
      "2300-1700": 2545.26,
      "2300-1800": 2684.73,
      "2300-1900": 2787.0,
      "2400-500": 1125.03,
      "2400-600": 1278.44,
      "2400-700": 1380.72,
      "2400-800": 1534.13,
      "2400-900": 1636.4,
      "2400-1000": 1789.82,
      "2400-1100": 1892.09,
      "2400-1200": 2045.51,
      "2400-1300": 2147.78,
      "2400-1400": 2301.19,
      "2400-1500": 2403.47,
      "2400-1600": 2545.26,
      "2400-1700": 2659.16,
      "2400-1800": 2800.95,
      "2400-1900": 2903.22,
    },
  },
];

// Colors of product
const colorsQuoteVLS = {
  general: [
    {
      image: "bianco-pasta",
      name: "Bianco pasta",
      code: "0000",
    },
    {
      image: "ral-9001",
      name: "RAL 9001",
      code: "1111",
    },
  ],
  standard: [
    {
      image: "standard-7774",
      name: "Frassino",
      code: "7774",
    },
    {
      image: "standard-1562L",
      name: "Sheffield Rovere Light",
      code: "1562L",
    },
    {
      image: "standard-7512",
      name: "Rovere Gold",
      code: "7512",
    },
    {
      image: "standard-225L",
      name: "Noce",
      code: "225L",
    },
    {
      image: "standard-4444",
      name: "Crema",
      code: "4444",
    },
  ],
  fuoriStandard: [
    {
      image: "not-standard-1423L",
      name: "Pino Nudo",
      code: "1423L",
    },
    {
      image: "not-standard-4404",
      name: "Douglas",
      code: "4404",
    },
    {
      image: "not-standard-2013L",
      name: "Quercia Irlandese",
      code: "2013L",
    },
    {
      image: "not-standard-2014L",
      name: "Ciliegio Soft",
      code: "2014L",
    },
    {
      image: "not-standard-1208L",
      name: "Whinchester XA",
      code: "1208L",
    },
    {
      image: "not-standard-1991L",
      name: "Noce Kolonial",
      code: "1991L",
    },
    {
      image: "not-standard-2038L",
      name: "Sheffield Oak Alpine",
      code: "2038L",
    },
    {
      image: "not-standard-2039L",
      name: "Sheffield Oak Concrete",
      code: "2039L",
    },
    {
      image: "not-standard-2031L",
      name: "Turner Oak Malt",
      code: "2031L",
    },
    {
      image: "not-standard-2451L",
      name: "Turner Oak Toffee",
      code: "2451L",
    },
    {
      image: "not-standard-2521L",
      name: "Kitami Dark",
      code: "2521L",
    },
    {
      image: "not-standard-9734",
      name: "Bianco Latte",
      code: "9734",
    },
    {
      image: "not-standard-7666",
      name: "Grigio Chiaro",
      code: "7666",
    },
    {
      image: "not-standard-1049L",
      name: "Metbrush Aluminium",
      code: "1049L",
    },
    {
      image: "not-standard-9922",
      name: "Grigio Argento",
      code: "9922",
    },
    {
      image: "not-standard-4443",
      name: "Grigio Antracite",
      code: "4443",
    },
    {
      image: "not-standard-4683",
      name: "Blu Brillante",
      code: "4683",
    },
    {
      image: "not-standard-4925",
      name: "Verde Muschio",
      code: "4925",
    },
    {
      image: "not-standard-9773",
      name: "Verde Scuro",
      code: "9773",
    },
    {
      image: "not-standard-9792",
      name: "Rosso Scuro",
      code: "9792",
    },
    {
      image: "not-standard-012L",
      name: "Cioccolato",
      code: "012L",
    },
    {
      image: "not-standard-1443L",
      name: "Bronzo",
      code: "1443L",
    },
  ],
  matt: [
    {
      image: "matt-2426L",
      name: "Pepper Oak Super-Matt",
      code: "2426L",
    },
    {
      image: "matt-2532L",
      name: "Weissbach Eiche Super-Matt",
      code: "2532L",
    },
    {
      image: "matt-2533L",
      name: "Ginger Oak Super-Matt",
      code: "2533L",
    },
    {
      image: "matt-2534L",
      name: "Cinnamon Oak Super-Matt",
      code: "2534L",
    },
    {
      image: "matt-2366L",
      name: "Honey Oak Super-Matt",
      code: "2366L",
    },
    {
      image: "matt-2535L",
      name: "Amaranth Oak Super-Matt",
      code: "2535L",
    },
    {
      image: "matt-2536L",
      name: "Verkehrsweiss",
      code: "2536L",
    },
    {
      image: "matt-1551L",
      name: "Crema Matt",
      code: "1551L",
    },
    {
      image: "matt-2537L",
      name: "Quarzgrau",
      code: "2537L",
    },
    {
      image: "matt-2538L",
      name: "Umbragrau",
      code: "2538L",
    },
    {
      image: "matt-2539L",
      name: "S-Bronze",
      code: "2539L",
    },
    {
      image: "matt-2540L",
      name: "Fenstergrau",
      code: "2540L",
    },
    {
      image: "matt-2542L",
      name: "Antharazitgrau",
      code: "2542L",
    },
    {
      image: "matt-1560L",
      name: "Grigio Scuro Matt",
      code: "1560L",
    },
    {
      image: "matt-2543L",
      name: "Jet Black",
      code: "2543L",
    },
    {
      image: "matt-2541L",
      name: "Basaltgrau",
      code: "2541L",
    },
  ],
  woodLam: [
    {
      image: "woodlam-1579L",
      name: "Lam. Olmo Chiaro",
      code: "1579L",
    },
    {
      image: "woodlam-1738L",
      name: "Lam. Magellan Ice",
      code: "1738L",
    },
    {
      image: "woodlam-1743L",
      name: "Lam. Frassino Sbiancato",
      code: "1743L",
    },
    {
      image: "woodlam-2550L",
      name: "Lam. Ribeira White",
      code: "2550L",
    },
    {
      image: "woodlam-2551L",
      name: "Lam. Gladestone Oak Grey",
      code: "2551L",
    },
  ],
};

// Every combination of colors has a percentage more
const percentagePlusPerColorsVLS = {
  "0000-0000": 0,
  "1111-1111": 6,
  "7774-0000": 15,
  "1562L-0000": 15,
  "7512-0000": 15,
  "225L-0000": 15,
  "4444-0000": 15,
  "1423L-0000": 20,
  "4404-0000": 20,
  "2013L-0000": 20,
  "1208L-0000": 20,
  "1991L-0000": 20,
  "2038L-0000": 20,
  "2039L-0000": 20,
  "2031L-0000": 20,
  "2451L-0000": 20,
  "2521L-0000": 20,
  "9734-0000": 20,
  "7666-0000": 20,
  "1049L-0000": 20,
  "9922-0000": 20,
  "4443-0000": 20,
  "4683-0000": 20,
  "4925-0000": 20,
  "9773-0000": 20,
  "9792-0000": 20,
  "012L-0000": 20,
  "1443L-0000": 20,
  "7774-1111": 20,
  "1562L-1111": 20,
  "7512-1111": 20,
  "225L-1111": 20,
  "4444-1111": 20,
  "7774-7774": 20,
  "1562L-1562L": 20,
  "7512-7512": 20,
  "225L-225L": 20,
  "4444-4444": 20,
  "2426L-0000": 25,
  "2532L-0000": 25,
  "2533L-0000": 25,
  "2534L-0000": 25,
  "2366L-0000": 25,
  "2535L-0000": 25,
  "2536L-0000": 25,
  "1551L-0000": 25,
  "2537L-0000": 25,
  "2538L-0000": 25,
  "2539L-0000": 25,
  "2540L-0000": 25,
  "2542L-0000": 25,
  "1560L-0000": 25,
  "2543L-0000": 25,
  "2541L-0000": 25,
  "1423L-1111": 25,
  "4404-1111": 25,
  "2013L-1111": 25,
  "1208L-1111": 25,
  "1991L-1111": 25,
  "2038L-1111": 25,
  "2039L-1111": 25,
  "2031L-1111": 25,
  "2451L-1111": 25,
  "2521L-1111": 25,
  "9734-1111": 25,
  "7666-1111": 25,
  "1049L-1111": 25,
  "9922-1111": 25,
  "4443-1111": 25,
  "4683-1111": 25,
  "4925-1111": 25,
  "9773-1111": 25,
  "9792-1111": 25,
  "012L-1111": 25,
  "1443L-1111": 25,
  "1579L-0000": 30,
  "1738L-0000": 30,
  "1743L-0000": 30,
  "2550L-0000": 30,
  "2551L-0000": 30,
  "7774-1562L": 25,
  "7774-7512": 25,
  "7774-225L": 25,
  "7774-4444": 25,
  "1562L-7774": 25,
  "1562L-7512": 25,
  "1562L-225L": 25,
  "1562L-4444": 25,
  "7512-7774": 25,
  "7512-1562L": 25,
  "7512-225L": 25,
  "7512-4444": 25,
  "225L-7774": 25,
  "225L-1562L": 25,
  "225L-7512": 25,
  "225L-4444": 25,
  "4444-7774": 25,
  "4444-1562L": 25,
  "4444-7512": 25,
  "4444-225L": 25,
  "1579L-1111": 30,
  "1738L-1111": 30,
  "1743L-1111": 30,
  "2550L-1111": 30,
  "2551L-1111": 30,
  "1423L-7774": 25,
  "1423L-1562L": 25,
  "1423L-7512": 25,
  "1423L-225L": 25,
  "1423L-4444": 25,
  "4404-7774": 25,
  "4404-1562L": 25,
  "4404-7512": 25,
  "4404-225L": 25,
  "4404-4444": 25,
  "2013L-7774": 25,
  "2013L-1562L": 25,
  "2013L-7512": 25,
  "2013L-225L": 25,
  "2013L-4444": 25,
  "1208L-7774": 25,
  "1208L-1562L": 25,
  "1208L-7512": 25,
  "1208L-225L": 25,
  "1208L-4444": 25,
  "1991L-7774": 25,
  "1991L-1562L": 25,
  "1991L-7512": 25,
  "1991L-225L": 25,
  "1991L-4444": 25,
  "2038L-7774": 25,
  "2038L-1562L": 25,
  "2038L-7512": 25,
  "2038L-225L": 25,
  "2038L-4444": 25,
  "2039L-7774": 25,
  "2039L-1562L": 25,
  "2039L-7512": 25,
  "2039L-225L": 25,
  "2039L-4444": 25,
  "2031L-7774": 25,
  "2031L-1562L": 25,
  "2031L-7512": 25,
  "2031L-225L": 25,
  "2031L-4444": 25,
  "2451L-7774": 25,
  "2451L-1562L": 25,
  "2451L-7512": 25,
  "2451L-225L": 25,
  "2451L-4444": 25,
  "2521L-7774": 25,
  "2521L-1562L": 25,
  "2521L-7512": 25,
  "2521L-225L": 25,
  "2521L-4444": 25,
  "9734-7774": 25,
  "9734-1562L": 25,
  "9734-7512": 25,
  "9734-225L": 25,
  "9734-4444": 25,
  "7666-7774": 25,
  "7666-1562L": 25,
  "7666-7512": 25,
  "7666-225L": 25,
  "7666-4444": 25,
  "1049L-7774": 25,
  "1049L-1562L": 25,
  "1049L-7512": 25,
  "1049L-225L": 25,
  "1049L-4444": 25,
  "9922-7774": 25,
  "9922-1562L": 25,
  "9922-7512": 25,
  "9922-225L": 25,
  "9922-4444": 25,
  "4443-7774": 25,
  "4443-1562L": 25,
  "4443-7512": 25,
  "4443-225L": 25,
  "4443-4444": 25,
  "4683-7774": 25,
  "4683-1562L": 25,
  "4683-7512": 25,
  "4683-225L": 25,
  "4683-4444": 25,
  "4925-7774": 25,
  "4925-1562L": 25,
  "4925-7512": 25,
  "4925-225L": 25,
  "4925-4444": 25,
  "9773-7774": 25,
  "9773-1562L": 25,
  "9773-7512": 25,
  "9773-225L": 25,
  "9773-4444": 25,
  "9792-7774": 25,
  "9792-1562L": 25,
  "9792-7512": 25,
  "9792-225L": 25,
  "9792-4444": 25,
  "012L-7774": 25,
  "012L-1562L": 25,
  "012L-7512": 25,
  "012L-225L": 25,
  "012L-4444": 25,
  "1443L-7774": 25,
  "1443L-1562L": 25,
  "1443L-7512": 25,
  "1443L-225L": 25,
  "1443L-4444": 25,
  "1423L-1423L": 25,
  "4404-4404": 25,
  "2013L-2013L": 25,
  "1208L-1208L": 25,
  "1991L-1991L": 25,
  "2038L-2038L": 25,
  "2039L-2039L": 25,
  "2031L-2031L": 25,
  "2451L-2451L": 25,
  "2521L-2521L": 25,
  "9734-9734": 25,
  "7666-7666": 25,
  "1049L-1049L": 25,
  "9922-9922": 25,
  "4443-4443": 25,
  "4683-4683": 25,
  "4925-4925": 25,
  "9773-9773": 25,
  "9792-9792": 25,
  "012L-012L": 25,
  "1443L-1443L": 25,
  "2426L-7774": 25,
  "2426L-1562L": 25,
  "2426L-7512": 25,
  "2426L-225L": 25,
  "2426L-4444": 25,
  "2532L-7774": 25,
  "2532L-1562L": 25,
  "2532L-7512": 25,
  "2532L-225L": 25,
  "2532L-4444": 25,
  "2533L-7774": 25,
  "2533L-1562L": 25,
  "2533L-7512": 25,
  "2533L-225L": 25,
  "2533L-4444": 25,
  "2534L-7774": 25,
  "2534L-1562L": 25,
  "2534L-7512": 25,
  "2534L-225L": 25,
  "2534L-4444": 25,
  "2366L-7774": 25,
  "2366L-1562L": 25,
  "2366L-7512": 25,
  "2366L-225L": 25,
  "2366L-4444": 25,
  "2535L-7774": 25,
  "2535L-1562L": 25,
  "2535L-7512": 25,
  "2535L-225L": 25,
  "2535L-4444": 25,
  "2536L-7774": 25,
  "2536L-1562L": 25,
  "2536L-7512": 25,
  "2536L-225L": 25,
  "2536L-4444": 25,
  "1551L-7774": 25,
  "1551L-1562L": 25,
  "1551L-7512": 25,
  "1551L-225L": 25,
  "1551L-4444": 25,
  "2537L-7774": 25,
  "2537L-1562L": 25,
  "2537L-7512": 25,
  "2537L-225L": 25,
  "2537L-4444": 25,
  "2538L-7774": 25,
  "2538L-1562L": 25,
  "2538L-7512": 25,
  "2538L-225L": 25,
  "2538L-4444": 25,
  "2539L-7774": 25,
  "2539L-1562L": 25,
  "2539L-7512": 25,
  "2539L-225L": 25,
  "2539L-4444": 25,
  "2540L-7774": 25,
  "2540L-1562L": 25,
  "2540L-7512": 25,
  "2540L-225L": 25,
  "2540L-4444": 25,
  "2542L-7774": 25,
  "2542L-1562L": 25,
  "2542L-7512": 25,
  "2542L-225L": 25,
  "2542L-4444": 25,
  "1560L-7774": 25,
  "1560L-1562L": 25,
  "1560L-7512": 25,
  "1560L-225L": 25,
  "1560L-4444": 25,
  "2543L-7774": 25,
  "2543L-1562L": 25,
  "2543L-7512": 25,
  "2543L-225L": 25,
  "2543L-4444": 25,
  "2541L-7774": 25,
  "2541L-1562L": 25,
  "2541L-7512": 25,
  "2541L-225L": 25,
  "2541L-4444": 25,
  "1579L-7774": 30,
  "1579L-1562L": 30,
  "1579L-7512": 30,
  "1579L-225L": 30,
  "1579L-4444": 30,
  "1738L-7774": 30,
  "1738L-1562L": 30,
  "1738L-7512": 30,
  "1738L-225L": 30,
  "1738L-4444": 30,
  "1743L-7774": 30,
  "1743L-1562L": 30,
  "1743L-7512": 30,
  "1743L-225L": 30,
  "1743L-4444": 30,
  "2550L-7774": 30,
  "2550L-1562L": 30,
  "2550L-7512": 30,
  "2550L-225L": 30,
  "2550L-4444": 30,
  "2551L-7774": 30,
  "2551L-1562L": 30,
  "2551L-7512": 30,
  "2551L-225L": 30,
  "2551L-4444": 30,
  "2426L-1423L": 25,
  "2426L-4404": 25,
  "2426L-2013L": 25,
  "2426L-1208L": 25,
  "2426L-1991L": 25,
  "2426L-2038L": 25,
  "2426L-2039L": 25,
  "2426L-2031L": 25,
  "2426L-2451L": 25,
  "2426L-2521L": 25,
  "2426L-9734": 25,
  "2426L-7666": 25,
  "2426L-1049L": 25,
  "2426L-9922": 25,
  "2426L-4443": 25,
  "2426L-4683": 25,
  "2426L-4925": 25,
  "2426L-9773": 25,
  "2426L-9792": 25,
  "2426L-012L": 25,
  "2426L-1443L": 25,
  "2532L-1423L": 25,
  "2532L-4404": 25,
  "2532L-2013L": 25,
  "2532L-1208L": 25,
  "2532L-1991L": 25,
  "2532L-2038L": 25,
  "2532L-2039L": 25,
  "2532L-2031L": 25,
  "2532L-2451L": 25,
  "2532L-2521L": 25,
  "2532L-9734": 25,
  "2532L-7666": 25,
  "2532L-1049L": 25,
  "2532L-9922": 25,
  "2532L-4443": 25,
  "2532L-4683": 25,
  "2532L-4925": 25,
  "2532L-9773": 25,
  "2532L-9792": 25,
  "2532L-012L": 25,
  "2532L-1443L": 25,
  "2533L-1423L": 25,
  "2533L-4404": 25,
  "2533L-2013L": 25,
  "2533L-1208L": 25,
  "2533L-1991L": 25,
  "2533L-2038L": 25,
  "2533L-2039L": 25,
  "2533L-2031L": 25,
  "2533L-2451L": 25,
  "2533L-2521L": 25,
  "2533L-9734": 25,
  "2533L-7666": 25,
  "2533L-1049L": 25,
  "2533L-9922": 25,
  "2533L-4443": 25,
  "2533L-4683": 25,
  "2533L-4925": 25,
  "2533L-9773": 25,
  "2533L-9792": 25,
  "2533L-012L": 25,
  "2533L-1443L": 25,
  "2534L-1423L": 25,
  "2534L-4404": 25,
  "2534L-2013L": 25,
  "2534L-1208L": 25,
  "2534L-1991L": 25,
  "2534L-2038L": 25,
  "2534L-2039L": 25,
  "2534L-2031L": 25,
  "2534L-2451L": 25,
  "2534L-2521L": 25,
  "2534L-9734": 25,
  "2534L-7666": 25,
  "2534L-1049L": 25,
  "2534L-9922": 25,
  "2534L-4443": 25,
  "2534L-4683": 25,
  "2534L-4925": 25,
  "2534L-9773": 25,
  "2534L-9792": 25,
  "2534L-012L": 25,
  "2534L-1443L": 25,
  "2366L-1423L": 25,
  "2366L-4404": 25,
  "2366L-2013L": 25,
  "2366L-1208L": 25,
  "2366L-1991L": 25,
  "2366L-2038L": 25,
  "2366L-2039L": 25,
  "2366L-2031L": 25,
  "2366L-2451L": 25,
  "2366L-2521L": 25,
  "2366L-9734": 25,
  "2366L-7666": 25,
  "2366L-1049L": 25,
  "2366L-9922": 25,
  "2366L-4443": 25,
  "2366L-4683": 25,
  "2366L-4925": 25,
  "2366L-9773": 25,
  "2366L-9792": 25,
  "2366L-012L": 25,
  "2366L-1443L": 25,
  "2535L-1423L": 25,
  "2535L-4404": 25,
  "2535L-2013L": 25,
  "2535L-1208L": 25,
  "2535L-1991L": 25,
  "2535L-2038L": 25,
  "2535L-2039L": 25,
  "2535L-2031L": 25,
  "2535L-2451L": 25,
  "2535L-2521L": 25,
  "2535L-9734": 25,
  "2535L-7666": 25,
  "2535L-1049L": 25,
  "2535L-9922": 25,
  "2535L-4443": 25,
  "2535L-4683": 25,
  "2535L-4925": 25,
  "2535L-9773": 25,
  "2535L-9792": 25,
  "2535L-012L": 25,
  "2535L-1443L": 25,
  "2536L-1423L": 25,
  "2536L-4404": 25,
  "2536L-2013L": 25,
  "2536L-1208L": 25,
  "2536L-1991L": 25,
  "2536L-2038L": 25,
  "2536L-2039L": 25,
  "2536L-2031L": 25,
  "2536L-2451L": 25,
  "2536L-2521L": 25,
  "2536L-9734": 25,
  "2536L-7666": 25,
  "2536L-1049L": 25,
  "2536L-9922": 25,
  "2536L-4443": 25,
  "2536L-4683": 25,
  "2536L-4925": 25,
  "2536L-9773": 25,
  "2536L-9792": 25,
  "2536L-012L": 25,
  "2536L-1443L": 25,
  "1551L-1423L": 25,
  "1551L-4404": 25,
  "1551L-2013L": 25,
  "1551L-1208L": 25,
  "1551L-1991L": 25,
  "1551L-2038L": 25,
  "1551L-2039L": 25,
  "1551L-2031L": 25,
  "1551L-2451L": 25,
  "1551L-2521L": 25,
  "1551L-9734": 25,
  "1551L-7666": 25,
  "1551L-1049L": 25,
  "1551L-9922": 25,
  "1551L-4443": 25,
  "1551L-4683": 25,
  "1551L-4925": 25,
  "1551L-9773": 25,
  "1551L-9792": 25,
  "1551L-012L": 25,
  "1551L-1443L": 25,
  "2537L-1423L": 25,
  "2537L-4404": 25,
  "2537L-2013L": 25,
  "2537L-1208L": 25,
  "2537L-1991L": 25,
  "2537L-2038L": 25,
  "2537L-2039L": 25,
  "2537L-2031L": 25,
  "2537L-2451L": 25,
  "2537L-2521L": 25,
  "2537L-9734": 25,
  "2537L-7666": 25,
  "2537L-1049L": 25,
  "2537L-9922": 25,
  "2537L-4443": 25,
  "2537L-4683": 25,
  "2537L-4925": 25,
  "2537L-9773": 25,
  "2537L-9792": 25,
  "2537L-012L": 25,
  "2537L-1443L": 25,
  "2538L-1423L": 25,
  "2538L-4404": 25,
  "2538L-2013L": 25,
  "2538L-1208L": 25,
  "2538L-1991L": 25,
  "2538L-2038L": 25,
  "2538L-2039L": 25,
  "2538L-2031L": 25,
  "2538L-2451L": 25,
  "2538L-2521L": 25,
  "2538L-9734": 25,
  "2538L-7666": 25,
  "2538L-1049L": 25,
  "2538L-9922": 25,
  "2538L-4443": 25,
  "2538L-4683": 25,
  "2538L-4925": 25,
  "2538L-9773": 25,
  "2538L-9792": 25,
  "2538L-012L": 25,
  "2538L-1443L": 25,
  "2539L-1423L": 25,
  "2539L-4404": 25,
  "2539L-2013L": 25,
  "2539L-1208L": 25,
  "2539L-1991L": 25,
  "2539L-2038L": 25,
  "2539L-2039L": 25,
  "2539L-2031L": 25,
  "2539L-2451L": 25,
  "2539L-2521L": 25,
  "2539L-9734": 25,
  "2539L-7666": 25,
  "2539L-1049L": 25,
  "2539L-9922": 25,
  "2539L-4443": 25,
  "2539L-4683": 25,
  "2539L-4925": 25,
  "2539L-9773": 25,
  "2539L-9792": 25,
  "2539L-012L": 25,
  "2539L-1443L": 25,
  "2540L-1423L": 25,
  "2540L-4404": 25,
  "2540L-2013L": 25,
  "2540L-1208L": 25,
  "2540L-1991L": 25,
  "2540L-2038L": 25,
  "2540L-2039L": 25,
  "2540L-2031L": 25,
  "2540L-2451L": 25,
  "2540L-2521L": 25,
  "2540L-9734": 25,
  "2540L-7666": 25,
  "2540L-1049L": 25,
  "2540L-9922": 25,
  "2540L-4443": 25,
  "2540L-4683": 25,
  "2540L-4925": 25,
  "2540L-9773": 25,
  "2540L-9792": 25,
  "2540L-012L": 25,
  "2540L-1443L": 25,
  "2542L-1423L": 25,
  "2542L-4404": 25,
  "2542L-2013L": 25,
  "2542L-1208L": 25,
  "2542L-1991L": 25,
  "2542L-2038L": 25,
  "2542L-2039L": 25,
  "2542L-2031L": 25,
  "2542L-2451L": 25,
  "2542L-2521L": 25,
  "2542L-9734": 25,
  "2542L-7666": 25,
  "2542L-1049L": 25,
  "2542L-9922": 25,
  "2542L-4443": 25,
  "2542L-4683": 25,
  "2542L-4925": 25,
  "2542L-9773": 25,
  "2542L-9792": 25,
  "2542L-012L": 25,
  "2542L-1443L": 25,
  "1560L-1423L": 25,
  "1560L-4404": 25,
  "1560L-2013L": 25,
  "1560L-1208L": 25,
  "1560L-1991L": 25,
  "1560L-2038L": 25,
  "1560L-2039L": 25,
  "1560L-2031L": 25,
  "1560L-2451L": 25,
  "1560L-2521L": 25,
  "1560L-9734": 25,
  "1560L-7666": 25,
  "1560L-1049L": 25,
  "1560L-9922": 25,
  "1560L-4443": 25,
  "1560L-4683": 25,
  "1560L-4925": 25,
  "1560L-9773": 25,
  "1560L-9792": 25,
  "1560L-012L": 25,
  "1560L-1443L": 25,
  "2543L-1423L": 25,
  "2543L-4404": 25,
  "2543L-2013L": 25,
  "2543L-1208L": 25,
  "2543L-1991L": 25,
  "2543L-2038L": 25,
  "2543L-2039L": 25,
  "2543L-2031L": 25,
  "2543L-2451L": 25,
  "2543L-2521L": 25,
  "2543L-9734": 25,
  "2543L-7666": 25,
  "2543L-1049L": 25,
  "2543L-9922": 25,
  "2543L-4443": 25,
  "2543L-4683": 25,
  "2543L-4925": 25,
  "2543L-9773": 25,
  "2543L-9792": 25,
  "2543L-012L": 25,
  "2543L-1443L": 25,
  "2541L-1423L": 25,
  "2541L-4404": 25,
  "2541L-2013L": 25,
  "2541L-1208L": 25,
  "2541L-1991L": 25,
  "2541L-2038L": 25,
  "2541L-2039L": 25,
  "2541L-2031L": 25,
  "2541L-2451L": 25,
  "2541L-2521L": 25,
  "2541L-9734": 25,
  "2541L-7666": 25,
  "2541L-1049L": 25,
  "2541L-9922": 25,
  "2541L-4443": 25,
  "2541L-4683": 25,
  "2541L-4925": 25,
  "2541L-9773": 25,
  "2541L-9792": 25,
  "2541L-012L": 25,
  "2541L-1443L": 25,
  "1579L-1423L": 30,
  "1579L-4404": 30,
  "1579L-2013L": 30,
  "1579L-1208L": 30,
  "1579L-1991L": 30,
  "1579L-2038L": 30,
  "1579L-2039L": 30,
  "1579L-2031L": 30,
  "1579L-2451L": 30,
  "1579L-2521L": 30,
  "1579L-9734": 30,
  "1579L-7666": 30,
  "1579L-1049L": 30,
  "1579L-9922": 30,
  "1579L-4443": 30,
  "1579L-4683": 30,
  "1579L-4925": 30,
  "1579L-9773": 30,
  "1579L-9792": 30,
  "1579L-012L": 30,
  "1579L-1443L": 30,
  "1738L-1423L": 30,
  "1738L-4404": 30,
  "1738L-2013L": 30,
  "1738L-1208L": 30,
  "1738L-1991L": 30,
  "1738L-2038L": 30,
  "1738L-2039L": 30,
  "1738L-2031L": 30,
  "1738L-2451L": 30,
  "1738L-2521L": 30,
  "1738L-9734": 30,
  "1738L-7666": 30,
  "1738L-1049L": 30,
  "1738L-9922": 30,
  "1738L-4443": 30,
  "1738L-4683": 30,
  "1738L-4925": 30,
  "1738L-9773": 30,
  "1738L-9792": 30,
  "1738L-012L": 30,
  "1738L-1443L": 30,
  "1743L-1423L": 30,
  "1743L-4404": 30,
  "1743L-2013L": 30,
  "1743L-1208L": 30,
  "1743L-1991L": 30,
  "1743L-2038L": 30,
  "1743L-2039L": 30,
  "1743L-2031L": 30,
  "1743L-2451L": 30,
  "1743L-2521L": 30,
  "1743L-9734": 30,
  "1743L-7666": 30,
  "1743L-1049L": 30,
  "1743L-9922": 30,
  "1743L-4443": 30,
  "1743L-4683": 30,
  "1743L-4925": 30,
  "1743L-9773": 30,
  "1743L-9792": 30,
  "1743L-012L": 30,
  "1743L-1443L": 30,
  "2550L-1423L": 30,
  "2550L-4404": 30,
  "2550L-2013L": 30,
  "2550L-1208L": 30,
  "2550L-1991L": 30,
  "2550L-2038L": 30,
  "2550L-2039L": 30,
  "2550L-2031L": 30,
  "2550L-2451L": 30,
  "2550L-2521L": 30,
  "2550L-9734": 30,
  "2550L-7666": 30,
  "2550L-1049L": 30,
  "2550L-9922": 30,
  "2550L-4443": 30,
  "2550L-4683": 30,
  "2550L-4925": 30,
  "2550L-9773": 30,
  "2550L-9792": 30,
  "2550L-012L": 30,
  "2550L-1443L": 30,
  "2551L-1423L": 30,
  "2551L-4404": 30,
  "2551L-2013L": 30,
  "2551L-1208L": 30,
  "2551L-1991L": 30,
  "2551L-2038L": 30,
  "2551L-2039L": 30,
  "2551L-2031L": 30,
  "2551L-2451L": 30,
  "2551L-2521L": 30,
  "2551L-9734": 30,
  "2551L-7666": 30,
  "2551L-1049L": 30,
  "2551L-9922": 30,
  "2551L-4443": 30,
  "2551L-4683": 30,
  "2551L-4925": 30,
  "2551L-9773": 30,
  "2551L-9792": 30,
  "2551L-012L": 30,
  "2551L-1443L": 30,
  "2426L-2426L": 25,
  "2532L-2532L": 25,
  "2533L-2533L": 25,
  "2534L-2534L": 25,
  "2366L-2366L": 25,
  "2535L-2535L": 25,
  "2536L-2536L": 25,
  "1551L-1551L": 25,
  "2537L-2537L": 25,
  "2538L-2538L": 25,
  "2539L-2539L": 25,
  "2540L-2540L": 25,
  "2542L-2542L": 25,
  "1560L-1560L": 25,
  "2543L-2543L": 25,
  "2541L-2541L": 25,
  "1579L-2426L": 70,
  "1579L-2532L": 70,
  "1579L-2533L": 70,
  "1579L-2534L": 70,
  "1579L-2366L": 70,
  "1579L-2535L": 70,
  "1579L-2536L": 70,
  "1579L-1551L": 70,
  "1579L-2537L": 70,
  "1579L-2538L": 70,
  "1579L-2539L": 70,
  "1579L-2540L": 70,
  "1579L-2542L": 70,
  "1579L-1560L": 70,
  "1579L-2543L": 70,
  "1579L-2541L": 70,
  "1738L-2426L": 70,
  "1738L-2532L": 70,
  "1738L-2533L": 70,
  "1738L-2534L": 70,
  "1738L-2366L": 70,
  "1738L-2535L": 70,
  "1738L-2536L": 70,
  "1738L-1551L": 70,
  "1738L-2537L": 70,
  "1738L-2538L": 70,
  "1738L-2539L": 70,
  "1738L-2540L": 70,
  "1738L-2542L": 70,
  "1738L-1560L": 70,
  "1738L-2543L": 70,
  "1738L-2541L": 70,
  "1743L-2426L": 70,
  "1743L-2532L": 70,
  "1743L-2533L": 70,
  "1743L-2534L": 70,
  "1743L-2366L": 70,
  "1743L-2535L": 70,
  "1743L-2536L": 70,
  "1743L-1551L": 70,
  "1743L-2537L": 70,
  "1743L-2538L": 70,
  "1743L-2539L": 70,
  "1743L-2540L": 70,
  "1743L-2542L": 70,
  "1743L-1560L": 70,
  "1743L-2543L": 70,
  "1743L-2541L": 70,
  "2550L-2426L": 70,
  "2550L-2532L": 70,
  "2550L-2533L": 70,
  "2550L-2534L": 70,
  "2550L-2366L": 70,
  "2550L-2535L": 70,
  "2550L-2536L": 70,
  "2550L-1551L": 70,
  "2550L-2537L": 70,
  "2550L-2538L": 70,
  "2550L-2539L": 70,
  "2550L-2540L": 70,
  "2550L-2542L": 70,
  "2550L-1560L": 70,
  "2550L-2543L": 70,
  "2550L-2541L": 70,
  "2551L-2426L": 70,
  "2551L-2532L": 70,
  "2551L-2533L": 70,
  "2551L-2534L": 70,
  "2551L-2366L": 70,
  "2551L-2535L": 70,
  "2551L-2536L": 70,
  "2551L-1551L": 70,
  "2551L-2537L": 70,
  "2551L-2538L": 70,
  "2551L-2539L": 70,
  "2551L-2540L": 70,
  "2551L-2542L": 70,
  "2551L-1560L": 70,
  "2551L-2543L": 70,
  "2551L-2541L": 70,
};

// General variables to insert inside quote
const floorsVLS = [
  {
    name: "1° Piano",
    rightText: "+4%",
    code: "1",
    percentagePlus: 0.04,
  },
  {
    name: "2° Piano",
    rightText: "+5%",
    code: "2",
    percentagePlus: 0.05,
  },
  {
    name: "3° Piano",
    rightText: "+6%",
    code: "3",
    percentagePlus: 0.06,
  },
  {
    name: "4° Piano",
    rightText: "+7%",
    code: "4",
    percentagePlus: 0.07,
  },
  {
    name: "5° Piano",
    rightText: "+8%",
    code: "5",
    percentagePlus: 0.08,
  },
];

const specialFormatsVLS = [
  { name: "Nessuno", code: "nessuno" },
  { name: "Fuori squadro", code: "fuori-squadro" },
];

const colorsHandleVLS = [
  { name: "Bianco", code: "bianco" },
  { name: "Silver", code: "silver" },
  { name: "Oro Satinato", code: "oro-satinato" },
  { name: "Bronzo", code: "Bronzo" },
];

const colorsDumpsterVLS = [
  { name: "Bianco", code: "bianco" },
  { name: "Cream", code: "crema" },
  { name: "Frassino", code: "frassino" },
  { name: "Rovere gold", code: "rovere-gold" },
  { name: "Noce", code: "noce" },
];

const infixVLS = [
  {
    name: "Versione infisso con 5 camere e 2 guarnizioni",
    code: "5-camere-2-guarnizioni",
    rightText: "INCLUSO",
  },
  {
    name: "Versione infisso con 7 camere e 3 guarnizioni",
    code: "7-camere-3-guarnizioni",
    rightText: "+8%",
    percentagePlus: 0.08,
  },
];

const typesOfGlassVLS = [
  {
    name: "Doppio",
    code: "doppio",
    rightText: "INCLUSO",
  },
  {
    name: "Triplo",
    code: "triplo",
    rightText: "+10%",
    percentagePlus: 0.1,
  },
];

const glassesVLS = [
  {
    name: "Vetro Trasparente",
    code: "vetro-trasparente",
    rightText: "INCLUSO",
  },
  {
    name: "Vetro Satinato",
    code: "vetro-satinato",
    rightText: "+10%",
    percentagePlus: 0.1,
  },
];

const profilesVLS = [
  {
    name: "Profilo Squadrato",
    code: "profilo-squadrato",
    rightText: "INCLUSO",
  },
  {
    name: "Profilo Arrotondato",
    code: "profilo-arrotondato",
    rightText: "+8%",
    percentagePlus: 0.08,
  },
  {
    name: "Profilo Slim",
    code: "profilo-slim",
    rightText: "+15%",
    percentagePlus: 0.15,
  },
];

const climatZonesVLS = [
  {
    name: "A",
    code: "A",
  },
  {
    name: "B",
    code: "B",
  },
  {
    name: "C",
    code: "C",
  },
  {
    name: "D",
    code: "D",
  },
  {
    name: "E",
    code: "E",
  },
  {
    name: "F",
    code: "F",
  },
];

// All prices per accessory
const pricePerWidthDumpster = 400;
const pricePerSizeShutter = 400;
const pricePerSizeShutter2 = 400;
const pricePerSizeMosNet = 400;

// All data of the product to insert inside quote
const productState = {
  code: "",
  name: "",
  position: "",
  width: "",
  height: "",
  product: { name: "", code: "" },
  specialFormat: specialFormatsVLS[0],
  colorHandle: colorsHandleVLS[0],
  widthDumpster: "",
  colorDumpster: colorsDumpsterVLS[0],
  widthShutter: "",
  heightShutter: "",
  widthShutter2: "",
  heightShutter2: "",
  widthMosNet: "",
  heightMosNet: "",
  infix: infixVLS[0],
  typeOfGlass: typesOfGlassVLS[0],
  glass: glassesVLS[0],
  profile: profilesVLS[0],
  colorIn: {
    name: "Bianco pasta",
    code: "0000",
  },
  colorOut: {
    name: "Bianco pasta",
    code: "0000",
  },
  climateZone: climatZonesVLS[0],
};

export {
  listOfProductsVLS,
  colorsQuoteVLS,
  floorsVLS,
  specialFormatsVLS,
  percentagePlusPerColorsVLS,
  colorsHandleVLS,
  colorsDumpsterVLS,
  infixVLS,
  typesOfGlassVLS,
  glassesVLS,
  profilesVLS,
  climatZonesVLS,
  productState,
  pricePerWidthDumpster,
  pricePerSizeShutter,
  pricePerSizeShutter2,
  pricePerSizeMosNet,
};
