import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import { formatAddress, formatPrice } from "../StringHelpers";

export const generateQuotePDF = async ({ state, clientData, companyState }) => {
  const doc = new jsPDF();

  const margin = 20;
  // Add company logo
  if (companyState.logoUrl) {
    try {
      // Convert logo URL to base64
      const response = await fetch(companyState.logoUrl);
      const blob = await response.blob();
      const reader = new FileReader();

      await new Promise((resolve) => {
        reader.onload = () => {
          doc.addImage(
            reader.result,
            "PNG",
            margin,
            margin,
            40,
            20,
            "",
            "FAST"
          );
          resolve();
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error loading logo:", error);
    }
  }

  // Quote number and date
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  const _id = companyState.companyName + "#" + state.quoteId;
  doc.text(_id, 150, 20);
  doc.setFont("helvetica", "normal");
  doc.text(format(new Date(), "dd/MM/yyyy"), 170, 25);

  // Client information section with styled text
  const startY = 60;

  const renderStyledField = (key, value, x, y) => {
    doc.setFont("helvetica", "bold");
    doc.text(key + ": ", x, y);
    const keyWidth = doc.getTextWidth(key + ": ");
    doc.setFont("helvetica", "normal");
    doc.text(value, x + keyWidth, y);
  };

  renderStyledField("Cliente", clientData.name, 15, startY);
  if (clientData.email !== null)
    renderStyledField("Email", clientData.email, 15, startY + 5);
  if (clientData.phoneNumber !== null)
    renderStyledField("Cellulare", clientData.phoneNumber, 15, startY + 10);

  if (clientData.address !== "") {
    renderStyledField(
      "Indirizzo",
      formatAddress(clientData.address, clientData.city, clientData.cap),
      15,
      startY + 15
    );
  }

  renderStyledField(
    "Installazione a",
    state.installationOnFloor.name,
    15,
    startY + (clientData.address !== "" ? 20 : 15)
  );

  // Prices section
  const priceY = startY + 37;
  renderStyledField("Prezzo", formatPrice(state.calculatedPrice), 15, priceY);
  renderStyledField(
    "Prezzo ordine da pagare",
    formatPrice(state.showedPrice),
    15,
    priceY + 5
  );

  // Windows list title
  doc.setFontSize(14);
  doc.setFont("helvetica", "bold");
  doc.text("Lista finestre:", 15, priceY + 35);

  // Windows table (unchanged)
  doc.autoTable({
    startY: priceY + 40,
    head: [
      [
        "Finestra",
        "Larghezza/Altezza",
        "Tipo vetro",
        "Modello vetro",
        "Colore esterno",
        "Colore interno",
        "Locale",
      ],
    ],
    body: state.productsList.map((w) => [
      w.product.name,
      w.width + "x" + w.height + " (mm)",
      w.typeOfGlass.name,
      w.glass.name,
      w.colorIn.name,
      w.colorOut.name,
      w.position,
    ]),
    theme: "grid",
    styles: {
      fontSize: 10,
      cellPadding: 5,
    },
    headStyles: {
      fillColor: [0, 0, 0],
      textColor: [255, 255, 255],
    },
  });

  doc.save(`preventivo-${_id}-${Date.now()}.pdf`);
};
