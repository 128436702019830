import React from "react";
import { IoDocuments } from "react-icons/io5";
import { TbInvoice } from "react-icons/tb";
import { LuEuro } from "react-icons/lu";
import RevenueGraph from "./RevenueGraph";
import "style/pages-style/dashboard.css";
import useInvoicesStore from "providers/useInvoicesStore";

export default function Dashboard() {
  const { invoicesState } = useInvoicesStore();

  const DataBox = ({ value, title, icon }) => {
    return (
      <div className="box-with-border data">
        <div className="box-data-icon">{icon}</div>
        <br />
        <strong
          style={{
            fontWeight: "500",
            fontSize: "22px",
          }}
        >
          {value}
        </strong>
        <div style={{ color: "grey", fontSize: "14px" }}>{title}</div>
      </div>
    );
  };

  return (
    <div className="base-view" style={{ gap: "16px", overflowY: "scroll" }}>
      <div>
        <strong className="title-home-view">Dashboard</strong>
        <div className="divider-home-view" />
      </div>

      {/* Data grid */}
      <div style={{ display: "flex", gap: "16px" }}>
        <DataBox
          title={"Preventivi totali"}
          value={"12"}
          icon={<IoDocuments size={"24px"} />}
        />
        <DataBox
          title={"Fatture totali"}
          value={invoicesState.length}
          icon={<TbInvoice size={"24px"} />}
        />
        <DataBox
          title={"Fatture da pagare"}
          value={invoicesState.filter((e) => e.payedOn === null).length}
          icon={<TbInvoice size={"24px"} />}
        />
        <DataBox
          title={"Fatturato totale di quest'anno"}
          value={"12,000€"}
          icon={<LuEuro size={"24px"} />}
        />
      </div>

      {/* Data graph */}
      <RevenueGraph />
    </div>
  );
}
