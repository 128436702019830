import { PagesInHome } from "global/Pages";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "style/components-style/sidebar.css";
import useCompanyStore from "providers/useCompanyStore";

export default function Sidebar() {
  const loc = useLocation();
  const isActive = (path) => loc.pathname.includes(path);
  const { companyState } = useCompanyStore();

  return (
    <div className="main-sidebar">
      {/* Pages */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={companyState.logoUrl}
              alt="logo"
              style={{
                width: "200px",
                height: "120px",
                objectFit: "contain",
                marginLeft: "var(--mv-2)",
                margin: "40px 0px",
              }}
            />
          </div>

          {PagesInHome.map((e) => (
            <Link
              key={e.path}
              to={e.path}
              className="no-link"
              style={{ margin: "var(--mv-1)" }}
            >
              <div
                className={
                  "sidebar-button" + (isActive(e.path) ? " active" : "")
                }
              >
                <div className="sidebar-icon">
                  {isActive(e.path) ? e.iconActive : e.icon}
                </div>
                <div>{e.name}</div>
              </div>
            </Link>
          ))}
        </div>

        {/* <div className="end-sidebar">
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "var(--mv-2)",
            }}
          >
            <div style={{ marginRight: "3px" }}>Made by</div>
            <a href="https://www.quotapp.it" target="_blank" rel="noreferrer">
              Quota
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
}
