import { supabase } from "services/supabaseConfig";
import { create } from "zustand";

const _initialState = {
  id: null,
  email: null,
  name: null,
  isAdmin: false,
  createAt: null,
};

const useUserStore = create((set, get) => ({
  userState: _initialState,
  clearUserState: () => {
    set({ userState: _initialState });
  },
  updateUserData: (newState) => {
    set({
      userState: {
        ...get().userState,
        ...newState,
      },
    });
  },
  getInitials: () => {
    const s = get().userState;

    if (s.name === null) return "";

    const separateNames = s.name.split(" ");
    var initials = "";

    for (var i = 0; i < separateNames.length; i++) {
      initials += separateNames[i][0];

      if (i === 2) break;
    }

    return initials;
  },
  initUserData: async (userId) => {
    const s = get().userState;

    if (s.id !== null && s.id !== undefined) return;

    const response = await supabase
      .from("Users")
      .select("*")
      .eq("id", userId)
      .single();

    if (response.error !== null) return;
    set({
      userState: {
        ...get().userState,
        ...response.data,
      },
    });
  },
}));

export default useUserStore;
