import React from "react";

export default function CheckboxRow({ isSelected, onChange, title }) {
  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <input
        type="checkbox"
        checked={isSelected}
        style={{ cursor: "pointer" }}
        onChange={() => onChange(!isSelected)}
      />
      <div>{title}</div>
    </div>
  );
}
