import { InputForm } from "components/forms/InputForm";
import SelectForm from "components/forms/SelectForm";
import { floorsVLS, productState } from "global/ProductsVariables";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import React, { useEffect, useState } from "react";
import useClientsStore from "providers/useClientsStore";
import ClientSidebar from "pages/home/Clients/ClientSidebar";
import _ from "lodash";

export default function QuoteBase() {
  const { state, setState, getStatePerStage } = useCreateQuoteStore();
  const [data, setData] = useState(getStatePerStage(0));
  const { clientsState, clientFromId } = useClientsStore();
  const [addClientModalVisible, setAddClientModalVisible] = useState(false);

  useEffect(() => {
    setData(getStatePerStage(0));
  }, [getStatePerStage]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
        maxWidth: "400px",
      }}
    >
      <SelectForm
        label={"Cliente"}
        secondaryButton={
          <div
            className="add-label-button"
            onClick={() => setAddClientModalVisible(true)}
          >
            Aggiungi
          </div>
        }
        options={clientsState.map((e) => ({
          name: e.name,
          code: e.id,
          rightText: e.pIva,
        }))}
        value={{
          name: clientFromId(data.toClientId)?.name,
          code: data.toClientId,
        }}
        onChange={(i) =>
          setData((prev) => ({
            ...prev,
            toClientId: clientsState[i].id,
          }))
        }
      />

      {data.toClientId !== undefined && (
        <div style={{ display: "flex", gap: "8px" }}>
          <InputForm
            label={"Numero finestre"}
            placeholder={0}
            value={data.numberOfWindows}
            type={"number"}
            onChange={(e) => {
              setData((prev) => ({
                ...prev,
                numberOfWindows: e,
              }));
            }}
          />
          <SelectForm
            value={data.installationOnFloor}
            label={"Installazione al piano"}
            onChange={(i) =>
              setData((prev) => ({
                ...prev,
                installationOnFloor: floorsVLS[i],
              }))
            }
            options={floorsVLS}
            width={"200px"}
          />
        </div>
      )}

      <div>
        <button
          className="q-secondary-button black"
          disabled={
            _.isEqual(getStatePerStage(0), data) ||
            data.toClientId === undefined ||
            data.numberOfWindows === ""
          }
          onClick={() => {
            if (state.productsList.length !== Number(data.numberOfWindows))
              setState({
                ...data,
                productsList: [
                  ...Array(Number(data.numberOfWindows)).keys(),
                ].map((i) => ({
                  ...productState,
                  name: "Finestra " + (i + 1),
                  code: String(i + 1),
                })),
              });
            else
              setState({
                ...data,
              });
          }}
        >
          Salva
        </button>
      </div>

      {/* Overlay */}
      <div
        className={"overlay" + (addClientModalVisible ? "" : " hide")}
        onClick={() => {
          setAddClientModalVisible(false);
        }}
      />
      {/* Edit profile */}
      <ClientSidebar
        open={addClientModalVisible}
        onClose={() => setAddClientModalVisible(false)}
      />
    </div>
  );
}
