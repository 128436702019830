import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { supabase } from "services/supabaseConfig";
import ModalUser from "./ModalUser";
import { useSearching } from "helpers/uses/useSearching";
import toast from "react-hot-toast";

export default function ListUsers() {
  const [search, setSearch] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const [showModalUser, setShowModalUser] = useState(false);
  const [userToEdit, setUserToEdit] = useState();

  const getAllUsers = async () => {
    const response = await supabase.from("Users").select("*");
    if (response.error !== null) return;

    setAllUsers(response.data);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div className="base-view">
      <strong className="title-home-view">Clienti</strong>
      <div className="divider-home-view mb-big" />

      {/* Actions */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        {/* Search */}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <input
            className="q-input-form"
            style={{ flex: 1, borderRadius: "12px 0px 0px 12px" }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Cerca per codice, nome o email"
          />

          <div
            style={{
              backgroundColor: "grey",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              borderRadius: "0px 12px 12px 0px",
              width: "46px",
              cursor: "pointer",
            }}
          >
            <IoSearch />
          </div>
        </div>

        {/* Actions */}
        <div style={{ display: "flex" }}>
          <button
            onClick={() => {
              setShowModalUser(true);
            }}
            className="q-secondary-button black"
          >
            <IoIosAddCircle size={"20px"} />
            <div>Aggiungi utente</div>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="home-view">
        <div className="quota-table-container">
          <table className="quota-table-table">
            <thead>
              <tr className="quota-table-header-row">
                <th className="quota-table-header-cell">Cod.</th>
                <th className="quota-table-header-cell">Nominativo</th>
                <th className="quota-table-header-cell">Email</th>
                <th className="quota-table-header-cell">Ruolo</th>
                <th className="quota-table-header-cell">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {useSearching({
                list: allUsers,
                searchValue: search,
                searchParams: ["email", "name"],
              }).map((e, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0
                      ? "quota-table-alternate-row"
                      : "quota-table-row"
                  }
                >
                  <td className="quota-table-cell">{e.id.split("-")[0]}</td>
                  <td className="quota-table-cell">{e.name}</td>
                  <td className="quota-table-cell">{e.email}</td>
                  <td className="quota-table-cell">
                    {e.isAdmin ? "Admin" : "Utente"}
                  </td>
                  <td className="quota-table-cell w-actions">
                    <div style={{ display: "flex", gap: "6px" }}>
                      <button
                        className="q-secondary-button little-button blue"
                        onClick={() => {
                          setShowModalUser(true);
                          setUserToEdit(e);
                        }}
                      >
                        Modifica
                      </button>
                      <button
                        className="q-secondary-button little-button red"
                        onClick={async () => {
                          await supabase.from("Users").delete().eq("id", e.id);
                          toast.success("Utente eliminato");
                          getAllUsers();
                        }}
                      >
                        Elimina
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Overlay */}
      <div
        className={"overlay" + (showModalUser ? "" : " hide")}
        onClick={() => {
          setShowModalUser(false);
          setUserToEdit();
        }}
      />

      {/* Edit user */}
      <ModalUser
        userToEdit={userToEdit}
        visible={showModalUser}
        update={getAllUsers}
        onClose={() => {
          setShowModalUser(false);
          setUserToEdit();
        }}
      />
    </div>
  );
}
