// list: list of values
// value: value searched

import useClientsStore from "providers/useClientsStore";

// searchParams: the parameters to search for (email, name...)
export const useSearching = ({
  list,
  searchValue,
  useSearchForClient,
  searchParams,
}) => {
  const { clientFromId } = useClientsStore();

  return list.filter((item) =>
    searchParams.some(
      (param) =>
        item[param]
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        (useSearchForClient &&
          clientFromId(item["toClientId"])
            .name.toLowerCase()
            .includes(searchValue.toLowerCase()))
    )
  );
};
