import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphBase = ({ data, keys }) => {
  // Configure default Y-axis settings
  const yAxisConfig =
    keys.length === 0
      ? {
          domain: [0, 2000],
          ticks: [0, 200, 400, 700, 1000, 1300, 1600, 2000],
          allowDataOverflow: true,
        }
      : {};

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis {...yAxisConfig} tickFormatter={(value) => `${value}`} />
          <Tooltip />
          <Legend />
          {keys.map((e) => (
            <Line
              key={e.code}
              type="monotone"
              dataKey={e.code}
              name={e.name}
              stroke={e.color}
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraphBase;
