import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import ClientSidebar from "./ClientSidebar";
import { IoIosAddCircle } from "react-icons/io";
import { useSearching } from "helpers/uses/useSearching";
import useClientsStore from "providers/useClientsStore";
import toast from "react-hot-toast";

export default function ListClients() {
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(undefined);

  const { clientsState, initClients, removeClient } = useClientsStore();

  useEffect(() => {
    initClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="base-view">
      <strong className="title-home-view">Clienti</strong>
      <div className="divider-home-view mb-big" />

      {/* Actions */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        {/* Search */}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <input
            className="q-input-form"
            style={{ flex: 1, borderRadius: "12px 0px 0px 12px" }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Cerca per codice, nome o p.iva"
          />

          <div
            style={{
              backgroundColor: "grey",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              borderRadius: "0px 12px 12px 0px",
              width: "46px",
              cursor: "pointer",
            }}
          >
            <IoSearch />
          </div>
        </div>

        {/* Actions */}
        <div style={{ display: "flex" }}>
          {/* <button
            onClick={() => setShowUploadFile(true)}
            className="q-secondary-button green"
            style={{ marginRight: "var(--mv-1)" }}
          >
            <IoCloudUploadOutline size={"20px"} />
            <div>Carica clienti</div>
          </button> */}

          <button
            onClick={() => {
              setShowModal(true);
              setClientToEdit(undefined);
            }}
            className="q-secondary-button black"
          >
            <IoIosAddCircle size={"20px"} />
            <div>Aggiungi cliente</div>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="home-view">
        <div className="quota-table-container">
          <table className="quota-table-table">
            <thead>
              <tr className="quota-table-header-row">
                <th className="quota-table-header-cell">Cod.</th>
                <th className="quota-table-header-cell">Nominativo</th>
                <th className="quota-table-header-cell">Città</th>
                <th className="quota-table-header-cell">Provincia</th>
                <th className="quota-table-header-cell">P.IVA</th>
                <th className="quota-table-header-cell">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {useSearching({
                list: clientsState,
                searchValue: search,
                searchParams: ["id", "name", "pIva"],
              }).map((e, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0
                      ? "quota-table-alternate-row"
                      : "quota-table-row"
                  }
                >
                  <td className="quota-table-cell">{e.id}</td>
                  <td className="quota-table-cell">{e.name}</td>
                  <td className="quota-table-cell">{e.city}</td>
                  <td className="quota-table-cell">{e.prov}</td>
                  <td className="quota-table-cell">{e.pIva}</td>
                  <td className="quota-table-cell w-actions">
                    <div style={{ display: "flex", gap: "6px" }}>
                      <button
                        onClick={() => {
                          setShowModal(true);
                          setClientToEdit(e);
                        }}
                        className="q-secondary-button little-button blue"
                      >
                        Modifica
                      </button>
                      <button
                        onClick={() => {
                          toast.success("Cliente eliminato");
                          removeClient(e.id);
                        }}
                        className="q-secondary-button little-button red"
                      >
                        Elimina
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {clientsState.length === 0 && (
        <div
          style={{
            textAlign: "center",
            color: "#17171760",
            marginTop: "12px",
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          Nessun cliente al momento
        </div>
      )}
      {/* <PaginationsIndexes /> */}

      {/* Overlay */}
      <div
        className={"overlay" + (showModal ? "" : " hide")}
        onClick={() => {
          setShowModal(false);
          setClientToEdit(undefined);
        }}
      />

      {/* Upload Excel with Clients */}
      {/* <UploadFileData
        open={showUploadFile}
        onClose={() => setShowUploadFile(false)}
        onUploadedFile={(e) => {}}
      /> */}

      {/* Edit profile */}
      <ClientSidebar
        clientToEdit={clientToEdit}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setClientToEdit(undefined);
        }}
      />
    </div>
  );
}
