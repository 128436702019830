import React, { useState } from "react";
import LogoHeader from "assets/logos/logo_header.png";
import Background from "assets/images/background-auth.png";
import { InputForm } from "components/forms/InputForm";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { PagesInHome } from "global/Pages";
import { supabase } from "services/supabaseConfig";
import useUserStore from "providers/useUserStore";
import { useLocalStorage } from "helpers/uses/useLocalStorage";
import useCompanyStore from "providers/useCompanyStore";
import useClientsStore from "providers/useClientsStore";
import useInvoicesStore from "providers/useInvoicesStore";

export default function LoginPage() {
  const nav = useNavigate();
  const { saveLocalValue } = useLocalStorage();
  const { initUserData } = useUserStore();
  const { initCompanyData } = useCompanyStore();
  const { initClients } = useClientsStore();
  const { initInvoices } = useInvoicesStore();

  const [authForms, setAuthForms] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const isButtonEnable =
    authForms.email.length > 5 && authForms.password.length >= 8 && !loading;

  const Login = async () => {
    setLoading(true);

    try {
      // Sign user with credential
      const { data, error } = await supabase.auth.signInWithPassword({
        email: authForms.email,
        password: authForms.password,
      });

      if (error) throw error;

      // Get user from database from ID
      await initUserData(data.user.id);
      await initCompanyData();
      await initClients();
      await initInvoices();

      // Save local value
      saveLocalValue("userId", data.user.id);

      nav("/" + PagesInHome[0].path);
    } catch (_) {
      toast.error("Credenziali non valide");
    }

    setLoading(false);
  };

  return (
    <div style={{ position: "absolute", width: "100vw", height: "100vh" }}>
      {/* Forms */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "#FeFeFe",
            borderRadius: "1.6rem",
            padding: "24px",
            height: "330px",
            width: "550px",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid grey",
            boxShadow: "0px 6px 20px 2px #F7F7F744",
            margin: "0px 24px",
          }}
        >
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src={LogoHeader}
                style={{ width: "260px", objectFit: "contain" }}
                alt="logo"
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  marginTop: "40px",
                  flexDirection: "column",
                }}
              >
                <InputForm
                  label={"Email"}
                  placeholder={"email@gmail.com"}
                  type="email"
                  value={authForms.email}
                  onChange={(e) =>
                    setAuthForms((prev) => ({ ...prev, email: e }))
                  }
                />
                <InputForm
                  label={"Password"}
                  placeholder={"password"}
                  type="password"
                  value={authForms.password}
                  onChange={(e) =>
                    setAuthForms((prev) => ({ ...prev, password: e }))
                  }
                />
              </div>
            </div>

            <button
              className="q-secondary-button blue"
              disabled={!isButtonEnable}
              onClick={Login}
              style={{ width: "100%" }}
              type="submit"
            >
              Accedi
            </button>
          </form>
        </div>
      </div>

      <img
        src={Background}
        alt="background"
        style={{
          position: "absolute",
          top: 0,
          width: "100vw",
          height: "100vh",
          objectFit: "cover",
          zIndex: -1,
        }}
      />
    </div>
  );
}
