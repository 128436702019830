import { format } from "date-fns";
import { formatPrice } from "helpers/StringHelpers";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";
import React from "react";

export default function InvoicePDFView({ invoiceData }) {
  const { companyState } = useCompanyStore();
  const { clientFromId } = useClientsStore();

  const _client = clientFromId(invoiceData.toClientId);

  return (
    <div
      id="invoice-pdf"
      className="pdf-view-layout"
      style={{
        height: "80vh",
        flexDirection: "column",
        padding: "32px",
      }}
    >
      {/* Image and text */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img
          src={companyState.logoUrl}
          height={"120px"}
          alt="logo"
          style={{ objectFit: "contain" }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <strong style={{ fontWeight: "600" }}>
            Fattura #{invoiceData.invoiceId}
          </strong>
          <div>{format(invoiceData.createdAt, "dd/MM/yyyy")}</div>
        </div>
      </div>

      {/* User data and Client data */}
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginTop: "100px",
        }}
      >
        <div style={{ maxWidth: "300px" }}>
          <strong style={{ fontWeight: "400", color: "#444444" }}>
            Per conto di:
          </strong>
          <div style={{ fontSize: "22px" }}>{companyState.companyName}</div>
          <div>{companyState.address}</div>
          <div>
            {companyState.city} ({companyState.prov}) {companyState.cap}
          </div>
          <div>P.IVA: {companyState.pIva}</div>
        </div>
        <div style={{ maxWidth: "300px", textAlign: "end" }}>
          <strong style={{ fontWeight: "400", color: "#444444" }}>
            Destinatario:
          </strong>
          {invoiceData.toClientId === null ? (
            <div>-</div>
          ) : _client === null ? (
            <div>-</div>
          ) : (
            <>
              <div style={{ fontSize: "22px" }}>{_client.name}</div>
              <div>{_client.address}</div>
              <div>
                {_client.city}{" "}
                {_client.prov !== undefined && "(" + _client.prov + ")"}{" "}
                {_client.cap}
              </div>
              <div>P.IVA: {_client.pIva}</div>
            </>
          )}
        </div>
      </div>

      {/* Works */}
      <div
        className="quota-table-container br-secondary"
        style={{
          border: "1px solid var(--light-grey)",
          borderRadius: "0px",
          marginTop: "32px",
        }}
      >
        <table className="quota-table-table secondary-table br-secondary">
          <thead>
            <tr
              className="quota-table-header-row"
              style={{
                backgroundColor: "transparent",
                color: "black",
                borderBottom: "1px solid var(--light-grey)",
              }}
            >
              <th
                className="quota-table-header-cell table-header-cell-no-padding"
                style={{
                  borderRight: "1px solid var(--light-grey)",
                }}
              >
                Descrizione
              </th>
              <th
                className="quota-table-header-cell table-header-cell-no-padding"
                style={{
                  textAlign: "end",
                }}
              >
                Importo
              </th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.works.map((e, i) => (
              <tr
                key={i}
                className={
                  i % 2 === 0 ? "quota-table-alternate-row" : "quota-table-row"
                }
              >
                <td className="quota-table-cell secondary">{e.description}</td>
                <td
                  className="quota-table-cell secondary"
                  style={{
                    borderRight: "none",
                    width: "100px",
                    textAlign: "end",
                  }}
                >
                  {formatPrice(e.price)}{" "}
                  {e.quantity !== "" && <>x{e.quantity}</>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: "6px", fontSize: "17px", textAlign: "end" }}>
        Prezzo totale:{" "}
        {formatPrice(
          invoiceData.works.reduce(
            (a, v) =>
              (a = a + v.price * (v.quantity === "" ? 1 : Number(v.quantity))),
            0
          )
        )}
      </div>

      {/* Note / Expire date */}
      <div style={{ flex: 1 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        {invoiceData.note !== "" && (
          <div>
            <div style={{ fontWeight: "500" }}>Note</div>
            <div style={{ fontSize: "14px" }}>{invoiceData.note}</div>
          </div>
        )}
        {invoiceData.expireAt !== null &&
          !isNaN(new Date(invoiceData.expireAt)) && (
            <div>
              <div style={{ fontWeight: "500" }}>Da saldare entro:</div>
              <div style={{ fontSize: "14px" }}>
                {format(invoiceData.expireAt, "dd/MM/yyyy")}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
