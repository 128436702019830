import React from "react";
import { FaArrowRightToBracket, FaShield, FaClock } from "react-icons/fa6";
import { IoSettingsSharp } from "react-icons/io5";
import { FiFileText } from "react-icons/fi";
import { FaCloud } from "react-icons/fa";

export default function LandingPage() {
  return (
    <div
      style={{
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      {/* Hero Section */}
      <header
        style={{
          background: "linear-gradient(to right, #2563eb, #1e40af)",
          color: "white",
        }}
      >
        <nav
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "1rem 1.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              GestionePro
            </div>
            <div
              style={{
                display: "flex",
                gap: "2rem",
              }}
            >
              <a
                href="#features"
                style={{ color: "white", textDecoration: "none" }}
              >
                Funzionalità
              </a>
              <a
                href="#pricing"
                style={{ color: "white", textDecoration: "none" }}
              >
                Prezzi
              </a>
              <a
                href="#contact"
                style={{ color: "white", textDecoration: "none" }}
              >
                Contatti
              </a>
            </div>
            <button
              style={{
                backgroundColor: "white",
                color: "#2563eb",
                padding: "0.5rem 1.5rem",
                borderRadius: "0.5rem",
                fontWeight: "500",
                border: "none",
                cursor: "pointer",
              }}
            >
              Prova Gratuita
            </button>
          </div>
        </nav>

        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "6rem 1.5rem",
          }}
        >
          <div style={{ maxWidth: "48rem" }}>
            <h1
              style={{
                fontSize: "3.5rem",
                fontWeight: "bold",
                marginBottom: "1.5rem",
              }}
            >
              Software Gestionale per Fatture e Preventivi
            </h1>
            <p
              style={{
                fontSize: "1.25rem",
                marginBottom: "2rem",
              }}
            >
              Gestisci la tua attività in modo efficiente con il nostro software
              completo per fatturazione e preventivi. Sicuro, personalizzabile e
              facile da usare.
            </p>
            <div style={{ display: "flex", gap: "1rem" }}>
              <button
                style={{
                  backgroundColor: "white",
                  color: "#2563eb",
                  padding: "0.75rem 2rem",
                  borderRadius: "0.5rem",
                  fontWeight: "500",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Inizia Ora
                <FaArrowRightToBracket style={{ marginLeft: "0.5rem" }} />
              </button>
              <button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  padding: "0.75rem 2rem",
                  borderRadius: "0.5rem",
                  fontWeight: "500",
                  border: "2px solid white",
                  cursor: "pointer",
                }}
              >
                Richiedi Demo
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section
        style={{
          padding: "5rem 0",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
        id="features"
      >
        <h2
          style={{
            fontSize: "2.5rem",
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "4rem",
            color: "#1f2937",
          }}
        >
          Perché Scegliere GestionePro?
        </h2>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "3rem",
            padding: "0 1.5rem",
          }}
        >
          <FeatureCard
            Icon={FaShield}
            title="Sicuro e Affidabile"
            description="I tuoi dati sono protetti con crittografia avanzata. Backup automatici giornalieri e conformità GDPR garantita."
          />
          <FeatureCard
            Icon={IoSettingsSharp}
            title="Completamente Personalizzabile"
            description="Adatta il software alle tue esigenze. Personalizza modelli, workflow e report secondo le necessità della tua azienda."
          />
          <FeatureCard
            Icon={FiFileText}
            title="Fatturazione Semplificata"
            description="Crea e gestisci fatture e preventivi in pochi click. Supporto completo per fatturazione elettronica."
          />
        </div>
      </section>

      {/* Benefits Section */}
      <section
        style={{
          backgroundColor: "#f9fafb",
          padding: "5rem 0",
        }}
      >
        <div
          style={{
            maxWidth: "1200px",
            margin: "0 auto",
            padding: "0 1.5rem",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "3rem",
            alignItems: "center",
          }}
        >
          <div>
            <h2
              style={{
                fontSize: "2rem",
                fontWeight: "bold",
                marginBottom: "1.5rem",
              }}
            >
              Vantaggi del Nostro Gestionale
            </h2>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <BenefitItem
                Icon={FaClock}
                title="Risparmio di Tempo"
                description="Automatizza le attività ripetitive e riduci gli errori manuali."
              />
              <BenefitItem
                Icon={FaCloud}
                title="Accessibile Ovunque"
                description="Accedi ai tuoi dati da qualsiasi dispositivo, in qualsiasi momento."
              />
            </div>
          </div>

          <div
            style={{
              backgroundColor: "white",
              padding: "2rem",
              borderRadius: "0.5rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "1.5rem",
                textAlign: "center",
              }}
            >
              Inizia la Prova Gratuita
            </h3>
            <form
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <input
                type="text"
                placeholder="Nome Azienda"
                style={{
                  width: "100%",
                  padding: "0.5rem 1rem",
                  border: "1px solid #e5e7eb",
                  borderRadius: "0.5rem",
                  outline: "none",
                }}
              />
              <input
                type="email"
                placeholder="Email"
                style={{
                  width: "100%",
                  padding: "0.5rem 1rem",
                  border: "1px solid #e5e7eb",
                  borderRadius: "0.5rem",
                  outline: "none",
                }}
              />
              <button
                style={{
                  width: "100%",
                  backgroundColor: "#2563eb",
                  color: "white",
                  padding: "0.75rem",
                  borderRadius: "0.5rem",
                  fontWeight: "500",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Richiedi Accesso
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

// Helper Components
const FeatureCard = ({ Icon, title, description }) => (
  <div style={{ textAlign: "center" }}>
    <div
      style={{
        backgroundColor: "#dbeafe",
        padding: "1rem",
        borderRadius: "9999px",
        width: "4rem",
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0 auto 1rem",
      }}
    >
      <Icon style={{ width: "2rem", height: "2rem", color: "#2563eb" }} />
    </div>
    <h3
      style={{
        fontSize: "1.25rem",
        fontWeight: "600",
        marginBottom: "1rem",
      }}
    >
      {title}
    </h3>
    <p style={{ color: "#4b5563" }}>{description}</p>
  </div>
);

const BenefitItem = ({ Icon, title, description }) => (
  <div style={{ display: "flex", gap: "0.75rem" }}>
    <Icon
      style={{
        width: "1.5rem",
        height: "1.5rem",
        color: "#2563eb",
        marginTop: "0.25rem",
      }}
    />
    <div>
      <h3 style={{ fontWeight: "600", marginBottom: "0.25rem" }}>{title}</h3>
      <p style={{ color: "#4b5563" }}>{description}</p>
    </div>
  </div>
);
