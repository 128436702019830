import { InputForm } from "components/forms/InputForm";
import { TextAreaForm } from "components/forms/TextAreaForm";
import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { v1 } from "uuid";

export default function AddPaymentInvoiceModal(props) {
  const _initState = {
    id: v1().split("-")[0],
    description: "",
    price: "",
    quantity: "",
  };
  const [dataForms, setDataForms] = useState(_initState);

  useEffect(() => {
    if (props.workToEdit !== undefined)
      setDataForms((prev) => ({ ...prev, ...props.workToEdit }));
    else setDataForms(() => ({ ..._initState }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.workToEdit]);

  if (!props.visible) {
    return <></>;
  }

  return (
    <div className="little-modal-center">
      {/* Title */}
      <div
        style={{
          display: "flex",
          margin: "24px 24px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <strong
          className="title-home-view"
          style={{ fontWeight: "500", fontSize: "24px" }}
        >
          Aggiungi prestazione
        </strong>
        <div className="close-icon" onClick={props.onClose}>
          <IoClose size={"32px"} color="#000000EE" />
        </div>
      </div>

      <div
        style={{
          margin: "0px 24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {/* Forms */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
          }}
        >
          <TextAreaForm
            label={"Descrizione"}
            required={true}
            placeholder={"..."}
            height="50px"
            value={dataForms.description}
            onChange={(e) =>
              setDataForms((prev) => ({ ...prev, description: e }))
            }
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <InputForm
              label={"Importo"}
              required={true}
              width={"100%"}
              value={dataForms.price}
              placeholder={"0.00€"}
              onChange={(e) => setDataForms((prev) => ({ ...prev, price: e }))}
            />
            <InputForm
              label={"Quantità"}
              type="number"
              value={dataForms.quantity}
              placeholder={"0"}
              onChange={(e) =>
                setDataForms((prev) => ({ ...prev, quantity: e }))
              }
            />
          </div>
        </div>

        {/* Buttons */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "32px",
            gap: "8px",
          }}
        >
          <button
            className="q-secondary-button black"
            onClick={() => {
              setDataForms(() => ({ ..._initState }));
              props.onClose();
            }}
          >
            Cancella
          </button>
          <button
            className="q-secondary-button blue"
            onClick={() => {
              props.onConfirm(dataForms);
              setDataForms(() => ({ ..._initState }));
              props.onClose();
            }}
            disabled={!(dataForms.description !== "" && dataForms.price !== "")}
          >
            Conferma
          </button>
        </div>
      </div>
    </div>
  );
}
