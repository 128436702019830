export const InputForm = ({
  label,
  value,
  placeholder,
  onChange,
  width,
  maxWidth,
  type = "text",
  disable = false,
  required = false,
  autoComplete,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: width,
        maxWidth: maxWidth,
      }}
    >
      <label htmlFor="name" className="q-label-form">
        <div>{label}</div>
        {required && <div style={{ color: "red" }}>*</div>}
      </label>
      <input
        className="q-input-form"
        value={value === null ? "" : value}
        type={type}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        disabled={disable}
        autoComplete={autoComplete}
      />
    </div>
  );
};
