import { listOfProductsVLS } from "global/ProductsVariables";
import React from "react";
import { IoClose } from "react-icons/io5";
import "style/components-style/products-modal.css";

export default function SelectProductModal(props) {
  const productsToShow = listOfProductsVLS.filter(
    (e) =>
      e.pricesPerMeasures[props.productHeight + "-" + props.productWidth] !==
      undefined
  );

  const ProductToChoose = ({ product }) => {
    return (
      <div
        className={
          "product-option" +
          (props.productCode === product.code ? " product-selected" : "")
        }
        onClick={() => {
          props.onSelect(product);
          props.onClose();
        }}
      >
        <img
          src={product.image}
          style={{
            flex: 1,
            width: "100%",
            height: "100px",
            objectFit: "contain",
          }}
          alt={"product-" + product.name}
        />
        <div>{product.name}</div>
      </div>
    );
  };

  return (
    <div className={"edit-view" + (props.open ? " open-x" : "")}>
      <div className="edit-view-content fit-height">
        <div
          className="close-icon"
          style={{ position: "absolute", top: 8, right: 12 }}
          onClick={props.onClose}
        >
          <IoClose size={"32px"} color="#000000EE" />
        </div>

        <strong style={{ fontWeight: "500", fontSize: "20px" }}>
          Seleziona finestra:
        </strong>

        {/* Grid view products */}
        <div className="grid-view-product-modal">
          {productsToShow.map((e, i) => (
            <ProductToChoose key={i} product={e} />
          ))}
        </div>
      </div>
    </div>
  );
}
