export const getCompanyData = async (pIva) => {
  try {
    const response = await fetch(
      "https://quota-backend.onrender.com/api/vat/" + pIva,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    const data = await response.json();

    if (response.ok) {
      const _d = data.data;
      const _address = _d.address.substring(0, _d.address.indexOf("\n") - 1);
      const _addressData = _d.address
        .substring(_d.address.indexOf("\n") + 1)
        .replace("\n", "")
        .split(" ");

      return {
        isValid: _d.isValid,
        companyName: _d.name,
        address: _address,
        cap: _addressData[0],
        city: [
          ..._addressData.filter(
            (e, i) => i > 0 && i < _addressData.length - 1
          ),
        ].join(" "),
        prov: _addressData[_addressData.length - 1],
        pIva: pIva,
      };
    }

    return { isValid: false };
  } catch (_) {}

  return { isValid: false };
};
