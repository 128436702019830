import { generateQuotePDF } from "helpers/pdf/GenerateQuotePDF";
import { formatDateInEU, formatPrice } from "helpers/StringHelpers";
import { useSearching } from "helpers/uses/useSearching";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";
import useCreateQuoteStore from "providers/useCreateQuoteStore";
import useQuotesStore from "providers/useQuotesStore";
import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function ListQuotes() {
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const resetQuoteData = useCreateQuoteStore((v) => v.reset);

  const { quotesState, initQuotes, removeQuote } = useQuotesStore();
  const { clientFromId } = useClientsStore();
  const { companyState } = useCompanyStore();

  useEffect(() => {
    initQuotes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="base-view">
      <strong className="title-home-view">Preventivi</strong>
      <div className="divider-home-view mb-big" />

      {/* Actions */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        {/* Search */}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <input
            className="q-input-form"
            style={{ flex: 1, borderRadius: "12px 0px 0px 12px" }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Cerca per codice o nome"
          />

          <div
            style={{
              backgroundColor: "grey",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              borderRadius: "0px 12px 12px 0px",
              width: "46px",
              cursor: "pointer",
            }}
          >
            <IoSearch />
          </div>
        </div>

        {/* Actions */}
        <div style={{ display: "flex" }}>
          <button
            onClick={() => {
              resetQuoteData();
              nav("/home/list-quotes/quote");
            }}
            className="q-secondary-button black"
          >
            <IoIosAddCircle size={"20px"} />
            <div>Crea preventivo</div>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="home-view">
        <div className="quota-table-container">
          <table className="quota-table-table">
            <thead>
              <tr className="quota-table-header-row">
                <th className="quota-table-header-cell">Cod.</th>
                <th className="quota-table-header-cell">Data</th>
                <th className="quota-table-header-cell">Cliente</th>
                <th className="quota-table-header-cell">Prezzo</th>
                <th className="quota-table-header-cell">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {useSearching({
                list: quotesState,
                searchValue: search,
                useSearchForClient: true,
                searchParams: ["quoteId"],
              }).map((e, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0
                      ? "quota-table-alternate-row"
                      : "quota-table-row"
                  }
                >
                  <td className="quota-table-cell">{e.quoteId}</td>
                  <td className="quota-table-cell">
                    {formatDateInEU(e.createdAt)}
                  </td>
                  <td className="quota-table-cell">
                    {clientFromId(e.toClientId)?.name ?? "-"}
                  </td>
                  <td className="quota-table-cell">
                    {formatPrice(e.showedPrice)}
                  </td>
                  <td className="quota-table-cell w-actions">
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                      }}
                    >
                      <button
                        className="q-secondary-button little-button cool-red"
                        onClick={() => {
                          generateQuotePDF({
                            state: e,
                            clientData: clientFromId(e.toClientId),
                            companyState: companyState,
                          });
                        }}
                      >
                        PDF
                      </button>
                      <button
                        className="q-secondary-button little-button blue"
                        onClick={() => {
                          nav("/home/list-quotes/quote?id=" + e.id);
                        }}
                      >
                        Modifica
                      </button>
                      <button
                        className="q-secondary-button little-button red"
                        onClick={() => {
                          removeQuote(e.id);
                        }}
                      >
                        Elimina
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
