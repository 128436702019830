import { useLocalStorage } from "helpers/uses/useLocalStorage";
import useUserStore from "providers/useUserStore";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "style/components-style/user-dropdown.css";

export default function UserIcon() {
  const { clearAllLocalStorage } = useLocalStorage();
  const { getInitials, clearUserState } = useUserStore();
  const nav = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  // Create a ref to store the dropdown container
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks anywhere on the document
    const handleClickOutside = (event) => {
      // Check if dropdownRef exists and if the click was outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Add event listener when the dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]); // Re-run effect when isOpen changes

  return (
    <div style={{ position: "absolute", top: 12, right: 32 }} ref={dropdownRef}>
      <div onClick={() => setIsOpen(!isOpen)} id="user-icon">
        <div style={{ color: "white", fontSize: "20px" }}>{getInitials()}</div>
      </div>
      <div className={"user-dropdown" + (isOpen ? " open" : "")}>
        <button
          onClick={() => {
            clearAllLocalStorage();
            clearUserState();
            setIsOpen(!isOpen);
            nav("/login");
          }}
          id="logout-button"
        >
          Log out
        </button>
      </div>
    </div>
  );
}
