import { generateInvoicePDF } from "helpers/pdf/GenerateInvoicePDF";
import { formatDateInEU, formatPrice } from "helpers/StringHelpers";
import { useSearching } from "helpers/uses/useSearching";
import useClientsStore from "providers/useClientsStore";
import useCompanyStore from "providers/useCompanyStore";
import useInvoicesStore from "providers/useInvoicesStore";
import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

export default function ListInvoices() {
  const [search, setSearch] = useState("");
  const nav = useNavigate();
  const { invoicesState, initInvoices, removeInvoice } = useInvoicesStore();
  const { clientFromId } = useClientsStore();
  const { companyState } = useCompanyStore();

  useEffect(() => {
    initInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFullPricePerInvoice = (works) => {
    var price = 0;

    for (var i = 0; i < works.length; i++) {
      price +=
        Number(works[i].price) *
        (works[i].quantity === "" ? 1 : Number(works[i].quantity));
    }

    return price;
  };

  return (
    <div className="base-view">
      <strong className="title-home-view">Fatture</strong>
      <div className="divider-home-view mb-big" />

      {/* Actions */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
        }}
      >
        {/* Search */}
        <div
          style={{
            display: "flex",
            width: "100%",
            maxWidth: "400px",
          }}
        >
          <input
            className="q-input-form"
            style={{ flex: 1, borderRadius: "12px 0px 0px 12px" }}
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="Cerca per codice o cliente"
          />

          <div
            style={{
              backgroundColor: "grey",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "20px",
              borderRadius: "0px 12px 12px 0px",
              width: "46px",
              cursor: "pointer",
            }}
          >
            <IoSearch />
          </div>
        </div>

        {/* Actions */}
        <div style={{ display: "flex" }}>
          <button
            onClick={() => {
              nav("/home/list-invoices/invoice");
            }}
            className="q-secondary-button black"
          >
            <IoIosAddCircle size={"20px"} />
            <div>Crea fattura</div>
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="home-view">
        <div className="quota-table-container">
          <table className="quota-table-table">
            <thead>
              <tr className="quota-table-header-row">
                <th className="quota-table-header-cell">Cod.</th>
                <th className="quota-table-header-cell">Creata il</th>
                <th className="quota-table-header-cell">Cliente</th>
                <th className="quota-table-header-cell">Importo</th>
                <th className="quota-table-header-cell">Stato</th>
                <th className="quota-table-header-cell">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {useSearching({
                list: invoicesState,
                searchValue: search,
                searchParams: ["invoiceId"],
                useSearchForClient: true,
              }).map((e, i) => (
                <tr
                  key={i}
                  className={
                    i % 2 === 0
                      ? "quota-table-alternate-row"
                      : "quota-table-row"
                  }
                >
                  <td className="quota-table-cell">{e.invoiceId}</td>
                  <td className="quota-table-cell">
                    {formatDateInEU(e.createdAt)}
                  </td>
                  <td className="quota-table-cell">
                    {clientFromId(e.toClientId) === undefined
                      ? "-"
                      : clientFromId(e.toClientId).name}
                  </td>
                  <td className="quota-table-cell">
                    {formatPrice(getFullPricePerInvoice(e.works))}
                  </td>
                  <td className="quota-table-cell">
                    <div
                      style={{
                        backgroundColor: e.payedOn ? "green" : "orange",
                        color: "white",
                        width: "fit-content",
                        padding: "2px 12px",
                        borderRadius: "32px",
                        fontSize: "15px",
                        textWrap: "nowrap",
                      }}
                    >
                      {e.payedOn ? "Pagata" : "Da pagare"}
                    </div>
                  </td>
                  <td className="quota-table-cell w-actions">
                    <div
                      style={{
                        display: "flex",
                        gap: "6px",
                      }}
                    >
                      <button
                        className="q-secondary-button little-button cool-red"
                        onClick={() => {
                          generateInvoicePDF({
                            invoiceData: e,
                            client: clientFromId(e.toClientId),
                            companyState: companyState,
                          });
                        }}
                      >
                        PDF
                      </button>
                      <button
                        className="q-secondary-button little-button blue"
                        onClick={() => {
                          nav("/home/list-invoices/invoice?id=" + e.id);
                        }}
                      >
                        Modifica
                      </button>
                      <button
                        className="q-secondary-button little-button red"
                        onClick={() => removeInvoice(e.id)}
                      >
                        Elimina
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {invoicesState.length === 0 && (
        <div
          style={{
            textAlign: "center",
            color: "#17171760",
            marginTop: "12px",
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          Nessuna fattura al momento
        </div>
      )}
    </div>
  );
}
