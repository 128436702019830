export const useLocalStorage = () => {
  const getUserId = () => localStorage.getItem("userId");
  const getAccessToken = () => localStorage.getItem("accessToken");
  const getRefreshToken = () => localStorage.getItem("refreshToken");

  const clearAllLocalStorage = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
  };

  const saveLocalValue = (key, value) => {
    localStorage.setItem(key, value);
  };

  return {
    getUserId,
    getAccessToken,
    getRefreshToken,
    clearAllLocalStorage,
    saveLocalValue,
  };
};
